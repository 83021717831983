import React, { FC } from 'react'
import { Button, Card } from 'antd'
import { Drawer } from '@/components/Drawer'
import { CreateDebtorForm } from '@/views/debtors/components/DebtorGeneralForm'
import { Debtor } from '@/models/debtor'
import Container from '@/components/layouts/Container'

interface CreateDebtorProps {
  onDebtorCreated: (debtor: Debtor) => void
  onClose: () => void
}

export const CreateDebtor: FC<CreateDebtorProps> = ({ onDebtorCreated, onClose }) => (
  <Drawer width={480} onClose={onClose} visible={true}>
    <Container>
      <Card>
        <h2>Create New Debtor</h2>
        <CreateDebtorForm
          onSaved={(debtor: Debtor) => {
            onDebtorCreated(debtor)
            onClose()
          }}
        />
      </Card>
    </Container>
  </Drawer>
)

export const CreateDebtorButton: FC<{
  onShowCreateDebtor: () => void
  disabled?: boolean
}> = ({ onShowCreateDebtor, disabled }) => (
  <Button type='primary' onClick={onShowCreateDebtor} disabled={disabled} style={{ marginLeft: '8px' }}>
    Create Debtor
  </Button>
)
