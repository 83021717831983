import React, { FC } from 'react'
import { GetDebtor, GetDebtorVariables } from '@/queries/_gen_/GetDebtor'
import Fetch from '@/components/Fetch'
import { GetDebtorQuery } from '@/queries/debtors.queries'
import CenterAlert from '@/components/CenterAlert'
import { UpdateDebtorForm, ReadOnlyDebtorForm } from './components/DebtorGeneralForm'
import { DebtorViewLayout } from './components/DebtorViewLayout'
import { DebtorContactsForm } from './components/DebtorContactsForm'
import * as R from 'ramda'
import { RoutedTabPane } from '@/components/RoutedTabs'
import { DebtorEventsTab } from './components/DebtorEventsTab'
import Container from '@/components/layouts/Container'
import { useRole } from '@/hooks/useRole'
import { DebtorRecoveryEventsTab } from './components/DebtorRecoveryEventsTab'

export interface UpdateDebtorViewProps {
  debtorId: number
  pathRoot?: string
}

export const UpdateDebtorView: FC<UpdateDebtorViewProps> = ({ debtorId, pathRoot }) => {
  const { isManager } = useRole()

  return (
    <Fetch<GetDebtor, GetDebtorVariables> query={GetDebtorQuery} container={true} variables={{ id: debtorId }}>
      {data => {
        if (data && data.debtor) {
          const debtor = data.debtor

          const tabs: RoutedTabPane[] = [
            {
              name: 'General',
              path: '',
              render: () => (
                <Container>
                  {isManager ? (
                    <UpdateDebtorForm key={debtor.id} debtor={R.omit(['debtor_contacts', '__typename'], debtor)} />
                  ) : (
                    <ReadOnlyDebtorForm key={debtor.id} debtor={debtor} />
                  )}
                </Container>
              )
            },
            {
              name: 'Contacts',
              path: '/contacts',
              render: () => (
                <Container>
                  <DebtorContactsForm debtor={debtor} readOnly={!isManager} />
                </Container>
              )
            },
            {
              name: 'History',
              path: '/history',
              render: () => <DebtorEventsTab debtor={debtor} />
            },
            {
              name: 'Recovery History',
              path: '/recovery-history',
              render: () => <DebtorRecoveryEventsTab debtor={debtor} />
            }
          ]

          return <DebtorViewLayout pathRoot={pathRoot || `/debtors/${debtorId}`} header={data.debtor.name} tabs={tabs} />
        }
        return <CenterAlert type='error' message='Debtor not found :(' />
      }}
    </Fetch>
  )
}
