import React, { FC } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { HeaderWithContent } from '@/components/layouts/HeaderWithContent'
import { CreateCategoryForm } from './components/CategoryForm'
import { CreateCategoryRecoveryForm } from './components/CategoryRecoveryForm'
import Container from '@/components/layouts/Container'
import { stages, getStage } from '@/models/stage'

export const CreateCategoryView: FC = () => {
  const nav = useNavigate()
  const stage = getStage(useParams().stage)
  return (
    <HeaderWithContent header='New category'>
      <Container>
        {stage === stages.recovery ? (
          <CreateCategoryRecoveryForm onSaved={category => nav(`/categories/recovery/${category.id}`)} />
        ) : (
          <CreateCategoryForm onSaved={category => nav(`/categories/reminder/${category.id}`)} />
        )}
      </Container>
    </HeaderWithContent>
  )
}
