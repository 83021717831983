export enum EventType {

  //reminder
  reminderCreated = 'reminder_created',
  reminderSendTriggered = 'reminder_send_triggered',
  reminderSendError = 'reminder_send_error',
  reminderCancelled = 'reminder_cancelled',
  reminderMarkedAsSent = 'reminder_marked_as_sent',
  reminderSent = 'reminder_sent',
  reminderEmailEvent = 'reminder_email_event',

  //comment
  comment = 'comment',

  //invoice
  invoiceCreated = 'invoice_created',
  invoiceUpdated = 'invoice_updated',

  //casecard
  casecardCreated = 'casecard_created',
  casecardUpdated = 'casecard_updated'

}

export const eventType2Color: Record<string, string> = {
  [EventType.reminderSendError]: 'red',
  [EventType.reminderSent]: 'green',
  [EventType.reminderMarkedAsSent]: 'green',
  [EventType.comment]: 'yellow'
}