import { IdField } from '../utils'
import { GetExistingDebtorsAndInvoices_debtor_category_category_steps_template } from '@/queries/_gen_/GetExistingDebtorsAndInvoices'
import { ReminderPeriod, ReminderType } from 'common/models/reminder'
import { debtor_contact_insert_input, casecard_insert_input } from '@/queries/_gen_global'
import { ColumnType, SheetFormat } from './xls'
import { FCategoryImportFields } from '@/queries/_gen_/FCategoryImportFields'

export type ContactFields = Pick<debtor_contact_insert_input, 'value' | 'city' | 'country' | 'post_code' | 'type'>

export type CasecardFields = Pick<
  casecard_insert_input,
  | 'id'
  | 'case_number'
  | 'amount_outstanding'
  | 'payment_status'
  | 'client_cost'
  | 'commission_amount'
  | 'commission_type'
  | 'administration_fee'
  | 'currency'
  | 'debtor_id'
  | 'is_paid'
  | 'penalty_amount'
  | 'penalty_type'
  | 'customer_ref_no'
  | 'case_payment_date'
>

type ExistingCasecardFields = CasecardFields & IdField

export interface ImportOptions {
  importCasecards: boolean
  generateReminders: boolean
  markOldInvoicesAsPaid: boolean
}

export const defaultImportOptions: ImportOptions = {
  importCasecards: true,
  generateReminders: false,
  markOldInvoicesAsPaid: false
}

export const sheetFormat2ImportOptions: { [key in SheetFormat]: ImportOptions } = {
  [SheetFormat.silva]: defaultImportOptions,
  [SheetFormat.simple]: defaultImportOptions,
  [SheetFormat.custom]: defaultImportOptions
}

export interface ReminderFields {
  template: GetExistingDebtorsAndInvoices_debtor_category_category_steps_template | null
  reminder_type: ReminderType
  reminder_period: ReminderPeriod
  period_days_to: number
  period_days_from: number
  is_suspended: boolean
}

type PlanDebtor = {
  // @TODO are we gonna import more debtor fields?
  name: string
  company_code: string | null
}

export interface NewDebtor extends PlanDebtor {
  contacts: ContactFields[]
  casecards: CasecardFields[]
  reminders: ReminderFields[]
  category_id?: number
}

export interface UpdateDebtor extends PlanDebtor, IdField {
  new_contacts: ContactFields[]
  new_casecards: CasecardFields[]
  update_casecards: ExistingCasecardFields[]
  new_reminders: ReminderFields[]
  update_fields: {
    customer_code_in_client_system?: string
    category_id?: number
    extra_1?: string
    extra_2?: string
    company_code?: string
  }
}

export interface Plan {
  import_options: ImportOptions
  new_debtors: NewDebtor[]
  update_debtors: UpdateDebtor[]
  next_casecard_number: number
  update_column_types?: ColumnType[]
}

export function isSameDebtor(
  debtor1: { name: string; company_code?: string | null },
  debtor2: { name: string; company_code?: string | null }
): boolean {
  return debtor1.company_code && debtor2.company_code ? debtor1.company_code === debtor2.company_code : debtor1.name === debtor2.name
}

export interface ImportContext {
  clientCategories: FCategoryImportFields[]
}
