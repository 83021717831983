import React, { FC } from 'react'
import Container from '@/components/layouts/Container'
import { EventsHistory } from '@/components/history/EventsHistory'
import { DebtorWithId } from '@/models/debtor'

export interface DebtorRecoveryEventsTabProps {
  debtor: DebtorWithId
}

export const DebtorRecoveryEventsTab: FC<DebtorRecoveryEventsTabProps> = ({ debtor }) => (
  <Container>
    <EventsHistory debtorId={debtor.id} stage='recovery' />
  </Container>
)
