import React, { FC, useCallback } from 'react'
import {
  SearchRecoveryTemplates_items,
  SearchRecoveryTemplatesVariables,
  SearchRecoveryTemplates
} from '@/queries/_gen_/SearchRecoveryTemplates'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { GraphqlSelect } from './GraphqSelect'
import { SearchRecoveriesTemplatesQuery } from '@/queries/recoveriesTemplates.queries'
import { order_by } from '@/queries/_gen_global'
import { templateLanguageNames, TemplateLanguage } from '@/models/templates'

interface DebtRecoveryTemplateSelectProps {
  value?: number
  onChange: (value: SearchRecoveryTemplates_items | null) => void
}

export const DebtRecoveryTemplateSelect: FC<DebtRecoveryTemplateSelectProps> = props => {
  const { value, onChange } = props

  const client = useCurrentClient()

  const buildVars = useCallback(
    (query: string): SearchRecoveryTemplatesVariables['where'] => ({
      client_id: { _eq: client.id },
      archived: { _eq: false },
      ...(query ? { name: { _ilike: `%${query}%` } } : {})
    }),
    [client]
  )

  return (
    <GraphqlSelect<SearchRecoveryTemplates_items, SearchRecoveryTemplates, SearchRecoveryTemplatesVariables>
      query={SearchRecoveriesTemplatesQuery}
      renderLabel={t => `${t.name} (${templateLanguageNames[t.language as TemplateLanguage]})`}
      buildSearchVariables={buildVars}
      valueId={value}
      onChange={onChange}
      orderBy={[{ name: order_by.asc }]}
    />
  )
}
