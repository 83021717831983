import gql from 'graphql-tag'
import { CategoryFieldsFragment } from './categories.queries'

export const ClientFieldsFragment = gql`
  fragment FClientFields on client {
    id
    name
    address
    company_code
    vat_code
    reference_code
    category_id
    default_currency
    date_locale
    date_format
    invoice_series
    next_invoice_number
    is_archived
    penalty_type
    penalty_amount
    commission_type
    commission_amount
    casecard_series
    next_casecard_number
    administration_fee_settings
  }
`

export const ClientApiKeyFieldsFragment = gql`
  fragment FClientApiKeyFields on client_api_keys {
    id
    client_id
    api_key
    is_expired
    created_at
    updated_at
  }
`

export const SearchClientsQuery = gql`
  query SearchClients($limit: Int!, $offset: Int!, $where: client_bool_exp!, $order_by: [client_order_by!]) {
    items: client(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      ...FClientFields
      category {
        ...FCategoryFields
      }
    }
    total: client_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }

  ${ClientFieldsFragment}
  ${CategoryFieldsFragment}
`

export const CountClientsQuery = gql`
  query CountClients($where: client_bool_exp) {
    result: client_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const InsertClientMutation = gql`
  mutation InsertClient($payload: client_insert_input!) {
    result: insert_client(objects: [$payload]) {
      returning {
        ...FClientFields
      }
    }
  }

  ${ClientFieldsFragment}
`

export const UpdateClientMutation = gql`
  mutation UpdateClient($id: Int!, $payload: client_set_input!) {
    result: update_client(_set: $payload, where: { id: { _eq: $id } }) {
      returning {
        ...FClientFields
      }
    }
  }

  ${ClientFieldsFragment}
`

export const GetClientQuery = gql`
  query GetClient($id: Int!) {
    client: client_by_pk(id: $id) {
      ...FClientFields
    }
  }
  ${ClientFieldsFragment}
`

export const InsertClientApiKeyMutation = gql`
  mutation InsertClientApiKey($payload: client_api_keys_insert_input!) {
    result: insert_client_api_keys_one(object: $payload) {
      ...FClientApiKeyFields
    }
  }
  ${ClientApiKeyFieldsFragment}
`

export const ExpireClientApiKeyMutation = gql`
  mutation UpdateClientApiKey($id: Int!) {
    result: update_client_api_keys(_set: { is_expired: true }, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...FClientApiKeyFields
      }
    }
  }
  ${ClientApiKeyFieldsFragment}
`

export const GetClientApiKeysQuery = gql`
  query GetClientApiKeys($clientId: Int!) {
    keys: client_api_keys(where: { client_id: { _eq: $clientId } }, order_by: { created_at: desc }) {
      ...FClientApiKeyFields
    }
  }
  ${ClientApiKeyFieldsFragment}
`
