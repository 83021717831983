import { Sheet, ColumnType, SheetConfig } from '@/models/casecard_import/xls'
import {
  ContactFields,
  CasecardFields,
  Plan,
  isSameDebtor,
  UpdateDebtor,
  ImportOptions,
  NewDebtor,
  ImportContext
} from '@/models/casecard_import/plan'
import { sheetToValues } from '@/utils/casecard_xls'
import { useMemo } from 'react'
import { RowValues, parseSeparatedItems } from '@/views/casecard_import/columns'
import ApolloClient from 'apollo-client'
import { useApolloClient } from 'react-apollo'
import * as R from 'ramda'
import { GetExistingDebtors, GetExistingDebtorsVariables } from '@/queries/_gen_/GetExistingDebtors'
import { GetExistingDebtorsAndCasecardsQuery, GetExistingDebtorsQuery } from '@/queries/import.queries'
import { extractQueryResult } from '@/utils/apollo'
import { useCurrentClient } from '../useCurrentClient'
import { ClientWithId } from '@/models/client'
import { ContactType, Debtor } from '@/models/debtor'
import {
  GetExistingDebtorsAndCasecards,
  GetExistingDebtorsAndCasecardsVariables,
  GetExistingDebtorsAndCasecards_debtor_casecards
} from '@/queries/_gen_/GetExistingDebtorsAndCasecards'
import { logger } from '@/logger'
import AdministrationFeeCalc from '@/utils/AdministrationFeeCalc'

function assertval<T>(what: T | undefined, message?: string): T {
  if (what === undefined) {
    throw new Error(message || 'unexpectedly undefined value')
  }
  return what
}

type ImportData = Map<Debtor, RowValues[]>

function valuesToImportData(values: RowValues[], importContext: ImportContext): ImportData {
  console.log(importContext) // TODO: remove importContext if not necessary
  const importData: ImportData = new Map()
  const debtors: Debtor[] = []

  values.forEach(row => {
    const name = assertval(row.debtor_code, 'there`s a row with no company name OR first name + last name')
    // const category_id: number | null = (() => {
    //   if (row.debtor_category) {
    //     return assertval(
    //       importContext.clientCategories.find(cat => cat.name === row.debtor_category)?.id,
    //       `category "${row.debtor_category}" not found`
    //     )
    //   }
    //   return null
    // })()
    const debtor: Debtor = {
      company_code: row.debtor_code || null,
      name,
      reference_code: null,
      vat_code: null,
      category_id: null,
      recovery_category_id: null,
      entity_type: 'individual',
      suspend_reminders_until: null,
      customer_code_in_client_system: null,
      extra_1: null,
      extra_2: null
    }

    const existingDebtor = debtors.find(existing => isSameDebtor(debtor, existing))
    if (existingDebtor) {
      ;(importData.get(existingDebtor) as RowValues[]).push(row)
    } else {
      importData.set(debtor, [row])
      debtors.push(debtor)
    }
  })
  return importData
}

function extractContacts(rows: RowValues[]): ContactFields[] {
  const contacts: ContactFields[] = []
  rows.map(row => {
    const address = row[ColumnType.debtorAddress]
    if (address) {
      contacts.push({ type: ContactType.address, value: address, city: row.debtor_city, post_code: null, country: null })
    }
    ;[...parseSeparatedItems(row[ColumnType.debtorPhoneNumber] ?? '')].forEach(value => {
      contacts.push({ type: ContactType.phone, value })
    })
    ;[...parseSeparatedItems(row[ColumnType.debtorEmail] ?? '')].forEach(value => {
      contacts.push({ type: ContactType.email, value })
    })
  })
  return R.uniqWith((a, b) => a.type === b.type && a.value?.toLowerCase() === b.value?.toLowerCase(), contacts)
}

/*
adds to plan debtors and contacts that should be inserted/updated
*/
async function createDebtorsAndContactsPlan(
  importOptions: ImportOptions,
  importData: ImportData,
  apolloClient: ApolloClient<any>,
  currentClient: ClientWithId
): Promise<Plan> {
  const importDebtors = Array.from(importData.entries())

  const result = extractQueryResult(
    await apolloClient.query<GetExistingDebtors, GetExistingDebtorsVariables>({
      query: GetExistingDebtorsQuery,
      variables: {
        debtor_company_codes: importDebtors.map(([debtor]) => debtor.company_code).filter((code): code is string => !!code),
        client_id: currentClient.id,
        debtor_names: importDebtors.map(([debtor]) => debtor.name)
      },
      fetchPolicy: 'no-cache'
    })
  )

  const plan: Plan = {
    import_options: importOptions,
    new_debtors: [],
    update_debtors: [],
    next_casecard_number: currentClient.next_casecard_number
  }

  importDebtors.forEach(([debtor, rows]) => {
    const contacts = extractContacts(rows)

    // sanity check: do not allow import if debtor with same name but different company code already exists in database
    if (debtor.company_code && debtor.name) {
      const existingWithSameNameButDiffCode = result.debtor.find(
        rdebtor => rdebtor.company_code && rdebtor.name && rdebtor.name === debtor.name && rdebtor.company_code !== debtor.company_code
      )
      if (existingWithSameNameButDiffCode) {
        throw new Error(
          `Trying to import debtor "${debtor.name}" with company code ${debtor.company_code}, but a debtor with this name and different company code ${existingWithSameNameButDiffCode.company_code} already exists in the database.`
        )
      }
    }

    const existingDebtor = result.debtor.find(rdebtor => isSameDebtor(debtor, rdebtor))
    if (existingDebtor) {
      const update_fields: UpdateDebtor['update_fields'] = {}
      if (debtor.category_id && debtor.category_id !== existingDebtor.category_id) {
        update_fields.category_id = debtor.category_id
      }
      if (debtor.customer_code_in_client_system && debtor.customer_code_in_client_system != existingDebtor.customer_code_in_client_system) {
        update_fields.customer_code_in_client_system = debtor.customer_code_in_client_system
      }
      if (debtor.extra_1 && debtor.extra_1 !== existingDebtor.extra_1) {
        update_fields.extra_1 = debtor.extra_1
      }
      if (debtor.extra_2 && debtor.extra_2 !== existingDebtor.extra_2) {
        update_fields.extra_2 = debtor.extra_2
      }
      if (debtor.company_code && !existingDebtor.company_code) {
        update_fields.company_code = debtor.company_code
      }
      plan.update_debtors.push({
        id: existingDebtor.id,
        name: existingDebtor.name,
        company_code: debtor.company_code || existingDebtor.company_code,
        new_contacts: contacts.filter(
          c => !existingDebtor.debtor_contacts.find(ec => ec.type === c.type && ec.value.toLowerCase() === c.value?.toLowerCase())
        ),
        new_casecards: [],
        update_casecards: [],
        new_reminders: [],
        update_fields
      })
    } else {
      plan.new_debtors.push({
        ...debtor,
        contacts,
        casecards: [],
        reminders: [],
        category_id: debtor.category_id || undefined
      })
    }
  })
  return plan
}

function fillInUndefinedCasecardFields(
  casecard: CasecardFields,
  existing: GetExistingDebtorsAndCasecards_debtor_casecards
): CasecardFields {
  return Object.keys(casecard).reduce<CasecardFields>(
    (obj, key) => ({
      ...obj,
      [key]: (casecard as any)[key] ?? (existing as any)[key]
    }),
    {} as CasecardFields
  ) as CasecardFields
}

function withCasecards(
  plan: Plan,
  importData: ImportData,
  existing: GetExistingDebtorsAndCasecards,
  currentClient: ClientWithId
  // context: ImportContext
): Plan {
  function row2Casecard(row: RowValues, debtor: Debtor): CasecardFields | null {
    console.log(debtor) // TODO: remove if debtor is not being used
    const amountOutstanding = row[ColumnType.amountOutstanding]

    if (amountOutstanding !== undefined && amountOutstanding.lessThanOrEqualTo(0)) {
      logger.info(`skipping document ${row[ColumnType.caseNumber]}, no outstanding amount`)
      return null
    }

    const client_cost = 0

    // Add Casecaerd
    return {
      case_number: row[ColumnType.caseNumber],
      amount_outstanding: amountOutstanding,
      client_cost,
      commission_amount: null,
      commission_type: null,
      administration_fee: null,
      currency: row[ColumnType.currency],
      is_paid: false,
      penalty_amount: row[ColumnType.penaltyAmount],
      penalty_type: 'percentage'
    }
  }

  let nextCasecardNumber = plan.next_casecard_number

  function processNewCasecard(casecard: CasecardFields): CasecardFields {
    //  for new casecard we want to set outstanding amt to line price, if there is none
    const cc = {
      ...casecard
    }

    // generate document number if there isn't one
    if (!cc.case_number) {
      assertval(currentClient.casecard_series, 'Current client has no casecard series defined, unable to generate casecard numbers.')
      cc.case_number = `${currentClient.casecard_series}-${nextCasecardNumber++}`
    }

    // Take penalty amount from client if there is none
    if (!cc.penalty_amount) {
      assertval(currentClient.penalty_amount, 'Current client has no penalty amount, unable to add penalty amount.')
      cc.penalty_amount = currentClient.penalty_amount
    }

    // Take penalty type from client if there is none
    if (!cc.penalty_type) {
      assertval(currentClient.penalty_type, 'Current client has no penalty type, unable to add penalty type.')
      cc.penalty_type = currentClient.penalty_type
    }

    // Take commission amount from client if there is none
    if (!cc.commission_amount) {
      assertval(currentClient.commission_amount, 'Current client has no commission amount, unable to add commission amount.')
      cc.commission_amount = currentClient.commission_amount
    }

    // Take commission type from client if there is none
    if (!cc.commission_type) {
      assertval(currentClient.commission_type, 'Current client has no commission type, unable to add commission type.')
      cc.commission_type = currentClient.commission_type
    }

    // Calculate the administration fee from category and customer settings
    if (!cc.administration_fee) {
      cc.administration_fee = AdministrationFeeCalc(cc.amount_outstanding, currentClient)
    }
    return cc
  }

  const importDebtors = Array.from(importData.keys())

  const new_debtors: NewDebtor[] = plan.new_debtors.map(debtor => {
    const importDebtor = assertval(importDebtors.find(idebtor => isSameDebtor(debtor, idebtor)))
    const rows = assertval(importData.get(importDebtor))

    const casecards = rows
      .map(casecard => row2Casecard(casecard, importDebtor))
      .filter((cc): cc is CasecardFields => !!cc)
      .map(processNewCasecard)
    return {
      ...debtor,
      casecards
    }
  })

  const update_debtors: UpdateDebtor[] = plan.update_debtors.map(debtor => {
    const importDebtor = assertval(
      importDebtors.find(idebtor => isSameDebtor(debtor, idebtor)),
      'import debtor not found'
    )
    const rows = assertval(importData.get(importDebtor), 'row for import debtor not found')
    const existingDebtor = assertval(
      existing.debtor.find(rdebtor => isSameDebtor(rdebtor, debtor)),
      'existing debtor not found'
    )
    const casecards = rows.map(casecard => row2Casecard(casecard, importDebtor)).filter((cc): cc is CasecardFields => !!cc)

    const retv: UpdateDebtor = {
      ...debtor,
      new_casecards: [],
      update_casecards: []
    }

    casecards.forEach(casecard => {
      const existingCasecard = existingDebtor && existingDebtor.casecards.find(cc => cc.case_number === casecard.case_number)
      if (existingCasecard) {
        retv.update_casecards.push({
          ...fillInUndefinedCasecardFields(casecard, existingCasecard),
          id: existingCasecard.id,
          payment_status: casecard.amount_outstanding < existingCasecard.amount_outstanding ? 'partial_paid' : 'due'
        })
      } else {
        retv.new_casecards.push(R.omit(['id'], processNewCasecard(casecard)))
      }
    })

    return retv
  })

  return {
    ...plan,
    new_debtors,
    update_debtors,
    next_casecard_number: nextCasecardNumber
  }
}

export function usePlan(
  sheet: Sheet,
  importOptions: ImportOptions,
  context: ImportContext,
  initialSheetConfig: SheetConfig,
  dependencies: unknown[]
): Promise<Plan> {
  const apolloClient = useApolloClient()
  const currentClient = useCurrentClient()

  return useMemo(async () => {
    const values = sheetToValues(sheet)
    const importData = valuesToImportData(values, context)

    let plan = await createDebtorsAndContactsPlan(importOptions, importData, apolloClient, currentClient)

    if (importOptions.importCasecards) {
      const caseNumbers = sheet.columns.find(c => c.type === ColumnType.caseNumber)
        ? R.flatten(Array.from(importData.values())).map(row => assertval(row.case_number))
        : []
      const existing = extractQueryResult(
        await apolloClient.query<GetExistingDebtorsAndCasecards, GetExistingDebtorsAndCasecardsVariables>({
          query: GetExistingDebtorsAndCasecardsQuery,
          variables: {
            client_id: currentClient.id,
            debtor_company_codes: plan.update_debtors.map(debtor => debtor.company_code).filter((code): code is string => !!code),
            casecaerd_case_numbers: caseNumbers,
            debtor_names: plan.update_debtors.map(debtor => debtor.name)
          },
          fetchPolicy: 'no-cache'
        })
      )
      if (importOptions.importCasecards) {
        plan = withCasecards(plan, importData, existing, currentClient)
      }
    }
    if (JSON.stringify(initialSheetConfig.columnTypes) !== JSON.stringify(sheet.columns.map(c => c.type))) {
      plan.update_column_types = sheet.columns.map(c => c.type)
    }
    return plan
  }, [sheet, importOptions, initialSheetConfig, ...dependencies, apolloClient, currentClient, context])
}
