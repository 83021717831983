import React, { FC, useEffect, useMemo } from 'react'
import { Client, ClientWithId } from '@/models/client'
import { Formik, Form } from 'formik'
import { ShortFormikField } from '@/components/form/FormikField'
import * as yup from 'yup'
import * as R from 'ramda'
import ApolloClient from 'apollo-client'
import { clientUniquenessValidator } from '../clientValidators'
import { InsertClientMutation, UpdateClientMutation, SearchClientsQuery } from '@/queries/clients.queries'
import { InsertClient, InsertClientVariables } from '@/queries/_gen_/InsertClient'
import { UpdateClient, UpdateClientVariables } from '@/queries/_gen_/UpdateClient'
import { FormSubmitButton } from '@/components/form/FormSubmitButton'
import { FormMutation } from '@/components/form/FormMutation'
import { emptyToNull } from '@/utils/object'
import { ShortCategorySelectFormikField } from '@/components/CategorySelect'
import { ExecutionResult, useApolloClient } from 'react-apollo'
import { CurrencySelect } from '@/components/form/CurrencySelect'
import { DateLocaleSelect } from '@/components/form/DateLocaleSelect'
import Input from 'antd/lib/input'
import formatDate from 'date-fns/format'
import * as locales from 'date-fns/locale'
import Container from '@/components/layouts/Container'
import { Checkbox } from 'antd'
import { ShortCasecardFeeTypeFormikField } from '@/views/casecards/components/FeeTypeSelect'
import { AdministrationFeeSettingsFormItem } from '@/components/form/AdministrationFeeSettingsFormItem'
import { EntityType } from '@/models/debtor'
import styled from 'styled-components'
import AntdForm from 'antd/lib/form'
import Button from 'antd/lib/button'

interface ClientFormProps {
  client: Client
  onSubmit: (values: Client) => Promise<ExecutionResult<InsertClient>>
  loading: boolean
  btnLabel: string
}

const AddButton = styled(Button)`
  width: 110px;
  margin-bottom: 20px;
`

const emptyClient: Client = {
  name: '',
  company_code: '',
  address: '',
  vat_code: '',
  reference_code: '',
  category_id: null,
  default_currency: 'EUR',
  date_format: 'yyyy-MM-dd',
  date_locale: 'en',
  invoice_series: '',
  next_invoice_number: 1,
  is_archived: false,
  penalty_type: null,
  penalty_amount: null,
  commission_type: null,
  commission_amount: null,
  casecard_series: null,
  next_casecard_number: 1,
  administration_fee_settings: []
}

function dateFormatPreview(format: string, locale: string) {
  try {
    return formatDate(new Date(), format, { locale: (locales as any)[locale] })
  } catch (e) {
    return (e as Error).message || e
  }
}

const validationSchema = (apollo: ApolloClient<any>, clientId?: number) =>
  yup.object().shape({
    name: yup
      .string()
      .required('Required!')
      .test('name_unique', 'Another client with this name already exists.', clientUniquenessValidator(apollo, 'name', clientId)),
    company_code: yup
      .string()
      .nullable()
      .test(
        'company_code_unique',
        'Another client with this company code already exists.',
        clientUniquenessValidator(apollo, 'company_code', clientId)
      ),
    vat_code: yup
      .mixed()
      .test(
        'vat_code_unique',
        'Another client with this VAT code already exists.',
        clientUniquenessValidator(apollo, 'vat_code', clientId)
      ),
    refrence_code: yup
      .mixed()
      .test(
        'reference_code_unique',
        'Another client with this reference code already exists.',
        clientUniquenessValidator(apollo, 'reference_code', clientId)
      ),
    invoice_series: yup
      .string()
      .nullable()
      .matches(/[a-zA-Z0-9\-_]+/, 'Must only contain letters, numbers, - or _')
  })

const ClientForm: FC<ClientFormProps> = ({ client, loading, onSubmit, btnLabel }) => {
  const apollo = useApolloClient()
  const vSchema = useMemo(() => validationSchema(apollo, client.id), [apollo, client.id])

  return (
    <Container>
      <Formik<Client> initialValues={client} onSubmit={onSubmit} validationSchema={vSchema}>
        {({ dirty, setFieldValue, values }) => (
          <Form className='ant-form ant-form-vertical'>
            <ShortFormikField name='name' label='Name' />
            <ShortFormikField name='company_code' label='Company code' />
            <ShortFormikField name='vat_code' label='VAT code' />
            <ShortFormikField name='reference_code' label='Reference code' />
            <ShortFormikField name='address' label='Address' />
            <ShortCategorySelectFormikField
              name='category_id'
              label='Default category'
              disabled={!client.id}
              clientId={client.id}
              help={client.id ? undefined : 'After saving the client please create a default category and then come back to set it here.'}
            />
            <ShortFormikField name='default_currency' label='Default currency'>
              {(
                { field: { onChange, ...fieldProps } } // eslint-disable-line @typescript-eslint/no-unused-vars
              ) => <CurrencySelect {...fieldProps} onChange={value => setFieldValue('default_currency', value)} />}
            </ShortFormikField>
            <ShortFormikField name='date_locale' label='Date locale'>
              {(
                { field: { onChange, ...fieldProps } } // eslint-disable-line @typescript-eslint/no-unused-vars
              ) => <DateLocaleSelect {...fieldProps} onChange={value => setFieldValue('date_locale', value)} />}
            </ShortFormikField>
            <ShortFormikField
              name='date_format'
              label={
                <>
                  Date format{' '}
                  <a href='https://date-fns.org/v2.8.1/docs/format' target='__blank'>
                    (help)
                  </a>
                </>
              }
            >
              {({ field }) => (
                <>
                  <Input {...field} />
                  <p>Preview: {dateFormatPreview(values.date_format, values.date_locale)}</p>
                </>
              )}
            </ShortFormikField>
            <ShortFormikField
              name='invoice_series'
              label='Invoice series'
              description='Used to generate document number if one is not present in import file.'
            />
            <ShortFormikField name='penalty_amount' label='Penalty Amount' />
            <ShortCasecardFeeTypeFormikField name='penalty_type' label='Penalty Type' />
            <ShortFormikField name='commission_amount' label='Commission Amount' />
            <ShortCasecardFeeTypeFormikField name='commission_type' label='Commission Type' />

            <AntdForm.Item>
              <label>Administration Fee Settings:</label>
            </AntdForm.Item>

            {values.administration_fee_settings.map((setting: any, idx: any) => (
              <AdministrationFeeSettingsFormItem
                key={idx}
                index={idx}
                value={setting}
                onDelete={() => setFieldValue('administration_fee_settings', R.remove(idx, 1, values.administration_fee_settings))}
                setFieldValue={setFieldValue as any}
              />
            ))}

            <AntdForm.Item>
              <AddButton
                onClick={() => {
                  setFieldValue('administration_fee_settings', [
                    ...values.administration_fee_settings,
                    { from: 0, to: 0, entity_type: null as EntityType | null, fee: 0 }
                  ])
                }}
                type='default'
              >
                Add Fee
              </AddButton>
            </AntdForm.Item>

            <ShortFormikField
              name='casecard_series'
              label='Casecard series'
              description='Used to generate casecard number if one is not present in import file.'
            />
            <ShortFormikField name='is_archived'>
              {(
                { field: { onChange, value, ...fieldProps } } // eslint-disable-line @typescript-eslint/no-unused-vars
              ) => (
                <Checkbox {...fieldProps} defaultChecked={value} onChange={value => setFieldValue('is_archived', value.target.checked)}>
                  Archived
                </Checkbox>
              )}
            </ShortFormikField>
            <FormSubmitButton htmlType='submit' type='primary' disabled={!dirty || loading} loading={loading}>
              {btnLabel}
            </FormSubmitButton>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

interface CreateClientFormProps {
  onSaved: (client: Client) => void
}

export const CreateClientForm: FC<CreateClientFormProps> = ({ onSaved }) => (
  <FormMutation<Client, InsertClient, InsertClientVariables>
    buildVariables={payload => ({ payload: emptyToNull(payload) })}
    successMessage={data => (data.result && `Client ${data.result.returning[0].name} created!`) || '???'}
    mutation={InsertClientMutation}
    refetchQueries={['SearchClients', { query: SearchClientsQuery, variables: { limit: 10000, offset: 0, where: {} } }]}
  >
    {({ onSubmit, result: { loading, data } }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        if (data && data.result && data.result.returning.length) {
          onSaved(data.result.returning[0])
        }
      }, [data])
      return <ClientForm client={emptyClient} onSubmit={onSubmit} loading={loading} btnLabel='Create client' />
    }}
  </FormMutation>
)

interface UpdateClientFormProps {
  client: ClientWithId
}

export const UpdateClientForm: FC<UpdateClientFormProps> = ({ client }) => (
  <FormMutation<Client, UpdateClient, UpdateClientVariables>
    buildVariables={payload => ({ payload: emptyToNull(R.omit(['id', 'category'], payload)), id: client.id })}
    successMessage={data => (data.result && `Client ${data.result.returning[0].name} updated!`) || '???'}
    mutation={UpdateClientMutation}
  >
    {({ onSubmit, result: { loading } }) => <ClientForm client={client} onSubmit={onSubmit} loading={loading} btnLabel='Update client' />}
  </FormMutation>
)
