import { FDebtorFields } from '@/queries/_gen_/FDebtorFields'
import { FDebtorContactFields } from '@/queries/_gen_/FDebtorContactFields'
import { Modelize, IdField } from './utils'
export * from 'common/models/contact'

export type DebtorContact = Omit<Modelize<FDebtorContactFields>, 'debtor_id'>

export type Debtor = Modelize<Omit<FDebtorFields, 'are_reminders_suspended'>>
export type DebtorWithId = Debtor & IdField

export interface DebtorWithContacts extends Debtor {
  debtor_contacts: DebtorContact[]
}

export enum EntityType {
  individual = 'individual',
  business = 'business'
}

export const entityTypeNames: { [key in EntityType]: string } = {
  [EntityType.individual]: 'Individual',
  [EntityType.business]: 'Business'
}

export const entityTypes = Object.values(EntityType)
