import React, { FC } from 'react'
import { HeaderWithContent } from '@/components/layouts/HeaderWithContent'
import { CreateTemplateForm } from './components/TemplateRecoveryForm'
import Container from '@/components/layouts/Container'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { useNavigate } from 'react-router'
import { DebtRecoveryTemplateWithId } from '@/models/templates'

export const CreateDebtRecoveriesTemplateView: FC = () => {
  const nav = useNavigate()
  return (
    <HeaderWithContent header='New template'>
      <Container>
        <CreateTemplateForm
          onSaved={(template: DebtRecoveryTemplateWithId) => nav(`/templates/debt-recoveries/${template.id}`)}
          client={useCurrentClient()}
        />
      </Container>
    </HeaderWithContent>
  )
}
