import gql from 'graphql-tag'

export const RecoveriesTemplateFieldsFragment = gql`
  fragment FDebtRecoveryTemplateFields on debt_recovery_template {
    client_id
    id
    language
    name
    type
    sms_content
    email_subject
    email_content
    recovery_content
    archived
    attachment_filename
  }
`

export const TemplateOptionFieldsFragment = gql`
  fragment FDebtRecoveryTemplateOptionFields on debt_recovery_template {
    id
    name
    language
    type
  }
`

export const SearchRecoveriesTemplatesQuery = gql`
  query SearchRecoveryTemplates(
    $limit: Int!
    $offset: Int!
    $where: debt_recovery_template_bool_exp!
    $order_by: [debt_recovery_template_order_by!]
  ) {
    items: debt_recovery_template(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
      ...FDebtRecoveryTemplateFields
    }
    total: debt_recovery_template_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${RecoveriesTemplateFieldsFragment}
`

export const GetTemplateOptionsQuery = gql`
  query GetRecoveryTemplateOptions($where: debt_recovery_template_bool_exp!) {
    results: debt_recovery_template(where: { _and: [$where, { archived: { _eq: false } }] }) {
      ...FTemplateOptionFields
    }
  }
  ${TemplateOptionFieldsFragment}
`

export const InsertTemplateMutation = gql`
  mutation InsertRecoveryTemplate($payload: debt_recovery_template_insert_input!) {
    result: insert_debt_recovery_template(objects: [$payload]) {
      returning {
        ...FDebtRecoveryTemplateFields
      }
    }
  }

  ${RecoveriesTemplateFieldsFragment}
`

export const UpdateTemplateMutation = gql`
  mutation UpdateRecoveryTemplate($id: Int!, $payload: debt_recovery_template_set_input!) {
    result: update_debt_recovery_template(_set: $payload, where: { id: { _eq: $id } }) {
      returning {
        ...FDebtRecoveryTemplateFields
      }
    }
  }

  ${RecoveriesTemplateFieldsFragment}
`

export const GetTemplateQuery = gql`
  query GetRecoveryTemplate($id: Int!) {
    template: debt_recovery_template_by_pk(id: $id) {
      ...FDebtRecoveryTemplateFields
    }
  }
  ${RecoveriesTemplateFieldsFragment}
`
