import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

const DebtorContactFields = gql`
  fragment FDebtorContactFields on debtor_contact {
    id
    value
    city
    country
    post_code
    type
    description
    is_enabled
    debtor_id
  }
`

export const Fragments = {
  DebtorFields: gql`
    fragment FDebtorFields on debtor {
      id
      name
      company_code
      vat_code
      reference_code
      entity_type
      category_id
      recovery_category_id
      suspend_reminders_until
      are_reminders_suspended
      customer_code_in_client_system
      extra_1
      extra_2
    }
  `,
  DebtorContacts: gql`
    fragment FDebtorContacts on debtor {
      debtor_contacts {
        ...FDebtorContactFields
      }
    }
    ${DebtorContactFields}
  `,
  DebtorContactFields
}

export const SearchDebtorsQuery = gql`
  query SearchDebtors($limit: Int!, $offset: Int!, $where: debtor_bool_exp!, $order_by: [debtor_order_by!]) {
    items: debtor(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      ...FDebtorFields
    }
    total: debtor_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }

  ${Fragments.DebtorFields}
`
export const GetDebtorQuery = gql`
  query GetDebtor($id: Int!) {
    debtor: debtor_by_pk(id: $id) {
      ...FDebtorFields
      ...FDebtorContacts
    }
  }
  ${Fragments.DebtorContacts}
  ${Fragments.DebtorFields}
`

export const CountDebtorsQuery = gql`
  query CountDebtors($where: debtor_bool_exp) {
    result: debtor_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const DeleteDebtorMutation = gql`
  mutation DeleteDebtor($id: Int) {
    delete_debtor(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`
export const InsertDebtorMutation = gql`
  mutation InsertDebtor($payload: debtor_insert_input!) {
    result: insert_debtor(objects: [$payload]) {
      returning {
        ...FDebtorFields
      }
    }
  }

  ${Fragments.DebtorFields}
`

export const UpdateDebtorMutation = gql`
  mutation UpdateDebtor($id: Int!, $payload: debtor_set_input!) {
    result: update_debtor(_set: $payload, where: { id: { _eq: $id } }) {
      returning {
        ...FDebtorFields
      }
    }
  }

  ${Fragments.DebtorFields}
`

export const ReplaceDebtorContactsMutation: DocumentNode = gql`
  mutation ReplaceDebtorContacts($debtor_id: Int!, $contacts: [debtor_contact_insert_input!]!) {
    delete_debtor_contact(where: { debtor_id: { _eq: $debtor_id } }) {
      affected_rows
      returning {
        id
        debtor_id
      }
    }
    result: insert_debtor_contact(objects: $contacts) {
      returning {
        ...FDebtorContactFields
      }
    }
  }
  ${Fragments.DebtorContactFields}
`
