import React, { FC, useEffect, useMemo } from 'react'
import { Debtor, DebtorWithId } from '@/models/debtor'
import { Formik } from 'formik'
import { FormikField } from '@/components/form/FormikField'
import { InsertDebtorMutation, UpdateDebtorMutation } from '@/queries/debtors.queries'
import { InsertDebtor, InsertDebtorVariables } from '@/queries/_gen_/InsertDebtor'
import { FormMutation } from '@/components/form/FormMutation'
import { UpdateDebtor, UpdateDebtorVariables } from '@/queries/_gen_/UpdateDebtor'
import * as R from 'ramda'
import * as yup from 'yup'
import ApolloClient from 'apollo-client'
import { debtorUniquenessValidator } from '../debtorValidators'
import { Form } from '@/components/form/Form'
import { FormSubmitButton } from '@/components/form/FormSubmitButton'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { CategorySelectFormikField } from '@/components/CategorySelect'
import { useApolloClient } from 'react-apollo'
import { useEventEmit } from '@/hooks/useEventEmit'
import { DebtorUpdatedEvent } from '@/models/events'
import { FormikDateField } from '@/components/form/FormikDateField'
import { DebtorEntityTypeFormikField } from './EntityTypeSelect'

interface DebtorFormProps {
  debtor: Debtor
  onSubmit: (values: Debtor) => void
  loading?: boolean
  btnLabel?: string
  readOnly?: boolean
}

const emptyDebtor: Debtor = {
  name: '',
  company_code: '',
  vat_code: '',
  reference_code: '',
  customer_code_in_client_system: '',
  category_id: null,
  recovery_category_id: null,
  entity_type: 'individual',
  suspend_reminders_until: null,
  extra_1: null,
  extra_2: null
}

const validationSchema = (apollo: ApolloClient<any>, clientId: number, debtorId?: number) =>
  yup.object().shape({
    name: yup
      .string()
      .required('Required!')
      .test('name_unique', 'Another debtor with this name already exists.', debtorUniquenessValidator(apollo, 'name', clientId, debtorId)),
    company_code: yup
      .string()
      .nullable()
      .test(
        'company_code_unique',
        'Another debtor with this company code already exists.',
        debtorUniquenessValidator(apollo, 'company_code', clientId, debtorId)
      ),
    vat_code: yup
      .mixed()
      .test(
        'vat_code_unique',
        'Another debtor with this VAT code already exists.',
        debtorUniquenessValidator(apollo, 'vat_code', clientId, debtorId)
      ),
    refrence_code: yup
      .mixed()
      .test(
        'reference_code_unique',
        'Another debtor with this reference code already exists.',
        debtorUniquenessValidator(apollo, 'reference_code', clientId, debtorId)
      ),
    customer_code_in_client_system: yup.string().nullable()
  })

const DebtorForm: FC<DebtorFormProps> = ({ debtor, loading, onSubmit, btnLabel, readOnly }) => {
  const client = useCurrentClient()
  const apollo = useApolloClient()
  const vSchema = useMemo(() => validationSchema(apollo, client.id, debtor.id), [apollo, client.id, debtor.id])

  return (
    <Formik<Debtor> initialValues={debtor} onSubmit={onSubmit} validationSchema={vSchema}>
      {({ dirty, setFieldValue }) => (
        <Form className='ant-form ant-form-vertical'>
          <FormikField name='name' label='Name' disabled={readOnly} />
          <FormikField name='company_code' label='Company code' disabled={readOnly} />
          <FormikField name='vat_code' label='VAT code' disabled={readOnly} />
          <FormikField name='reference_code' label='Reference code' disabled={readOnly} />
          <FormikField name='customer_code_in_client_system' label='Customer code in client system' disabled={readOnly} />
          <FormikDateField name='suspend_reminders_until' allowClear={true} label='Suspend reminders until' setFieldValue={setFieldValue} />
          <CategorySelectFormikField name='category_id' allowClear={true} label='Category' clientId={client.id} disabled={readOnly} />
          <CategorySelectFormikField
            name='recovery_category_id'
            allowClear={true}
            label='Recovery Category'
            clientId={client.id}
            disabled={readOnly}
          />
          <DebtorEntityTypeFormikField name='entity_type' label='Entity type' disabled={readOnly} />
          <FormikField name='extra_1' label='Extra 1' disabled={readOnly} />
          <FormikField name='extra_2' label='Extra 2' disabled={readOnly} />
          {!readOnly && (
            <FormSubmitButton htmlType='submit' type='primary' disabled={!dirty || loading} loading={loading}>
              {btnLabel || 'Submit'}
            </FormSubmitButton>
          )}
        </Form>
      )}
    </Formik>
  )
}

interface CreateDebtorFormProps {
  onSaved: (debtor: Debtor) => void
}

export const CreateDebtorForm: FC<CreateDebtorFormProps> = ({ onSaved }) => {
  const client = useCurrentClient()

  return (
    <FormMutation<Debtor, InsertDebtor, InsertDebtorVariables>
      buildVariables={payload => ({ payload: { ...payload, client_id: client.id, company_code: payload.company_code || null } })}
      successMessage={data => (data.result && `Debtor ${data.result.returning[0].name} created!`) || '???'}
      mutation={InsertDebtorMutation}
      refetchQueries={['SearchDebtors']}
    >
      {({ onSubmit, result: { loading, data } }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (data && data.result && data.result.returning.length) {
            onSaved(data.result.returning[0])
          }
        }, [data])
        return <DebtorForm debtor={emptyDebtor} onSubmit={onSubmit} loading={loading} btnLabel='Create debtor' />
      }}
    </FormMutation>
  )
}

interface UpdateDebtorFormProps {
  debtor: DebtorWithId
}

export const UpdateDebtorForm: FC<UpdateDebtorFormProps> = ({ debtor }) => {
  const emitDebtorUpdated = useEventEmit(DebtorUpdatedEvent)

  return (
    <FormMutation<Debtor, UpdateDebtor, UpdateDebtorVariables>
      buildVariables={payload => ({ payload: R.omit(['id', 'are_reminders_suspended'], payload), id: debtor.id })}
      successMessage={data => (data.result && `Debtor ${data.result.returning[0].name} updated!`) || '???'}
      mutation={UpdateDebtorMutation}
      onSaved={() => emitDebtorUpdated(debtor.id)}
      refetchQueries={['GetReminder', 'GetReminderStats']}
    >
      {({ onSubmit, result: { loading } }) => <DebtorForm debtor={debtor} onSubmit={onSubmit} loading={loading} btnLabel='Update debtor' />}
    </FormMutation>
  )
}

export const ReadOnlyDebtorForm: FC<{ debtor: DebtorWithId }> = ({ debtor }) => (
  <DebtorForm
    debtor={debtor}
    onSubmit={() => {
      /* */
    }}
    readOnly={true}
  />
)
