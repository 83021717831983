import React, { FC } from 'react'
import * as R from 'ramda'
import { GetTemplateQuery } from '@/queries/recoveriesTemplates.queries'
import CenterAlert from '@/components/CenterAlert'
import Fetch from '@/components/Fetch'
import { UpdateRecoveryTemplateForm } from './components/TemplateRecoveryForm'
import Container from '@/components/layouts/Container'
import { HeaderWithContent } from '@/components/layouts/HeaderWithContent'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { useParams } from 'react-router-dom'
import { GetRecoveryTemplate, GetRecoveryTemplateVariables } from '@/queries/_gen_/GetRecoveryTemplate'

export const UpdateDebtRecoveriesTemplateView: FC = () => {
  const client = useCurrentClient()
  const id = useParams().id
  const templateId = parseInt(id || '0', 10)

  return (
    <Fetch<GetRecoveryTemplate, GetRecoveryTemplateVariables>
      key={`template-update-${templateId}`}
      query={GetTemplateQuery}
      container={true}
      variables={{ id: templateId }}
      fetchPolicy='network-only'
    >
      {data => {
        if (data.template) {
          const template = R.omit(['__typename'], data.template)
          return (
            <HeaderWithContent header={template.name}>
              <Container>
                <UpdateRecoveryTemplateForm key={template.id} client={client} template={template} />
              </Container>
            </HeaderWithContent>
          )
        }
        return <CenterAlert type='error' message='Template not found :(' />
      }}
    </Fetch>
  )
}
