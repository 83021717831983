import React, { FC, useCallback } from 'react'
import { CommentForm } from './CommentForm'
import { useMutationWithErrorReporting } from '@/hooks/useMutationWithErrorReporting'
import { InsertCommentMutationV2, GetEventsQuery } from '@/queries/events.queries'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { event_insert_input } from '@/queries/_gen_global'
import { useIsMountedRef } from '@/hooks/useIsMountedRef'
import { GetEvents, GetEventsVariables } from '@/queries/_gen_/GetEvents'
import { EventType } from 'common/models/events'
import message from 'antd/lib/message'
import { InsertCommentV2, InsertCommentV2Variables } from '@/queries/_gen_/InsertCommentV2'

interface NewCommentFormProps {
  onSaved: (result: InsertCommentV2) => void // @todo
  onCancel: () => void
  reminderId?: number
  invoiceId?: number
  debtorId: number
  stage?: string
  variables?: GetEventsVariables
}

export const NewCommentForm: FC<NewCommentFormProps> = props => {
  const { onSaved, onCancel, reminderId, invoiceId, debtorId, stage, variables } = props

  const currentClient = useCurrentClient()
  const isMountedRef = useIsMountedRef()
  const isMounted = isMountedRef.current

  const [insertComment, insertCommentResult] = useMutationWithErrorReporting<InsertCommentV2, InsertCommentV2Variables>(
    InsertCommentMutationV2,
    {
      refetchQueries: ['GetDebtorInvoices']
    }
  )
  const onSubmit = useCallback(
    (comment: string) => {
      const eventInput: event_insert_input = {
        client_id: currentClient.id,
        debtor_id: debtorId,
        stage: stage || 'reminder',
        event_type: EventType.comment,
        extra_info: '{}',
        comment: { data: { comment } }
      }

      if (reminderId) {
        eventInput.reminders = {
          data: [
            {
              reminder_id: reminderId
            }
          ]
        }
      }
      if (debtorId) {
        eventInput.debtor_id = debtorId
      }
      if (invoiceId) {
        eventInput.invoices = {
          data: [
            {
              invoice_id: invoiceId
            }
          ]
        }
      }

      insertComment({
        variables: {
          event_input: eventInput
        },
        update: (cache, data) => {
          if (variables) {
            const query = cache.readQuery<GetEvents, GetEventsVariables>({ query: GetEventsQuery, variables })
            if (query && data.data?.insert_event_one) {
              cache.writeQuery<GetEvents, GetEventsVariables>({
                query: GetEventsQuery,
                variables,
                data: {
                  ...query,
                  items: [data.data?.insert_event_one, ...query.items]
                }
              })
            }
          }
        }
      }).then(result => {
        if (result.data && isMounted) {
          message.success('Comment added.')
          onSaved(result.data)
        }
      })
    },
    [onSaved, variables, currentClient.id, debtorId, invoiceId, insertComment, reminderId, isMounted]
  )

  return (
    <CommentForm
      comment=''
      onSubmit={onSubmit}
      onCancel={onCancel}
      loading={insertCommentResult.loading}
      disabled={insertCommentResult.loading}
    />
  )
}
