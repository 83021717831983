import React from 'react'
import CardLink from '@/components/CardLink'
import Container from '@/components/layouts/Container'
import { PlusOutlined } from '@ant-design/icons'

export function RemindersTemplatesIndexView() {
  return TemplatesIndexView('reminders')
}

export function InvoicesTemplatesIndexView() {
  return TemplatesIndexView('invoices')
}

export function DebtRecoveriesTemplatesIndexView() {
  return TemplatesIndexView('debt-recoveries')
}

function TemplatesIndexView(subPath: string) {
  return (
    <Container>
      <CardLink icon={<PlusOutlined />} title='New' to={`/templates/${subPath}/new`}>
        <p>Add a new template</p>
      </CardLink>
    </Container>
  )
}
