import React, { FC, useCallback, ChangeEvent } from 'react'
import { FormikField } from './FormikField'
import Input from 'antd/lib/input'
import AntdForm from 'antd/lib/form'
import * as R from 'ramda'
import { RemoveItemButton } from './RemoveItemButton'
import styled from 'styled-components'
import { FormikFieldError } from './FormError'
import { DebtorEntityTypeFormikField } from '@/views/debtors/components/EntityTypeSelect'
import { EntityType } from '@/models/debtor'

const NumInput = styled(Input)`
  width: 8em !important;
`

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-start;
  margin-bottom: 10px;

  .ant-timeline-item-head {
    margin-bottom: 0;
  }

  .ant-form-item-control {
    flex-direction: column-reverse;
  }

  .ant-form-inline .ant-form-item > .ant-form-item-control {
    display: flex;
  }
`

interface AdministrationFeeSettingsValue {
  from: number
  to: number
  entity_type: EntityType | null
  fee: number
  id?: number
}

interface AdministrationFeeSettingsFormItemProps {
  index: number
  value: AdministrationFeeSettingsValue
  onDelete: () => void
  setFieldValue: (field: string, value: any) => void
}

const DecimalField: FC<{ name: string; label: string; setValue: (value: number) => void }> = ({ name, label, setValue }) => {
  const onChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const num = Number(evt.target.value)
      if (!isNaN(num) && num >= 0) {
        setValue(num)
      }
    },
    [setValue]
  )

  return (
    <FormikField label={label} name={name}>
      {({ field: { value, ...rest } }) => (
        <NumInput size='small' min={0} value={value} onChange={onChange} {...R.omit(['onChange'], rest)} />
      )}
    </FormikField>
  )
}

export const AdministrationFeeSettingsFormItem: FC<AdministrationFeeSettingsFormItemProps> = (
  props: AdministrationFeeSettingsFormItemProps
) => {
  const { onDelete, index, setFieldValue } = props

  return (
    <FormContainer>
      <div className='ant-form ant-form-inline'>
        <DecimalField
          label='From'
          name={`administration_fee_settings.${index}.from`}
          setValue={(val: number) => setFieldValue(`administration_fee_settings.${index}.from`, val)}
        />
        <DecimalField
          label='To'
          name={`administration_fee_settings.${index}.to`}
          setValue={(val: number) => setFieldValue(`administration_fee_settings.${index}.to`, val)}
        />
        <DebtorEntityTypeFormikField label='Entity Type' name={`administration_fee_settings.${index}.entity_type`} />
        <DecimalField
          label='Fee'
          name={`administration_fee_settings.${index}.fee`}
          setValue={(val: number) => setFieldValue(`administration_fee_settings.${index}.fee`, val)}
        />
        <AntdForm.Item>
          <RemoveItemButton onClick={onDelete} />
        </AntdForm.Item>
      </div>
      <FormikFieldError $block={true} name={`administration_fee_settings.${index}`} />
    </FormContainer>
  )
}
