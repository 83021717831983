import React, { FC, useEffect, useState } from 'react'
import { Casecard, CasecardWithId } from '@/models/casecard'
import { Formik } from 'formik'
import { FormikField } from '@/components/form/FormikField'
import { FormikSwitchField } from '@/components/form/FormikSwitchField'
import { DebtorSelect } from '@/components/form/DebtorSelect'
import { CreateDebtor, CreateDebtorButton } from '@/components/form/CreateDebtor'
import { Row, Col } from 'antd'
import { InsertCasecardMutation, UpdateCasecardMutation } from '@/queries/casecards.queries'
import { InsertCasecard, InsertCasecardVariables } from '@/queries/_gen_/InsertCasecard'
import { FormMutation } from '@/components/form/FormMutation'
import { UpdateCasecard, UpdateCasecardVariables } from '@/queries/_gen_/UpdateCasecard'
import * as R from 'ramda'
import * as yup from 'yup'
import { Form } from '@/components/form/Form'
import { FormSubmitButton } from '@/components/form/FormSubmitButton'
import { CasecardFeeTypeFormikField } from './FeeTypeSelect'
import { CurrencySelect } from '@/components/form/CurrencySelect'
import { Debtor } from '@/models/debtor'

interface CasecardFormProps {
  casecard: Casecard
  onSubmit: (values: Casecard) => void
  loading?: boolean
  btnLabel?: string
  readOnly?: boolean
}

const emptyCasecard: Casecard = {
  case_number: '',
  amount_outstanding: 0.0,
  is_paid: false,
  currency: 'EUR',
  penalty_amount: 0.0,
  penalty_type: null,
  commission_amount: 0.0,
  commission_type: null,
  client_cost: 0.0,
  administration_fee: 0.0,
  payment_status: 'due'
}

const validationSchema = yup.object().shape({
  case_number: yup.string().required(),
  amount_outstanding: yup.number().required('Required!'),
  is_paid: yup.boolean().required('Required!'),
  penalty_amount: yup.number(),
  penalty_type: yup.string(),
  commission_amount: yup.number(),
  commission_type: yup.string(),
  client_cost: yup.number()
})

const CasecardForm: FC<CasecardFormProps> = ({ casecard, loading, onSubmit, btnLabel, readOnly }) => {
  const [showCreateDebtor, setShowCreateDebtor] = useState(false)

  return (
    <Formik<Casecard> initialValues={casecard} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ dirty }) => (
        <Form className='ant-form ant-form-vertical'>
          <FormikField name='case_number' label='Case Number' disabled={readOnly} />
          <FormikField name='debtor_id' label='Select Debtor'>
            {({ form }) => {
              if (showCreateDebtor) {
                return (
                  <CreateDebtor
                    onDebtorCreated={(debtor: Debtor) => {
                      // Set the newly created debtor as selected
                      if (debtor.id !== undefined) {
                        form.setFieldValue('debtor_id', debtor.id.toString())
                      }
                      setShowCreateDebtor(false)
                    }}
                    onClose={() => setShowCreateDebtor(false)}
                  />
                )
              }

              return (
                <Row gutter={8} align='middle'>
                  <Col flex='auto'>
                    <DebtorSelect
                      value={form.values.debtor_id}
                      setDebtor={(debtor: Debtor | undefined) => {
                        if (debtor) {
                          //
                        }
                      }}
                      onChange={(value: string) => {
                        form.setFieldValue('debtor_id', value)
                      }}
                      disabled={readOnly}
                    />
                  </Col>
                  <Col>{!readOnly && <CreateDebtorButton onShowCreateDebtor={() => setShowCreateDebtor(true)} disabled={readOnly} />}</Col>
                </Row>
              )
            }}
          </FormikField>
          <FormikField name='currency' label='Currency'>
            {({ field: { ...fieldProps }, form }) => (
              <CurrencySelect {...fieldProps} onChange={(value: string) => form.setFieldValue('currency', value)} disabled={readOnly} />
            )}
          </FormikField>
          <FormikField name='amount_outstanding' label='Amount Outstanding' disabled={readOnly} />
          <FormikSwitchField name='is_paid' label='Is Paid' disabled={readOnly} />
          <FormikField name='penalty_amount' label='Penalty Amount' disabled={readOnly} />
          <CasecardFeeTypeFormikField name='penalty_type' label='Penalty Type' disabled={readOnly} />
          <FormikField name='commission_amount' label='Commission Amount' disabled={readOnly} />
          <CasecardFeeTypeFormikField name='commission_type' label='Commission Type' disabled={readOnly} />
          <FormikField name='client_cost' label='Client Cost' disabled={readOnly} />
          {!readOnly && (
            <FormSubmitButton htmlType='submit' type='primary' disabled={!dirty || loading} loading={loading}>
              {btnLabel || 'Submit'}
            </FormSubmitButton>
          )}
        </Form>
      )}
    </Formik>
  )
}

interface CreateCasecardFormProps {
  onSaved: (casecard: Casecard) => void
}

export const CreateCasecardForm: FC<CreateCasecardFormProps> = ({ onSaved }) => (
  <FormMutation<Casecard, InsertCasecard, InsertCasecardVariables>
    buildVariables={payload => ({ payload })}
    successMessage={data => (data.result && `Casecard ${data.result.returning[0].case_number} created!`) || '???'}
    mutation={InsertCasecardMutation}
    refetchQueries={['SearchCasecards']}
  >
    {({ onSubmit, result: { loading, data } }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        if (data && data.result && data.result.returning.length) {
          onSaved(data.result.returning[0])
        }
      }, [data])
      return <CasecardForm casecard={emptyCasecard} onSubmit={onSubmit} loading={loading} btnLabel='Create Case Card' />
    }}
  </FormMutation>
)

interface UpdateCasecardFormProps {
  casecard: CasecardWithId
  readOnly: boolean
}

export const UpdateCasecardForm: FC<UpdateCasecardFormProps> = ({ casecard, readOnly }) => (
  <FormMutation<Casecard, UpdateCasecard, UpdateCasecardVariables>
    buildVariables={payload => ({ payload: R.omit(['__typename', 'id', 'debtor'], payload), id: casecard.id })}
    successMessage={data => (data.result && `Casecard ${data.result.returning[0].case_number} updated!`) || '???'}
    mutation={UpdateCasecardMutation}
    refetchQueries={['GetCasecard']}
  >
    {({ onSubmit, result: { loading } }) => (
      <CasecardForm casecard={casecard} readOnly={readOnly} onSubmit={onSubmit} loading={loading} btnLabel='Update casecard' />
    )}
  </FormMutation>
)

export const ReadOnlyCasecardForm: FC<{ casecard: CasecardWithId }> = ({ casecard }) => (
  <CasecardForm
    casecard={casecard}
    onSubmit={() => {
      /* */
    }}
    readOnly={true}
  />
)
