import React, { FC, useMemo } from 'react'
import { useQuery } from 'react-apollo'
import { GetCategoryOptions, GetCategoryOptionsVariables } from '@/queries/_gen_/GetCategoryOptions'
import { GetCategoryOptionsQuery } from '@/queries/categories.queries'
import Select, { SelectProps } from 'antd/lib/select'
import { FormikFieldProps, FormikField } from './form/FormikField'
import styled from 'styled-components'
import { formWidth } from '@/style-vars'

const { Option } = Select

interface Props extends SelectProps<number> {
  clientId?: number
  value?: number
  onChange: (value: number | null) => void
}

export const CategorySelect: FC<Props> = props => {
  const { clientId, value, onChange, ...rest } = props

  const variables = useMemo(
    (): GetCategoryOptionsVariables => ({
      where: {
        client_id: {
          _eq: clientId || -1
        }
      }
    }),
    [clientId]
  )

  const { data, loading } = useQuery<GetCategoryOptions, GetCategoryOptionsVariables>(GetCategoryOptionsQuery, {
    variables,
    fetchPolicy: 'cache-and-network'
  })

  const opts = useMemo(() => (data && data.result ? data.result : []), [data])

  return (
    <Select loading={loading} value={value} onChange={onChange} {...rest}>
      {opts.map(opt => (
        <Option key={opt.id} value={opt.id}>
          {opt.name}
        </Option>
      ))}
    </Select>
  )
}

interface CategorySelectFormikFieldProps extends Omit<FormikFieldProps, 'render'> {
  clientId?: number
  allowClear?: boolean
  disabled?: boolean
  help?: string
}

export const CategorySelectFormikField: FC<CategorySelectFormikFieldProps> = ({ clientId, allowClear, disabled, help, ...props }) => (
  <FormikField {...props}>
    {({ field, form }) => (
      <>
        <CategorySelect
          placeholder='None'
          allowClear={allowClear}
          disabled={disabled}
          value={field.value || undefined}
          onChange={value => form.setFieldValue(field.name, value)}
          clientId={clientId}
          notFoundContent='Client currently has no categories. Please create one.'
        />
        {help && <p>{help}</p>}
      </>
    )}
  </FormikField>
)

export const ShortCategorySelectFormikField = styled(CategorySelectFormikField)`
  max-width: ${formWidth};
`
