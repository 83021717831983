import React, { FC } from 'react'
import Select, { SelectProps } from 'antd/lib/select'
import { feeTypes, feeTypeNames, FeeType } from '@/models/casecard'
import { FormikField, FormikFieldProps } from '@/components/form/FormikField'
import styled from 'styled-components'
import { formWidth } from '@/style-vars'

const { Option } = Select

interface CasecardFeeTypeFormikFieldProps extends Omit<FormikFieldProps, 'render'> {
  disabled?: boolean
  help?: string
}

export const FeeTypeSelect: FC<SelectProps<FeeType>> = ({ value, ...props }) => (
  <Select {...props} value={value || undefined} placeholder='Select a type...'>
    {feeTypes.map(feeType => (
      <Option key={feeType} value={feeType}>
        {feeTypeNames[feeType]}
      </Option>
    ))}
  </Select>
)

export const CasecardFeeTypeFormikField: FC<CasecardFeeTypeFormikFieldProps> = ({ disabled, help, ...props }) => (
  <FormikField {...props}>
    {({ field, form }) => (
      <>
        <FeeTypeSelect
          placeholder='Select Type'
          value={field.value || undefined}
          onChange={value => form.setFieldValue(field.name, value)}
          disabled={disabled}
        />
        {help && <p>{help}</p>}
      </>
    )}
  </FormikField>
)

export const ShortCasecardFeeTypeFormikField = styled(CasecardFeeTypeFormikField)`
  max-width: ${formWidth};
`
