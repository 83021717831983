import gql from 'graphql-tag'

export const CasecardFieldsFragment = gql`
  fragment FCasecardFields on casecard {
    id
    case_number
    amount_outstanding
    payment_status
    is_paid
    currency
    penalty_amount
    penalty_type
    commission_amount
    commission_type
    administration_fee
    client_cost
    debtor {
      id
      name
      company_code
    }
  }
`

export const SearchCasecardsQuery = gql`
  query SearchCasecards($where: casecard_bool_exp!, $order_by: [casecard_order_by!], $limit: Int!, $offset: Int!) {
    items: casecard(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      ...FCasecardFields
    }
    total: casecard_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${CasecardFieldsFragment}
`

export const GetCasecardQuery = gql`
  query GetCasecard($id: Int!) {
    casecard: casecard_by_pk(id: $id) {
      ...FCasecardFields
    }
  }
  ${CasecardFieldsFragment}
`

export const InsertCasecardMutation = gql`
  mutation InsertCasecard($payload: casecard_insert_input!) {
    result: insert_casecard(objects: [$payload]) {
      returning {
        ...FCasecardFields
      }
    }
  }

  ${CasecardFieldsFragment}
`

export const UpdateCasecardMutation = gql`
  mutation UpdateCasecard($id: Int!, $payload: casecard_set_input!) {
    result: update_casecard(_set: $payload, where: { id: { _eq: $id } }) {
      returning {
        ...FCasecardFields
      }
    }
  }

  ${CasecardFieldsFragment}
`
