import { ColumnType } from '@/models/casecard_import/xls'
import dateFnsParseDate from 'date-fns/parse'
import formatDate from 'date-fns/format'
import isDateValid from 'date-fns/isValid'
import * as yup from 'yup'
import Decimal from 'decimal.js'
import currencyCodes from 'currency-codes'
import { ImportContext } from '@/models/casecard_import/plan'

const dateFormats: string[] = ['yyyy-MM-dd', 'yyyy.MM.dd', 'yyyyMMdd', 'dd/MM/yyyy', 'MM/dd/yy', 'yyyy/MM/dd']

export const parseDate = (value: string): Date | null => {
  for (const dateFormat of dateFormats) {
    try {
      const parsed = dateFnsParseDate(value, dateFormat, new Date())
      if (isDateValid(parsed)) {
        return parsed
      }
    } catch (e) {
      /* */
    }
  }
  return null
}

const rePhoneNumber = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/

export const parseSeparatedItems = (value: string): string[] =>
  value
    .split(/[,;]/)
    .map(x => x.trim())
    .filter(x => !!x)

/* VALIDATORS */
type Validator = (value: string, context: ImportContext) => string[]
const dateValidator: Validator = value => (parseDate(value) ? [] : ['Invalid date. Should be something like: 2019-11-03'])
const amountValidator: Validator = value => {
  try {
    const d = new Decimal(value)
    return d.decimalPlaces() <= 2 ? [] : ['Must have no more than 2 decimal places.']
  } catch (e) {
    return ['Invalid amount. Should be something like: 42.01']
  }
}
const phoneValidator: Validator = value =>
  parseSeparatedItems(value)
    .map(value => (rePhoneNumber.test(value) ? null : `Invalid phone number "${value}". Should be something like: +37012345678`))
    .filter((x): x is string => !!x)
const emailValidator: Validator = value =>
  parseSeparatedItems(value)
    .map(v => (yup.string().email().isValidSync(v) ? null : `Invalid email address "${v}".`))
    .filter((x): x is string => !!x)
const currencyValidator: Validator = value =>
  currencyCodes.code(value.toUpperCase()) ? [] : ['Invalid currency code. Expected something like: "EUR" or "USD" or etc.']

// const categoryValidator: Validator = (value, context) =>
//   context.clientCategories.find(cat => cat.name === value)
//     ? []
//     : [`Category not found. Available categories: ${context.clientCategories.map(c => c.name).join(',')}`]

/* FORMATTERS */
type Formatter = (value: string) => string

const dateFormatter: Formatter = value => {
  const dateval = parseDate(value)
  return dateval ? formatDate(dateval, dateFormats[0]) : value
}

const amountFormatter: Formatter = value => {
  const num = Number(value)
  if (!isNaN(num)) {
    return num.toFixed(2)
  }
  return value
}

/* COLUMN CONFIG */

export interface ColumnConfig {
  minWidth?: number
  validators?: Validator[]
  formatter?: Formatter
  number?: boolean
  required?: boolean
  unique?: boolean
}

export const columnTypes: { [key in ColumnType]: ColumnConfig } = {
  [ColumnType.skip]: {},
  [ColumnType.customerRefNo]: {
    required: true
  },
  [ColumnType.companyCode]: {
    required: true
  },
  [ColumnType.companyName]: {
    required: true
  },
  [ColumnType.debtorAddress]: {
    required: true
  },
  [ColumnType.debtorCity]: {
    required: true
  },
  [ColumnType.debtorPhoneNumber]: {
    validators: [phoneValidator],
    required: true
  },
  [ColumnType.caseNumber]: {
    required: true
  },
  [ColumnType.caseDate]: {
    validators: [dateValidator],
    formatter: dateFormatter,
    required: true
  },
  [ColumnType.casePaymentDate]: {
    validators: [dateValidator],
    formatter: dateFormatter,
    required: true
  },
  [ColumnType.amountOutstanding]: {
    validators: [amountValidator],
    formatter: amountFormatter,
    required: true
  },
  [ColumnType.currency]: {
    validators: [currencyValidator],
    required: false
  },
  [ColumnType.debtorEmail]: {
    validators: [emailValidator],
    required: true
  },
  [ColumnType.penaltyAmount]: {
    validators: [amountValidator],
    formatter: amountFormatter,
    required: false
  }
}

export interface RowValues {
  [ColumnType.customerRefNo]: string | undefined
  [ColumnType.companyCode]: string | undefined
  [ColumnType.companyName]: string | undefined
  [ColumnType.debtorAddress]: string | undefined
  [ColumnType.debtorCity]: string | undefined
  [ColumnType.debtorPhoneNumber]: string | undefined
  [ColumnType.caseNumber]: string | undefined
  [ColumnType.caseDate]: Date | undefined
  [ColumnType.casePaymentDate]: Date | undefined
  [ColumnType.currency]: string | undefined
  [ColumnType.amountOutstanding]: Decimal | undefined
  [ColumnType.debtorEmail]: string | undefined
  [ColumnType.penaltyAmount]: Decimal | undefined
}
