import { Client } from '@/models/client'
import { Category } from '@/models/category'

/**
 * Fee range definition for administration fees
 */
interface FeeRange {
  entity_type: string
  fee: number
  from: number
  to: number
}

/**
 * Calculates the administration fee based on client and category settings and debt amount
 *
 * @param client - The client object containing administration_fee_settings
 * @param category - The category object containing administration_fee_settings
 * @param debt_amount - The debt amount to calculate the fee for
 * @returns The calculated administration fee or undefined if no matching fee range is found
 */
export const AdministrationFeeCalc = (debt_amount: number, client: Client, category?: Category): number | undefined => {
  // Get fee settings from client and category
  const clientFeeSettings: FeeRange[] = client.administration_fee_settings || []
  const categoryFeeSettings: FeeRange[] = category?.administration_fee_settings || []

  // First check if there's a matching range in the category settings
  const categoryFeeRange = categoryFeeSettings.find(setting => debt_amount > setting.from && debt_amount <= setting.to)

  // If a matching range is found in category settings, return its fee
  if (categoryFeeRange) {
    return categoryFeeRange.fee
  }

  // If no matching range in category settings, check client settings
  const clientFeeRange = clientFeeSettings.find(setting => debt_amount > setting.from && debt_amount <= setting.to)

  // Return the fee from client settings if found, otherwise undefined
  return clientFeeRange?.fee
}

export default AdministrationFeeCalc
