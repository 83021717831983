import React, { useMemo } from 'react'
import Timeline from 'antd/lib/timeline'
import { GetEventsQuery } from '@/queries/events.queries'
import { GetEvents, GetEventsVariables, GetEvents_items } from '@/queries/_gen_/GetEvents'
import { event_bool_exp, order_by } from '@/queries/_gen_global'
import { EventItem } from './EventItem'
import { eventType2Color, EventType } from 'common/models/events'
import { useSearchQuery } from '@/hooks/useSearchQuery'
import { useSortBy } from '@/hooks/searchHelperHooks'
import InfiniteScroller from 'react-infinite-scroller'
import Alert from 'antd/lib/alert'
import { NewCommentItem } from './NewCommentItem'
import { MessageOutlined } from '@ant-design/icons'
import { useDelay } from '@/hooks/useDelay'
import { withSpan } from '../contexts/TraceContext'

interface EventsHistoryProps {
  reminderId?: number
  invoiceId?: number
  casecardId?: number
  debtorId: number
  commentsOnly?: boolean // show only comments
  debtorLevel?: boolean // even if reminderId or invoiceId provided, fetch all events for debtor.
  stage?: string
  // new comments are saved at most specific level tho
}

/* modified to target closest parent with overflow: auto css prop. kind of a hack, but easy*/
class HistoryInfScroller extends InfiniteScroller {
  getParentElement(elem?: HTMLElement | null): HTMLElement {
    if (elem) {
      const style = window.getComputedStyle(elem)
      if (style.getPropertyValue('overflow') === 'auto' || style.getPropertyValue('overflow-y') === 'auto') {
        return elem
      }
      return this.getParentElement(elem.parentElement)
    }
    return {
      removeEventListener: () => {}
    } as any
  }
}

export const EventsHistory = withSpan<EventsHistoryProps>(
  'EventsHistory',
  props => ({
    'reminder.id': props.reminderId,
    'invoice.id': props.invoiceId,
    'casecard.id': props.casecardId,
    'debtor.id': props.debtorId,
    'comments.only': String(props.commentsOnly || false),
    stage: props.stage || 'reminder'
  }),
  (props: EventsHistoryProps): JSX.Element => {
    const { reminderId, invoiceId, casecardId, debtorId, commentsOnly, debtorLevel, stage = 'reminder' } = props

    const where: event_bool_exp = useMemo((): event_bool_exp => {
      const result: event_bool_exp = {}

      if (reminderId && !debtorLevel) {
        result.reminders = { reminder_id: { _eq: reminderId } }
      } else if (invoiceId && !debtorLevel) {
        result.invoices = { invoice_id: { _eq: invoiceId } }
      } else if (casecardId && !debtorLevel) {
        result.casecards = { casecard_id: { _eq: casecardId } }
      } else {
        result.debtor_id = { _eq: debtorId }
      }

      if (commentsOnly) {
        result.comment_id = { _is_null: false }
      }

      if (stage) {
        result.stage = { _eq: stage }
      }

      return result
    }, [reminderId, invoiceId, debtorId, commentsOnly, debtorLevel])

    const searchBag = useSearchQuery<GetEvents_items, GetEventsVariables, GetEvents>({
      query: GetEventsQuery,
      extraVariables: useMemo(
        () => ({
          where: {
            ...where,
            is_deleted: { _eq: false }
          }
        }),
        [where]
      ),
      defaultSort: useSortBy('created_at', order_by.desc)
    })

    const { error, loading, fetchMore, fetchingMore, hasMore, items, variables } = searchBag

    const showLoaderDelayDone = useDelay([loading])

    return (
      <HistoryInfScroller
        hasMore={hasMore && !fetchingMore && !loading && !error}
        useWindow={false}
        loadMore={fetchMore}
        key={`${searchBag.query}-${searchBag.variables}`}
      >
        {error && <Alert type='error' message={error.message} />}
        {!error && (
          <Timeline pending={loading && showLoaderDelayDone && 'Loading...'}>
            <Timeline.Item>
              <NewCommentItem reminderId={reminderId} invoiceId={invoiceId} debtorId={debtorId} stage={stage} variables={variables} />
            </Timeline.Item>
            {items.map(event => (
              <Timeline.Item
                key={event.id}
                dot={event.event_type === EventType.comment ? <MessageOutlined /> : undefined}
                color={eventType2Color[event.event_type]}
              >
                <EventItem event={event} variables={variables} />
              </Timeline.Item>
            ))}
            {!items.length && (
              <Timeline.Item>
                <p>{commentsOnly ? 'No comments yet' : 'No events found'}</p>
              </Timeline.Item>
            )}
          </Timeline>
        )}
      </HistoryInfScroller>
    )
  }
)
