import React, { FC } from 'react'
import Select, { SelectProps } from 'antd/lib/select'
import { entityTypes, entityTypeNames, EntityType } from '@/models/debtor'
import { FormikField, FormikFieldProps } from '@/components/form/FormikField'

const { Option } = Select

interface DebtorEntityTypeFormikFieldProps extends Omit<FormikFieldProps, 'render'> {
  disabled?: boolean
  help?: string
}

export const DebtorEntityTypeFormikField: FC<DebtorEntityTypeFormikFieldProps> = ({ disabled, help, ...props }) => (
  <FormikField {...props}>
    {({ field, form }) => (
      <>
        <EntityTypeSelect
          placeholder='Select Type'
          value={field.value || undefined}
          onChange={value => form.setFieldValue(field.name, value)}
          disabled={disabled}
        />
        {help && <p>{help}</p>}
      </>
    )}
  </FormikField>
)

export const EntityTypeSelect: FC<SelectProps<EntityType>> = ({ value, ...props }) => (
  <Select {...props} value={value || undefined} placeholder='Select a type...'>
    {entityTypes.map(entityType => (
      <Option key={entityType} value={entityType}>
        {entityTypeNames[entityType]}
      </Option>
    ))}
  </Select>
)
