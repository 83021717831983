/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * unique or primary key constraints on table "casecard"
 */
export enum casecard_constraint {
  casecard_case_number_debtor_id_key = "casecard_case_number_debtor_id_key",
  casecard_pkey = "casecard_pkey",
}

/**
 * unique or primary key constraints on table "casecard_events"
 */
export enum casecard_events_constraint {
  casecard_events_pkey = "casecard_events_pkey",
}

/**
 * select columns of table "casecard_events"
 */
export enum casecard_events_select_column {
  casecard_id = "casecard_id",
  event_id = "event_id",
}

/**
 * update columns of table "casecard_events"
 */
export enum casecard_events_update_column {
  casecard_id = "casecard_id",
  event_id = "event_id",
}

/**
 * unique or primary key constraints on table "casecard_payment"
 */
export enum casecard_payment_constraint {
  casecard_payment_pkey = "casecard_payment_pkey",
}

/**
 * select columns of table "casecard_payment"
 */
export enum casecard_payment_select_column {
  amount = "amount",
  casecard_id = "casecard_id",
  payment_id = "payment_id",
}

/**
 * update columns of table "casecard_payment"
 */
export enum casecard_payment_update_column {
  amount = "amount",
  casecard_id = "casecard_id",
  payment_id = "payment_id",
}

/**
 * select columns of table "casecard"
 */
export enum casecard_select_column {
  administration_fee = "administration_fee",
  amount_outstanding = "amount_outstanding",
  case_number = "case_number",
  case_payment_date = "case_payment_date",
  client_cost = "client_cost",
  commission_amount = "commission_amount",
  commission_type = "commission_type",
  created_at = "created_at",
  creditor_id = "creditor_id",
  currency = "currency",
  customer_ref_no = "customer_ref_no",
  debtor_id = "debtor_id",
  id = "id",
  is_paid = "is_paid",
  payment_status = "payment_status",
  penalty_amount = "penalty_amount",
  penalty_type = "penalty_type",
  updated_at = "updated_at",
}

/**
 * select "casecard_aggregate_bool_exp_bool_and_arguments_columns" columns of table "casecard"
 */
export enum casecard_select_column_casecard_aggregate_bool_exp_bool_and_arguments_columns {
  is_paid = "is_paid",
}

/**
 * select "casecard_aggregate_bool_exp_bool_or_arguments_columns" columns of table "casecard"
 */
export enum casecard_select_column_casecard_aggregate_bool_exp_bool_or_arguments_columns {
  is_paid = "is_paid",
}

/**
 * update columns of table "casecard"
 */
export enum casecard_update_column {
  administration_fee = "administration_fee",
  amount_outstanding = "amount_outstanding",
  case_number = "case_number",
  case_payment_date = "case_payment_date",
  client_cost = "client_cost",
  commission_amount = "commission_amount",
  commission_type = "commission_type",
  created_at = "created_at",
  creditor_id = "creditor_id",
  currency = "currency",
  customer_ref_no = "customer_ref_no",
  debtor_id = "debtor_id",
  id = "id",
  is_paid = "is_paid",
  payment_status = "payment_status",
  penalty_amount = "penalty_amount",
  penalty_type = "penalty_type",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "category"
 */
export enum category_constraint {
  category_pkey = "category_pkey",
}

/**
 * unique or primary key constraints on table "category_step"
 */
export enum category_step_constraint {
  category_step_category_id_days_from_days_to_template_id_reminde = "category_step_category_id_days_from_days_to_template_id_reminde",
  category_step_pkey = "category_step_pkey",
}

/**
 * select columns of table "category_step"
 */
export enum category_step_select_column {
  category_id = "category_id",
  created_at = "created_at",
  created_by = "created_by",
  days_from = "days_from",
  days_to = "days_to",
  id = "id",
  reminder_type = "reminder_type",
  template_id = "template_id",
  updated_at = "updated_at",
  updated_by = "updated_by",
}

/**
 * update columns of table "category_step"
 */
export enum category_step_update_column {
  category_id = "category_id",
  created_at = "created_at",
  created_by = "created_by",
  days_from = "days_from",
  days_to = "days_to",
  id = "id",
  reminder_type = "reminder_type",
  template_id = "template_id",
  updated_at = "updated_at",
  updated_by = "updated_by",
}

/**
 * update columns of table "category"
 */
export enum category_update_column {
  administration_fee_settings = "administration_fee_settings",
  client_id = "client_id",
  commission_amount = "commission_amount",
  commission_type = "commission_type",
  created_at = "created_at",
  created_by = "created_by",
  debt_recovery_template_id = "debt_recovery_template_id",
  from_email = "from_email",
  from_sms = "from_sms",
  id = "id",
  invoice_due_days = "invoice_due_days",
  invoice_template_id = "invoice_template_id",
  name = "name",
  penalty_amount = "penalty_amount",
  penalty_type = "penalty_type",
  recovery_due_days = "recovery_due_days",
  stage = "stage",
  updated_at = "updated_at",
  updated_by = "updated_by",
}

/**
 * unique or primary key constraints on table "client"
 */
export enum client_constraint {
  client_company_code_key1 = "client_company_code_key1",
  client_name_key1 = "client_name_key1",
  client_pkey1 = "client_pkey1",
  client_vat_code_key = "client_vat_code_key",
}

/**
 * update columns of table "client"
 */
export enum client_update_column {
  address = "address",
  administration_fee_settings = "administration_fee_settings",
  automatic_reminder_gen_enabled = "automatic_reminder_gen_enabled",
  casecard_series = "casecard_series",
  category_id = "category_id",
  commission_amount = "commission_amount",
  commission_type = "commission_type",
  company_code = "company_code",
  date_format = "date_format",
  date_locale = "date_locale",
  default_currency = "default_currency",
  id = "id",
  invoice_series = "invoice_series",
  is_archived = "is_archived",
  name = "name",
  next_casecard_number = "next_casecard_number",
  next_invoice_number = "next_invoice_number",
  penalty_amount = "penalty_amount",
  penalty_type = "penalty_type",
  reference_code = "reference_code",
  vat_code = "vat_code",
}

/**
 * unique or primary key constraints on table "comment"
 */
export enum comment_constraint {
  comment_pkey = "comment_pkey",
}

/**
 * update columns of table "comment"
 */
export enum comment_update_column {
  comment = "comment",
  created_at = "created_at",
  created_by = "created_by",
  id = "id",
  updated_at = "updated_at",
  updated_by = "updated_by",
}

/**
 * unique or primary key constraints on table "creditor"
 */
export enum creditor_constraint {
  creditor_pkey = "creditor_pkey",
}

/**
 * update columns of table "creditor"
 */
export enum creditor_update_column {
  category_id = "category_id",
  client_id = "client_id",
  created_at = "created_at",
  created_by = "created_by",
  id = "id",
  name = "name",
  updated_at = "updated_at",
  updated_by = "updated_by",
}

/**
 * unique or primary key constraints on table "debtor"
 */
export enum debtor_constraint {
  client_pkey = "client_pkey",
  debtor_company_code_client_id_key = "debtor_company_code_client_id_key",
  debtor_name_client_id_key = "debtor_name_client_id_key",
}

/**
 * unique or primary key constraints on table "debtor_contact"
 */
export enum debtor_contact_constraint {
  client_contact_pkey = "client_contact_pkey",
  debtor_contact_debtor_id_type_value_key = "debtor_contact_debtor_id_type_value_key",
}

/**
 * select columns of table "debtor_contact"
 */
export enum debtor_contact_select_column {
  city = "city",
  country = "country",
  created_at = "created_at",
  created_by = "created_by",
  debtor_id = "debtor_id",
  description = "description",
  id = "id",
  import_file_id = "import_file_id",
  is_enabled = "is_enabled",
  post_code = "post_code",
  type = "type",
  updated_at = "updated_at",
  updated_by = "updated_by",
  value = "value",
}

/**
 * select "debtor_contact_aggregate_bool_exp_bool_and_arguments_columns" columns of table "debtor_contact"
 */
export enum debtor_contact_select_column_debtor_contact_aggregate_bool_exp_bool_and_arguments_columns {
  is_enabled = "is_enabled",
}

/**
 * select "debtor_contact_aggregate_bool_exp_bool_or_arguments_columns" columns of table "debtor_contact"
 */
export enum debtor_contact_select_column_debtor_contact_aggregate_bool_exp_bool_or_arguments_columns {
  is_enabled = "is_enabled",
}

/**
 * update columns of table "debtor_contact"
 */
export enum debtor_contact_update_column {
  city = "city",
  country = "country",
  created_at = "created_at",
  created_by = "created_by",
  debtor_id = "debtor_id",
  description = "description",
  id = "id",
  import_file_id = "import_file_id",
  is_enabled = "is_enabled",
  post_code = "post_code",
  type = "type",
  updated_at = "updated_at",
  updated_by = "updated_by",
  value = "value",
}

/**
 * select columns of table "debtor"
 */
export enum debtor_select_column {
  category_id = "category_id",
  client_id = "client_id",
  company_code = "company_code",
  created_at = "created_at",
  created_by = "created_by",
  customer_code_in_client_system = "customer_code_in_client_system",
  entity_type = "entity_type",
  extra_1 = "extra_1",
  extra_2 = "extra_2",
  id = "id",
  import_file_id = "import_file_id",
  name = "name",
  recovery_category_id = "recovery_category_id",
  reference_code = "reference_code",
  suspend_reminders_until = "suspend_reminders_until",
  updated_at = "updated_at",
  updated_by = "updated_by",
  vat_code = "vat_code",
}

/**
 * update columns of table "debtor"
 */
export enum debtor_update_column {
  category_id = "category_id",
  client_id = "client_id",
  company_code = "company_code",
  created_at = "created_at",
  created_by = "created_by",
  customer_code_in_client_system = "customer_code_in_client_system",
  entity_type = "entity_type",
  extra_1 = "extra_1",
  extra_2 = "extra_2",
  id = "id",
  import_file_id = "import_file_id",
  name = "name",
  recovery_category_id = "recovery_category_id",
  reference_code = "reference_code",
  suspend_reminders_until = "suspend_reminders_until",
  updated_at = "updated_at",
  updated_by = "updated_by",
  vat_code = "vat_code",
}

/**
 * unique or primary key constraints on table "event"
 */
export enum event_constraint {
  event_pkey = "event_pkey",
}

/**
 * select columns of table "event"
 */
export enum event_select_column {
  client_id = "client_id",
  comment_id = "comment_id",
  created_at = "created_at",
  created_by = "created_by",
  debtor_id = "debtor_id",
  event_type = "event_type",
  extra_info = "extra_info",
  id = "id",
  is_deleted = "is_deleted",
  stage = "stage",
}

/**
 * select "event_aggregate_bool_exp_bool_and_arguments_columns" columns of table "event"
 */
export enum event_select_column_event_aggregate_bool_exp_bool_and_arguments_columns {
  is_deleted = "is_deleted",
}

/**
 * select "event_aggregate_bool_exp_bool_or_arguments_columns" columns of table "event"
 */
export enum event_select_column_event_aggregate_bool_exp_bool_or_arguments_columns {
  is_deleted = "is_deleted",
}

/**
 * update columns of table "event"
 */
export enum event_update_column {
  client_id = "client_id",
  comment_id = "comment_id",
  created_at = "created_at",
  created_by = "created_by",
  debtor_id = "debtor_id",
  event_type = "event_type",
  extra_info = "extra_info",
  id = "id",
  is_deleted = "is_deleted",
  stage = "stage",
}

/**
 * unique or primary key constraints on table "import_file"
 */
export enum import_file_constraint {
  import_file_client_id_bucket_filename_import_type_key = "import_file_client_id_bucket_filename_import_type_key",
  import_file_pkey = "import_file_pkey",
}

/**
 * unique or primary key constraints on table "import_file_invoice"
 */
export enum import_file_invoice_constraint {
  import_file_invoice_import_file_id_invoice_id_key = "import_file_invoice_import_file_id_invoice_id_key",
  import_file_invoice_pkey = "import_file_invoice_pkey",
}

/**
 * select columns of table "import_file_invoice"
 */
export enum import_file_invoice_select_column {
  id = "id",
  import_file_id = "import_file_id",
  invoice_id = "invoice_id",
}

/**
 * update columns of table "import_file_invoice"
 */
export enum import_file_invoice_update_column {
  id = "id",
  import_file_id = "import_file_id",
  invoice_id = "invoice_id",
}

/**
 * update columns of table "import_file"
 */
export enum import_file_update_column {
  bucket_filename = "bucket_filename",
  client_id = "client_id",
  created_at = "created_at",
  created_by = "created_by",
  id = "id",
  import_options = "import_options",
  import_type = "import_type",
  orig_filename = "orig_filename",
  raw_data = "raw_data",
}

/**
 * unique or primary key constraints on table "invoice_attachments"
 */
export enum invoice_attachments_constraint {
  invoice_attachments_filename_key = "invoice_attachments_filename_key",
  invoice_attachments_pkey = "invoice_attachments_pkey",
}

/**
 * select columns of table "invoice_attachments"
 */
export enum invoice_attachments_select_column {
  client_id = "client_id",
  created_at = "created_at",
  file_location = "file_location",
  filename = "filename",
  folder = "folder",
  id = "id",
  invoice_id = "invoice_id",
  orig_filename = "orig_filename",
}

/**
 * update columns of table "invoice_attachments"
 */
export enum invoice_attachments_update_column {
  client_id = "client_id",
  created_at = "created_at",
  file_location = "file_location",
  filename = "filename",
  folder = "folder",
  id = "id",
  invoice_id = "invoice_id",
  orig_filename = "orig_filename",
}

/**
 * unique or primary key constraints on table "invoice"
 */
export enum invoice_constraint {
  invoice_debtor_id_document_number_key = "invoice_debtor_id_document_number_key",
  invoice_pkey = "invoice_pkey",
}

/**
 * unique or primary key constraints on table "invoice_events"
 */
export enum invoice_events_constraint {
  invoice_events_pkey = "invoice_events_pkey",
}

/**
 * select columns of table "invoice_events"
 */
export enum invoice_events_select_column {
  event_id = "event_id",
  invoice_id = "invoice_id",
}

/**
 * update columns of table "invoice_events"
 */
export enum invoice_events_update_column {
  event_id = "event_id",
  invoice_id = "invoice_id",
}

/**
 * unique or primary key constraints on table "invoice_line_item"
 */
export enum invoice_line_item_constraint {
  invoice_line_item_pkey = "invoice_line_item_pkey",
}

/**
 * select columns of table "invoice_line_item"
 */
export enum invoice_line_item_select_column {
  created_at = "created_at",
  created_by = "created_by",
  id = "id",
  invoice_id = "invoice_id",
  name = "name",
  price = "price",
  product_id = "product_id",
  quantity = "quantity",
  updated_at = "updated_at",
  updated_by = "updated_by",
}

/**
 * update columns of table "invoice_line_item"
 */
export enum invoice_line_item_update_column {
  created_at = "created_at",
  created_by = "created_by",
  id = "id",
  invoice_id = "invoice_id",
  name = "name",
  price = "price",
  product_id = "product_id",
  quantity = "quantity",
  updated_at = "updated_at",
  updated_by = "updated_by",
}

/**
 * unique or primary key constraints on table "invoice_payment"
 */
export enum invoice_payment_constraint {
  invoice_payment_pkey = "invoice_payment_pkey",
}

/**
 * select columns of table "invoice_payment"
 */
export enum invoice_payment_select_column {
  amount = "amount",
  invoice_id = "invoice_id",
  payment_id = "payment_id",
}

/**
 * update columns of table "invoice_payment"
 */
export enum invoice_payment_update_column {
  amount = "amount",
  invoice_id = "invoice_id",
  payment_id = "payment_id",
}

/**
 * select columns of table "invoice"
 */
export enum invoice_select_column {
  amount_outstanding = "amount_outstanding",
  amount_paid = "amount_paid",
  amount_with_vat = "amount_with_vat",
  amount_wo_vat = "amount_wo_vat",
  created_at = "created_at",
  created_by = "created_by",
  currency = "currency",
  date_1 = "date_1",
  date_2 = "date_2",
  debtor_id = "debtor_id",
  document_date = "document_date",
  document_number = "document_number",
  due_date = "due_date",
  extra_1 = "extra_1",
  extra_2 = "extra_2",
  extra_3 = "extra_3",
  extra_4 = "extra_4",
  extra_5 = "extra_5",
  extra_6 = "extra_6",
  extra_document_number_1 = "extra_document_number_1",
  full_amount = "full_amount",
  id = "id",
  invoice_type = "invoice_type",
  is_paid = "is_paid",
  stage = "stage",
  suspend_reminders_until = "suspend_reminders_until",
  time_1 = "time_1",
  updated_at = "updated_at",
  updated_by = "updated_by",
  vat = "vat",
}

/**
 * select "invoice_aggregate_bool_exp_bool_and_arguments_columns" columns of table "invoice"
 */
export enum invoice_select_column_invoice_aggregate_bool_exp_bool_and_arguments_columns {
  is_paid = "is_paid",
}

/**
 * select "invoice_aggregate_bool_exp_bool_or_arguments_columns" columns of table "invoice"
 */
export enum invoice_select_column_invoice_aggregate_bool_exp_bool_or_arguments_columns {
  is_paid = "is_paid",
}

/**
 * unique or primary key constraints on table "invoice_template"
 */
export enum invoice_template_constraint {
  invoice_template_pkey = "invoice_template_pkey",
}

/**
 * update columns of table "invoice_template"
 */
export enum invoice_template_update_column {
  archived = "archived",
  client_id = "client_id",
  content = "content",
  created_at = "created_at",
  created_by = "created_by",
  id = "id",
  language = "language",
  name = "name",
  updated_at = "updated_at",
  updated_by = "updated_by",
  version = "version",
}

/**
 * update columns of table "invoice"
 */
export enum invoice_update_column {
  amount_outstanding = "amount_outstanding",
  amount_paid = "amount_paid",
  amount_with_vat = "amount_with_vat",
  amount_wo_vat = "amount_wo_vat",
  created_at = "created_at",
  created_by = "created_by",
  currency = "currency",
  date_1 = "date_1",
  date_2 = "date_2",
  debtor_id = "debtor_id",
  document_date = "document_date",
  document_number = "document_number",
  due_date = "due_date",
  extra_1 = "extra_1",
  extra_2 = "extra_2",
  extra_3 = "extra_3",
  extra_4 = "extra_4",
  extra_5 = "extra_5",
  extra_6 = "extra_6",
  extra_document_number_1 = "extra_document_number_1",
  full_amount = "full_amount",
  id = "id",
  invoice_type = "invoice_type",
  is_paid = "is_paid",
  stage = "stage",
  suspend_reminders_until = "suspend_reminders_until",
  time_1 = "time_1",
  updated_at = "updated_at",
  updated_by = "updated_by",
  vat = "vat",
}

/**
 * column ordering options
 */
export enum order_by {
  asc = "asc",
  asc_nulls_first = "asc_nulls_first",
  asc_nulls_last = "asc_nulls_last",
  desc = "desc",
  desc_nulls_first = "desc_nulls_first",
  desc_nulls_last = "desc_nulls_last",
}

/**
 * unique or primary key constraints on table "payment"
 */
export enum payment_constraint {
  payment_client_id_amount_payer_name_payment_date_key = "payment_client_id_amount_payer_name_payment_date_key",
  payment_debtor_id_payment_date_amount_currency_from_key = "payment_debtor_id_payment_date_amount_currency_from_key",
  payments_pkey = "payments_pkey",
}

/**
 * update columns of table "payment"
 */
export enum payment_update_column {
  amount = "amount",
  client_id = "client_id",
  created_at = "created_at",
  created_by = "created_by",
  currency = "currency",
  debtor_id = "debtor_id",
  from = "from",
  iban = "iban",
  id = "id",
  import_file_id = "import_file_id",
  payer_name = "payer_name",
  payment_date = "payment_date",
  reason = "reason",
  updated_at = "updated_at",
  updated_by = "updated_by",
}

/**
 * unique or primary key constraints on table "reminder"
 */
export enum reminder_constraint {
  reminder_debtor_id_type_import_file_id_client_id_period_period_ = "reminder_debtor_id_type_import_file_id_client_id_period_period_",
  reminder_pkey = "reminder_pkey",
}

/**
 * unique or primary key constraints on table "reminder_events"
 */
export enum reminder_events_constraint {
  reminder_events_pkey = "reminder_events_pkey",
}

/**
 * select columns of table "reminder_events"
 */
export enum reminder_events_select_column {
  event_id = "event_id",
  reminder_id = "reminder_id",
  reminder_send_request_id = "reminder_send_request_id",
}

/**
 * update columns of table "reminder_events"
 */
export enum reminder_events_update_column {
  event_id = "event_id",
  reminder_id = "reminder_id",
  reminder_send_request_id = "reminder_send_request_id",
}

/**
 * select columns of table "reminder"
 */
export enum reminder_select_column {
  cancelled_at = "cancelled_at",
  cancelled_by = "cancelled_by",
  client_id = "client_id",
  completed_at = "completed_at",
  completed_by = "completed_by",
  created_at = "created_at",
  created_by = "created_by",
  debtor_id = "debtor_id",
  id = "id",
  import_file_id = "import_file_id",
  period = "period",
  period_days_from = "period_days_from",
  period_days_to = "period_days_to",
  stage = "stage",
  status = "status",
  template_id = "template_id",
  type = "type",
  updated_at = "updated_at",
  updated_by = "updated_by",
}

/**
 * unique or primary key constraints on table "reminder_send_request"
 */
export enum reminder_send_request_constraint {
  reminder_history_pkey = "reminder_history_pkey",
}

/**
 * select columns of table "reminder_send_request"
 */
export enum reminder_send_request_select_column {
  created_at = "created_at",
  created_by = "created_by",
  email_attachments = "email_attachments",
  email_body = "email_body",
  email_recipients = "email_recipients",
  email_subject = "email_subject",
  error_phone_numbers = "error_phone_numbers",
  error_reason = "error_reason",
  id = "id",
  message_id = "message_id",
  reminder_id = "reminder_id",
  reminders_send_request_id = "reminders_send_request_id",
  sms = "sms",
  status = "status",
  success_phone_numbers = "success_phone_numbers",
  updated_at = "updated_at",
}

/**
 * update columns of table "reminder_send_request"
 */
export enum reminder_send_request_update_column {
  created_at = "created_at",
  created_by = "created_by",
  email_attachments = "email_attachments",
  email_body = "email_body",
  email_recipients = "email_recipients",
  email_subject = "email_subject",
  error_phone_numbers = "error_phone_numbers",
  error_reason = "error_reason",
  id = "id",
  message_id = "message_id",
  reminder_id = "reminder_id",
  reminders_send_request_id = "reminders_send_request_id",
  sms = "sms",
  status = "status",
  success_phone_numbers = "success_phone_numbers",
  updated_at = "updated_at",
}

/**
 * update columns of table "reminder"
 */
export enum reminder_update_column {
  cancelled_at = "cancelled_at",
  cancelled_by = "cancelled_by",
  client_id = "client_id",
  completed_at = "completed_at",
  completed_by = "completed_by",
  created_at = "created_at",
  created_by = "created_by",
  debtor_id = "debtor_id",
  id = "id",
  import_file_id = "import_file_id",
  period = "period",
  period_days_from = "period_days_from",
  period_days_to = "period_days_to",
  stage = "stage",
  status = "status",
  template_id = "template_id",
  type = "type",
  updated_at = "updated_at",
  updated_by = "updated_by",
}

/**
 * unique or primary key constraints on table "reminders_send_request"
 */
export enum reminders_send_request_constraint {
  reminders_send_request_pkey = "reminders_send_request_pkey",
}

/**
 * update columns of table "reminders_send_request"
 */
export enum reminders_send_request_update_column {
  client_id = "client_id",
  completed_at = "completed_at",
  created_at = "created_at",
  created_by = "created_by",
  id = "id",
  started_at = "started_at",
}

/**
 * unique or primary key constraints on table "template"
 */
export enum template_constraint {
  template_client_id_type_name_language_key = "template_client_id_type_name_language_key",
  template_pkey = "template_pkey",
}

/**
 * select columns of table "template"
 */
export enum template_select_column {
  archived = "archived",
  attachment_filename = "attachment_filename",
  client_id = "client_id",
  created_at = "created_at",
  created_by = "created_by",
  email_content = "email_content",
  email_subject = "email_subject",
  id = "id",
  language = "language",
  name = "name",
  reminder_content = "reminder_content",
  sms_content = "sms_content",
  type = "type",
  updated_at = "updated_at",
  updated_by = "updated_by",
}

/**
 * select "template_aggregate_bool_exp_bool_and_arguments_columns" columns of table "template"
 */
export enum template_select_column_template_aggregate_bool_exp_bool_and_arguments_columns {
  archived = "archived",
}

/**
 * select "template_aggregate_bool_exp_bool_or_arguments_columns" columns of table "template"
 */
export enum template_select_column_template_aggregate_bool_exp_bool_or_arguments_columns {
  archived = "archived",
}

/**
 * update columns of table "template"
 */
export enum template_update_column {
  archived = "archived",
  attachment_filename = "attachment_filename",
  client_id = "client_id",
  created_at = "created_at",
  created_by = "created_by",
  email_content = "email_content",
  email_subject = "email_subject",
  id = "id",
  language = "language",
  name = "name",
  reminder_content = "reminder_content",
  sms_content = "sms_content",
  type = "type",
  updated_at = "updated_at",
  updated_by = "updated_by",
}

/**
 * unique or primary key constraints on table "user"
 */
export enum user_constraint {
  user_email_key = "user_email_key",
  user_pkey = "user_pkey",
}

/**
 * unique or primary key constraints on table "user_role"
 */
export enum user_role_constraint {
  user_role_pkey = "user_role_pkey",
}

/**
 * select columns of table "user_role"
 */
export enum user_role_select_column {
  client_id = "client_id",
  created_at = "created_at",
  created_by = "created_by",
  dr_general = "dr_general",
  dr_judicial = "dr_judicial",
  dr_recovery = "dr_recovery",
  dr_reminder = "dr_reminder",
  id = "id",
  role = "role",
  user_id = "user_id",
}

/**
 * select "user_role_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_role"
 */
export enum user_role_select_column_user_role_aggregate_bool_exp_bool_and_arguments_columns {
  dr_general = "dr_general",
  dr_judicial = "dr_judicial",
  dr_recovery = "dr_recovery",
  dr_reminder = "dr_reminder",
}

/**
 * select "user_role_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_role"
 */
export enum user_role_select_column_user_role_aggregate_bool_exp_bool_or_arguments_columns {
  dr_general = "dr_general",
  dr_judicial = "dr_judicial",
  dr_recovery = "dr_recovery",
  dr_reminder = "dr_reminder",
}

/**
 * update columns of table "user_role"
 */
export enum user_role_update_column {
  client_id = "client_id",
  created_at = "created_at",
  created_by = "created_by",
  dr_general = "dr_general",
  dr_judicial = "dr_judicial",
  dr_recovery = "dr_recovery",
  dr_reminder = "dr_reminder",
  id = "id",
  role = "role",
  user_id = "user_id",
}

/**
 * update columns of table "user"
 */
export enum user_update_column {
  created_at = "created_at",
  created_by = "created_by",
  email = "email",
  first_name = "first_name",
  id = "id",
  is_active = "is_active",
  is_admin = "is_admin",
  last_name = "last_name",
  password = "password",
  password_reset_token = "password_reset_token",
  updated_at = "updated_at",
  updated_by = "updated_by",
}

/**
 * Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'.
 */
export interface Boolean_comparison_exp {
  _eq?: boolean | null;
  _gt?: boolean | null;
  _gte?: boolean | null;
  _in?: boolean[] | null;
  _is_null?: boolean | null;
  _lt?: boolean | null;
  _lte?: boolean | null;
  _neq?: boolean | null;
  _nin?: boolean[] | null;
}

/**
 * Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'.
 */
export interface Int_comparison_exp {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: number[] | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: number[] | null;
}

/**
 * Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'.
 */
export interface String_comparison_exp {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _ilike?: string | null;
  _in?: string[] | null;
  _iregex?: string | null;
  _is_null?: boolean | null;
  _like?: string | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nilike?: string | null;
  _nin?: string[] | null;
  _niregex?: string | null;
  _nlike?: string | null;
  _nregex?: string | null;
  _nsimilar?: string | null;
  _regex?: string | null;
  _similar?: string | null;
}

/**
 * Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'.
 */
export interface bigint_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

export interface casecard_aggregate_bool_exp {
  bool_and?: casecard_aggregate_bool_exp_bool_and | null;
  bool_or?: casecard_aggregate_bool_exp_bool_or | null;
  count?: casecard_aggregate_bool_exp_count | null;
}

export interface casecard_aggregate_bool_exp_bool_and {
  arguments: casecard_select_column_casecard_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: casecard_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface casecard_aggregate_bool_exp_bool_or {
  arguments: casecard_select_column_casecard_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: casecard_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface casecard_aggregate_bool_exp_count {
  arguments?: casecard_select_column[] | null;
  distinct?: boolean | null;
  filter?: casecard_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "casecard"
 */
export interface casecard_aggregate_order_by {
  avg?: casecard_avg_order_by | null;
  count?: order_by | null;
  max?: casecard_max_order_by | null;
  min?: casecard_min_order_by | null;
  stddev?: casecard_stddev_order_by | null;
  stddev_pop?: casecard_stddev_pop_order_by | null;
  stddev_samp?: casecard_stddev_samp_order_by | null;
  sum?: casecard_sum_order_by | null;
  var_pop?: casecard_var_pop_order_by | null;
  var_samp?: casecard_var_samp_order_by | null;
  variance?: casecard_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "casecard"
 */
export interface casecard_arr_rel_insert_input {
  data: casecard_insert_input[];
  on_conflict?: casecard_on_conflict | null;
}

/**
 * order by avg() on columns of table "casecard"
 */
export interface casecard_avg_order_by {
  administration_fee?: order_by | null;
  amount_outstanding?: order_by | null;
  client_cost?: order_by | null;
  commission_amount?: order_by | null;
  creditor_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  penalty_amount?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "casecard". All fields are combined with a logical 'AND'.
 */
export interface casecard_bool_exp {
  _and?: casecard_bool_exp[] | null;
  _not?: casecard_bool_exp | null;
  _or?: casecard_bool_exp[] | null;
  administration_fee?: numeric_comparison_exp | null;
  amount_outstanding?: numeric_comparison_exp | null;
  case_number?: String_comparison_exp | null;
  case_payment_date?: date_comparison_exp | null;
  client_cost?: numeric_comparison_exp | null;
  commission_amount?: numeric_comparison_exp | null;
  commission_type?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  creditor?: creditor_bool_exp | null;
  creditor_id?: Int_comparison_exp | null;
  currency?: String_comparison_exp | null;
  customer_ref_no?: String_comparison_exp | null;
  debtor?: debtor_bool_exp | null;
  debtor_id?: Int_comparison_exp | null;
  events?: casecard_events_bool_exp | null;
  events_aggregate?: casecard_events_aggregate_bool_exp | null;
  id?: Int_comparison_exp | null;
  is_paid?: Boolean_comparison_exp | null;
  payment_status?: String_comparison_exp | null;
  penalty_amount?: numeric_comparison_exp | null;
  penalty_type?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

export interface casecard_events_aggregate_bool_exp {
  count?: casecard_events_aggregate_bool_exp_count | null;
}

export interface casecard_events_aggregate_bool_exp_count {
  arguments?: casecard_events_select_column[] | null;
  distinct?: boolean | null;
  filter?: casecard_events_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "casecard_events"
 */
export interface casecard_events_aggregate_order_by {
  avg?: casecard_events_avg_order_by | null;
  count?: order_by | null;
  max?: casecard_events_max_order_by | null;
  min?: casecard_events_min_order_by | null;
  stddev?: casecard_events_stddev_order_by | null;
  stddev_pop?: casecard_events_stddev_pop_order_by | null;
  stddev_samp?: casecard_events_stddev_samp_order_by | null;
  sum?: casecard_events_sum_order_by | null;
  var_pop?: casecard_events_var_pop_order_by | null;
  var_samp?: casecard_events_var_samp_order_by | null;
  variance?: casecard_events_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "casecard_events"
 */
export interface casecard_events_arr_rel_insert_input {
  data: casecard_events_insert_input[];
  on_conflict?: casecard_events_on_conflict | null;
}

/**
 * order by avg() on columns of table "casecard_events"
 */
export interface casecard_events_avg_order_by {
  casecard_id?: order_by | null;
  event_id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "casecard_events". All fields are combined with a logical 'AND'.
 */
export interface casecard_events_bool_exp {
  _and?: casecard_events_bool_exp[] | null;
  _not?: casecard_events_bool_exp | null;
  _or?: casecard_events_bool_exp[] | null;
  casecard?: casecard_bool_exp | null;
  casecard_id?: bigint_comparison_exp | null;
  event?: event_bool_exp | null;
  event_id?: bigint_comparison_exp | null;
}

/**
 * input type for inserting data into table "casecard_events"
 */
export interface casecard_events_insert_input {
  casecard?: casecard_obj_rel_insert_input | null;
  casecard_id?: any | null;
  event?: event_obj_rel_insert_input | null;
  event_id?: any | null;
}

/**
 * order by max() on columns of table "casecard_events"
 */
export interface casecard_events_max_order_by {
  casecard_id?: order_by | null;
  event_id?: order_by | null;
}

/**
 * order by min() on columns of table "casecard_events"
 */
export interface casecard_events_min_order_by {
  casecard_id?: order_by | null;
  event_id?: order_by | null;
}

/**
 * on_conflict condition type for table "casecard_events"
 */
export interface casecard_events_on_conflict {
  constraint: casecard_events_constraint;
  update_columns: casecard_events_update_column[];
  where?: casecard_events_bool_exp | null;
}

/**
 * order by stddev() on columns of table "casecard_events"
 */
export interface casecard_events_stddev_order_by {
  casecard_id?: order_by | null;
  event_id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "casecard_events"
 */
export interface casecard_events_stddev_pop_order_by {
  casecard_id?: order_by | null;
  event_id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "casecard_events"
 */
export interface casecard_events_stddev_samp_order_by {
  casecard_id?: order_by | null;
  event_id?: order_by | null;
}

/**
 * order by sum() on columns of table "casecard_events"
 */
export interface casecard_events_sum_order_by {
  casecard_id?: order_by | null;
  event_id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "casecard_events"
 */
export interface casecard_events_var_pop_order_by {
  casecard_id?: order_by | null;
  event_id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "casecard_events"
 */
export interface casecard_events_var_samp_order_by {
  casecard_id?: order_by | null;
  event_id?: order_by | null;
}

/**
 * order by variance() on columns of table "casecard_events"
 */
export interface casecard_events_variance_order_by {
  casecard_id?: order_by | null;
  event_id?: order_by | null;
}

/**
 * input type for inserting data into table "casecard"
 */
export interface casecard_insert_input {
  administration_fee?: any | null;
  amount_outstanding?: any | null;
  case_number?: string | null;
  case_payment_date?: any | null;
  client_cost?: any | null;
  commission_amount?: any | null;
  commission_type?: string | null;
  created_at?: any | null;
  creditor?: creditor_obj_rel_insert_input | null;
  creditor_id?: number | null;
  currency?: string | null;
  customer_ref_no?: string | null;
  debtor?: debtor_obj_rel_insert_input | null;
  debtor_id?: number | null;
  events?: casecard_events_arr_rel_insert_input | null;
  id?: number | null;
  is_paid?: boolean | null;
  payment_status?: string | null;
  penalty_amount?: any | null;
  penalty_type?: string | null;
  updated_at?: any | null;
}

/**
 * order by max() on columns of table "casecard"
 */
export interface casecard_max_order_by {
  administration_fee?: order_by | null;
  amount_outstanding?: order_by | null;
  case_number?: order_by | null;
  case_payment_date?: order_by | null;
  client_cost?: order_by | null;
  commission_amount?: order_by | null;
  commission_type?: order_by | null;
  created_at?: order_by | null;
  creditor_id?: order_by | null;
  currency?: order_by | null;
  customer_ref_no?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  payment_status?: order_by | null;
  penalty_amount?: order_by | null;
  penalty_type?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "casecard"
 */
export interface casecard_min_order_by {
  administration_fee?: order_by | null;
  amount_outstanding?: order_by | null;
  case_number?: order_by | null;
  case_payment_date?: order_by | null;
  client_cost?: order_by | null;
  commission_amount?: order_by | null;
  commission_type?: order_by | null;
  created_at?: order_by | null;
  creditor_id?: order_by | null;
  currency?: order_by | null;
  customer_ref_no?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  payment_status?: order_by | null;
  penalty_amount?: order_by | null;
  penalty_type?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "casecard"
 */
export interface casecard_obj_rel_insert_input {
  data: casecard_insert_input;
  on_conflict?: casecard_on_conflict | null;
}

/**
 * on_conflict condition type for table "casecard"
 */
export interface casecard_on_conflict {
  constraint: casecard_constraint;
  update_columns: casecard_update_column[];
  where?: casecard_bool_exp | null;
}

/**
 * Ordering options when selecting data from "casecard".
 */
export interface casecard_order_by {
  administration_fee?: order_by | null;
  amount_outstanding?: order_by | null;
  case_number?: order_by | null;
  case_payment_date?: order_by | null;
  client_cost?: order_by | null;
  commission_amount?: order_by | null;
  commission_type?: order_by | null;
  created_at?: order_by | null;
  creditor?: creditor_order_by | null;
  creditor_id?: order_by | null;
  currency?: order_by | null;
  customer_ref_no?: order_by | null;
  debtor?: debtor_order_by | null;
  debtor_id?: order_by | null;
  events_aggregate?: casecard_events_aggregate_order_by | null;
  id?: order_by | null;
  is_paid?: order_by | null;
  payment_status?: order_by | null;
  penalty_amount?: order_by | null;
  penalty_type?: order_by | null;
  updated_at?: order_by | null;
}

export interface casecard_payment_aggregate_bool_exp {
  count?: casecard_payment_aggregate_bool_exp_count | null;
}

export interface casecard_payment_aggregate_bool_exp_count {
  arguments?: casecard_payment_select_column[] | null;
  distinct?: boolean | null;
  filter?: casecard_payment_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "casecard_payment"
 */
export interface casecard_payment_arr_rel_insert_input {
  data: casecard_payment_insert_input[];
  on_conflict?: casecard_payment_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "casecard_payment". All fields are combined with a logical 'AND'.
 */
export interface casecard_payment_bool_exp {
  _and?: casecard_payment_bool_exp[] | null;
  _not?: casecard_payment_bool_exp | null;
  _or?: casecard_payment_bool_exp[] | null;
  amount?: numeric_comparison_exp | null;
  casecard?: casecard_bool_exp | null;
  casecard_id?: bigint_comparison_exp | null;
  payment?: payment_bool_exp | null;
  payment_id?: bigint_comparison_exp | null;
}

/**
 * input type for inserting data into table "casecard_payment"
 */
export interface casecard_payment_insert_input {
  amount?: any | null;
  casecard?: casecard_obj_rel_insert_input | null;
  casecard_id?: any | null;
  payment?: payment_obj_rel_insert_input | null;
  payment_id?: any | null;
}

/**
 * on_conflict condition type for table "casecard_payment"
 */
export interface casecard_payment_on_conflict {
  constraint: casecard_payment_constraint;
  update_columns: casecard_payment_update_column[];
  where?: casecard_payment_bool_exp | null;
}

/**
 * input type for updating data in table "casecard"
 */
export interface casecard_set_input {
  administration_fee?: any | null;
  amount_outstanding?: any | null;
  case_number?: string | null;
  case_payment_date?: any | null;
  client_cost?: any | null;
  commission_amount?: any | null;
  commission_type?: string | null;
  created_at?: any | null;
  creditor_id?: number | null;
  currency?: string | null;
  customer_ref_no?: string | null;
  debtor_id?: number | null;
  id?: number | null;
  is_paid?: boolean | null;
  payment_status?: string | null;
  penalty_amount?: any | null;
  penalty_type?: string | null;
  updated_at?: any | null;
}

/**
 * order by stddev() on columns of table "casecard"
 */
export interface casecard_stddev_order_by {
  administration_fee?: order_by | null;
  amount_outstanding?: order_by | null;
  client_cost?: order_by | null;
  commission_amount?: order_by | null;
  creditor_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  penalty_amount?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "casecard"
 */
export interface casecard_stddev_pop_order_by {
  administration_fee?: order_by | null;
  amount_outstanding?: order_by | null;
  client_cost?: order_by | null;
  commission_amount?: order_by | null;
  creditor_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  penalty_amount?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "casecard"
 */
export interface casecard_stddev_samp_order_by {
  administration_fee?: order_by | null;
  amount_outstanding?: order_by | null;
  client_cost?: order_by | null;
  commission_amount?: order_by | null;
  creditor_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  penalty_amount?: order_by | null;
}

/**
 * order by sum() on columns of table "casecard"
 */
export interface casecard_sum_order_by {
  administration_fee?: order_by | null;
  amount_outstanding?: order_by | null;
  client_cost?: order_by | null;
  commission_amount?: order_by | null;
  creditor_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  penalty_amount?: order_by | null;
}

/**
 * order by var_pop() on columns of table "casecard"
 */
export interface casecard_var_pop_order_by {
  administration_fee?: order_by | null;
  amount_outstanding?: order_by | null;
  client_cost?: order_by | null;
  commission_amount?: order_by | null;
  creditor_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  penalty_amount?: order_by | null;
}

/**
 * order by var_samp() on columns of table "casecard"
 */
export interface casecard_var_samp_order_by {
  administration_fee?: order_by | null;
  amount_outstanding?: order_by | null;
  client_cost?: order_by | null;
  commission_amount?: order_by | null;
  creditor_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  penalty_amount?: order_by | null;
}

/**
 * order by variance() on columns of table "casecard"
 */
export interface casecard_variance_order_by {
  administration_fee?: order_by | null;
  amount_outstanding?: order_by | null;
  client_cost?: order_by | null;
  commission_amount?: order_by | null;
  creditor_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  penalty_amount?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "category". All fields are combined with a logical 'AND'.
 */
export interface category_bool_exp {
  _and?: category_bool_exp[] | null;
  _not?: category_bool_exp | null;
  _or?: category_bool_exp[] | null;
  administration_fee_settings?: json_comparison_exp | null;
  category_steps?: category_step_bool_exp | null;
  category_steps_aggregate?: category_step_aggregate_bool_exp | null;
  client?: client_bool_exp | null;
  client_id?: Int_comparison_exp | null;
  commission_amount?: Int_comparison_exp | null;
  commission_type?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: String_comparison_exp | null;
  debt_recovery_template_id?: bigint_comparison_exp | null;
  from_email?: String_comparison_exp | null;
  from_sms?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  invoice_due_days?: Int_comparison_exp | null;
  invoice_template?: invoice_template_bool_exp | null;
  invoice_template_id?: bigint_comparison_exp | null;
  name?: String_comparison_exp | null;
  penalty_amount?: numeric_comparison_exp | null;
  penalty_type?: String_comparison_exp | null;
  recovery_due_days?: Int_comparison_exp | null;
  stage?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  updated_by?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "category"
 */
export interface category_insert_input {
  administration_fee_settings?: any | null;
  category_steps?: category_step_arr_rel_insert_input | null;
  client?: client_obj_rel_insert_input | null;
  client_id?: number | null;
  commission_amount?: number | null;
  commission_type?: string | null;
  created_at?: any | null;
  created_by?: string | null;
  debt_recovery_template_id?: any | null;
  from_email?: string | null;
  from_sms?: string | null;
  id?: number | null;
  invoice_due_days?: number | null;
  invoice_template?: invoice_template_obj_rel_insert_input | null;
  invoice_template_id?: any | null;
  name?: string | null;
  penalty_amount?: any | null;
  penalty_type?: string | null;
  recovery_due_days?: number | null;
  stage?: string | null;
  updated_at?: any | null;
  updated_by?: string | null;
}

/**
 * input type for inserting object relation for remote table "category"
 */
export interface category_obj_rel_insert_input {
  data: category_insert_input;
  on_conflict?: category_on_conflict | null;
}

/**
 * on_conflict condition type for table "category"
 */
export interface category_on_conflict {
  constraint: category_constraint;
  update_columns: category_update_column[];
  where?: category_bool_exp | null;
}

/**
 * Ordering options when selecting data from "category".
 */
export interface category_order_by {
  administration_fee_settings?: order_by | null;
  category_steps_aggregate?: category_step_aggregate_order_by | null;
  client?: client_order_by | null;
  client_id?: order_by | null;
  commission_amount?: order_by | null;
  commission_type?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  debt_recovery_template_id?: order_by | null;
  from_email?: order_by | null;
  from_sms?: order_by | null;
  id?: order_by | null;
  invoice_due_days?: order_by | null;
  invoice_template?: invoice_template_order_by | null;
  invoice_template_id?: order_by | null;
  name?: order_by | null;
  penalty_amount?: order_by | null;
  penalty_type?: order_by | null;
  recovery_due_days?: order_by | null;
  stage?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
}

/**
 * input type for updating data in table "category"
 */
export interface category_set_input {
  administration_fee_settings?: any | null;
  client_id?: number | null;
  commission_amount?: number | null;
  commission_type?: string | null;
  created_at?: any | null;
  created_by?: string | null;
  debt_recovery_template_id?: any | null;
  from_email?: string | null;
  from_sms?: string | null;
  id?: number | null;
  invoice_due_days?: number | null;
  invoice_template_id?: any | null;
  name?: string | null;
  penalty_amount?: any | null;
  penalty_type?: string | null;
  recovery_due_days?: number | null;
  stage?: string | null;
  updated_at?: any | null;
  updated_by?: string | null;
}

export interface category_step_aggregate_bool_exp {
  count?: category_step_aggregate_bool_exp_count | null;
}

export interface category_step_aggregate_bool_exp_count {
  arguments?: category_step_select_column[] | null;
  distinct?: boolean | null;
  filter?: category_step_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "category_step"
 */
export interface category_step_aggregate_order_by {
  avg?: category_step_avg_order_by | null;
  count?: order_by | null;
  max?: category_step_max_order_by | null;
  min?: category_step_min_order_by | null;
  stddev?: category_step_stddev_order_by | null;
  stddev_pop?: category_step_stddev_pop_order_by | null;
  stddev_samp?: category_step_stddev_samp_order_by | null;
  sum?: category_step_sum_order_by | null;
  var_pop?: category_step_var_pop_order_by | null;
  var_samp?: category_step_var_samp_order_by | null;
  variance?: category_step_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "category_step"
 */
export interface category_step_arr_rel_insert_input {
  data: category_step_insert_input[];
  on_conflict?: category_step_on_conflict | null;
}

/**
 * order by avg() on columns of table "category_step"
 */
export interface category_step_avg_order_by {
  category_id?: order_by | null;
  days_from?: order_by | null;
  days_to?: order_by | null;
  id?: order_by | null;
  template_id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "category_step". All fields are combined with a logical 'AND'.
 */
export interface category_step_bool_exp {
  _and?: category_step_bool_exp[] | null;
  _not?: category_step_bool_exp | null;
  _or?: category_step_bool_exp[] | null;
  category?: category_bool_exp | null;
  category_id?: Int_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: String_comparison_exp | null;
  days_from?: Int_comparison_exp | null;
  days_to?: Int_comparison_exp | null;
  id?: Int_comparison_exp | null;
  reminder_type?: String_comparison_exp | null;
  template?: template_bool_exp | null;
  template_id?: Int_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  updated_by?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "category_step"
 */
export interface category_step_insert_input {
  category?: category_obj_rel_insert_input | null;
  category_id?: number | null;
  created_at?: any | null;
  created_by?: string | null;
  days_from?: number | null;
  days_to?: number | null;
  id?: number | null;
  reminder_type?: string | null;
  template?: template_obj_rel_insert_input | null;
  template_id?: number | null;
  updated_at?: any | null;
  updated_by?: string | null;
}

/**
 * order by max() on columns of table "category_step"
 */
export interface category_step_max_order_by {
  category_id?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  days_from?: order_by | null;
  days_to?: order_by | null;
  id?: order_by | null;
  reminder_type?: order_by | null;
  template_id?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
}

/**
 * order by min() on columns of table "category_step"
 */
export interface category_step_min_order_by {
  category_id?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  days_from?: order_by | null;
  days_to?: order_by | null;
  id?: order_by | null;
  reminder_type?: order_by | null;
  template_id?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
}

/**
 * on_conflict condition type for table "category_step"
 */
export interface category_step_on_conflict {
  constraint: category_step_constraint;
  update_columns: category_step_update_column[];
  where?: category_step_bool_exp | null;
}

/**
 * order by stddev() on columns of table "category_step"
 */
export interface category_step_stddev_order_by {
  category_id?: order_by | null;
  days_from?: order_by | null;
  days_to?: order_by | null;
  id?: order_by | null;
  template_id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "category_step"
 */
export interface category_step_stddev_pop_order_by {
  category_id?: order_by | null;
  days_from?: order_by | null;
  days_to?: order_by | null;
  id?: order_by | null;
  template_id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "category_step"
 */
export interface category_step_stddev_samp_order_by {
  category_id?: order_by | null;
  days_from?: order_by | null;
  days_to?: order_by | null;
  id?: order_by | null;
  template_id?: order_by | null;
}

/**
 * order by sum() on columns of table "category_step"
 */
export interface category_step_sum_order_by {
  category_id?: order_by | null;
  days_from?: order_by | null;
  days_to?: order_by | null;
  id?: order_by | null;
  template_id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "category_step"
 */
export interface category_step_var_pop_order_by {
  category_id?: order_by | null;
  days_from?: order_by | null;
  days_to?: order_by | null;
  id?: order_by | null;
  template_id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "category_step"
 */
export interface category_step_var_samp_order_by {
  category_id?: order_by | null;
  days_from?: order_by | null;
  days_to?: order_by | null;
  id?: order_by | null;
  template_id?: order_by | null;
}

/**
 * order by variance() on columns of table "category_step"
 */
export interface category_step_variance_order_by {
  category_id?: order_by | null;
  days_from?: order_by | null;
  days_to?: order_by | null;
  id?: order_by | null;
  template_id?: order_by | null;
}

/**
 * input type for inserting data into table "client_api_keys"
 */
export interface client_api_keys_insert_input {
  api_key?: string | null;
  client?: client_obj_rel_insert_input | null;
  client_id?: number | null;
  created_at?: any | null;
  id?: number | null;
  is_expired?: boolean | null;
  updated_at?: any | null;
}

/**
 * Boolean expression to filter rows from the table "client". All fields are combined with a logical 'AND'.
 */
export interface client_bool_exp {
  _and?: client_bool_exp[] | null;
  _not?: client_bool_exp | null;
  _or?: client_bool_exp[] | null;
  address?: String_comparison_exp | null;
  administration_fee_settings?: json_comparison_exp | null;
  automatic_reminder_gen_enabled?: Boolean_comparison_exp | null;
  casecard_series?: String_comparison_exp | null;
  category?: category_bool_exp | null;
  category_id?: Int_comparison_exp | null;
  commission_amount?: numeric_comparison_exp | null;
  commission_type?: String_comparison_exp | null;
  company_code?: String_comparison_exp | null;
  date_format?: String_comparison_exp | null;
  date_locale?: String_comparison_exp | null;
  debtors?: debtor_bool_exp | null;
  debtors_aggregate?: debtor_aggregate_bool_exp | null;
  default_currency?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  invoice_series?: String_comparison_exp | null;
  is_archived?: Boolean_comparison_exp | null;
  name?: String_comparison_exp | null;
  next_casecard_number?: Int_comparison_exp | null;
  next_invoice_number?: Int_comparison_exp | null;
  penalty_amount?: numeric_comparison_exp | null;
  penalty_type?: String_comparison_exp | null;
  reference_code?: String_comparison_exp | null;
  templates?: template_bool_exp | null;
  templates_aggregate?: template_aggregate_bool_exp | null;
  user_roles?: user_role_bool_exp | null;
  user_roles_aggregate?: user_role_aggregate_bool_exp | null;
  vat_code?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "client"
 */
export interface client_insert_input {
  address?: string | null;
  administration_fee_settings?: any | null;
  automatic_reminder_gen_enabled?: boolean | null;
  casecard_series?: string | null;
  category?: category_obj_rel_insert_input | null;
  category_id?: number | null;
  commission_amount?: any | null;
  commission_type?: string | null;
  company_code?: string | null;
  date_format?: string | null;
  date_locale?: string | null;
  debtors?: debtor_arr_rel_insert_input | null;
  default_currency?: string | null;
  id?: number | null;
  invoice_series?: string | null;
  is_archived?: boolean | null;
  name?: string | null;
  next_casecard_number?: number | null;
  next_invoice_number?: number | null;
  penalty_amount?: any | null;
  penalty_type?: string | null;
  reference_code?: string | null;
  templates?: template_arr_rel_insert_input | null;
  user_roles?: user_role_arr_rel_insert_input | null;
  vat_code?: string | null;
}

/**
 * input type for inserting object relation for remote table "client"
 */
export interface client_obj_rel_insert_input {
  data: client_insert_input;
  on_conflict?: client_on_conflict | null;
}

/**
 * on_conflict condition type for table "client"
 */
export interface client_on_conflict {
  constraint: client_constraint;
  update_columns: client_update_column[];
  where?: client_bool_exp | null;
}

/**
 * Ordering options when selecting data from "client".
 */
export interface client_order_by {
  address?: order_by | null;
  administration_fee_settings?: order_by | null;
  automatic_reminder_gen_enabled?: order_by | null;
  casecard_series?: order_by | null;
  category?: category_order_by | null;
  category_id?: order_by | null;
  commission_amount?: order_by | null;
  commission_type?: order_by | null;
  company_code?: order_by | null;
  date_format?: order_by | null;
  date_locale?: order_by | null;
  debtors_aggregate?: debtor_aggregate_order_by | null;
  default_currency?: order_by | null;
  id?: order_by | null;
  invoice_series?: order_by | null;
  is_archived?: order_by | null;
  name?: order_by | null;
  next_casecard_number?: order_by | null;
  next_invoice_number?: order_by | null;
  penalty_amount?: order_by | null;
  penalty_type?: order_by | null;
  reference_code?: order_by | null;
  templates_aggregate?: template_aggregate_order_by | null;
  user_roles_aggregate?: user_role_aggregate_order_by | null;
  vat_code?: order_by | null;
}

/**
 * input type for updating data in table "client"
 */
export interface client_set_input {
  address?: string | null;
  administration_fee_settings?: any | null;
  automatic_reminder_gen_enabled?: boolean | null;
  casecard_series?: string | null;
  category_id?: number | null;
  commission_amount?: any | null;
  commission_type?: string | null;
  company_code?: string | null;
  date_format?: string | null;
  date_locale?: string | null;
  default_currency?: string | null;
  id?: number | null;
  invoice_series?: string | null;
  is_archived?: boolean | null;
  name?: string | null;
  next_casecard_number?: number | null;
  next_invoice_number?: number | null;
  penalty_amount?: any | null;
  penalty_type?: string | null;
  reference_code?: string | null;
  vat_code?: string | null;
}

/**
 * Boolean expression to filter rows from the table "comment". All fields are combined with a logical 'AND'.
 */
export interface comment_bool_exp {
  _and?: comment_bool_exp[] | null;
  _not?: comment_bool_exp | null;
  _or?: comment_bool_exp[] | null;
  comment?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: String_comparison_exp | null;
  events?: event_bool_exp | null;
  events_aggregate?: event_aggregate_bool_exp | null;
  id?: bigint_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  updated_by?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "comment"
 */
export interface comment_insert_input {
  comment?: string | null;
  created_at?: any | null;
  created_by?: string | null;
  events?: event_arr_rel_insert_input | null;
  id?: any | null;
  updated_at?: any | null;
  updated_by?: string | null;
}

/**
 * input type for inserting object relation for remote table "comment"
 */
export interface comment_obj_rel_insert_input {
  data: comment_insert_input;
  on_conflict?: comment_on_conflict | null;
}

/**
 * on_conflict condition type for table "comment"
 */
export interface comment_on_conflict {
  constraint: comment_constraint;
  update_columns: comment_update_column[];
  where?: comment_bool_exp | null;
}

/**
 * Ordering options when selecting data from "comment".
 */
export interface comment_order_by {
  comment?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  events_aggregate?: event_aggregate_order_by | null;
  id?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "creditor". All fields are combined with a logical 'AND'.
 */
export interface creditor_bool_exp {
  _and?: creditor_bool_exp[] | null;
  _not?: creditor_bool_exp | null;
  _or?: creditor_bool_exp[] | null;
  category_id?: Int_comparison_exp | null;
  client_id?: Int_comparison_exp | null;
  created_at?: time_comparison_exp | null;
  created_by?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  updated_at?: time_comparison_exp | null;
  updated_by?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "creditor"
 */
export interface creditor_insert_input {
  category_id?: number | null;
  client_id?: number | null;
  created_at?: any | null;
  created_by?: string | null;
  id?: number | null;
  name?: string | null;
  updated_at?: any | null;
  updated_by?: string | null;
}

/**
 * input type for inserting object relation for remote table "creditor"
 */
export interface creditor_obj_rel_insert_input {
  data: creditor_insert_input;
  on_conflict?: creditor_on_conflict | null;
}

/**
 * on_conflict condition type for table "creditor"
 */
export interface creditor_on_conflict {
  constraint: creditor_constraint;
  update_columns: creditor_update_column[];
  where?: creditor_bool_exp | null;
}

/**
 * Ordering options when selecting data from "creditor".
 */
export interface creditor_order_by {
  category_id?: order_by | null;
  client_id?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
}

/**
 * input type for updating data in table "creditor"
 */
export interface creditor_set_input {
  category_id?: number | null;
  client_id?: number | null;
  created_at?: any | null;
  created_by?: string | null;
  id?: number | null;
  name?: string | null;
  updated_at?: any | null;
  updated_by?: string | null;
}

/**
 * Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'.
 */
export interface date_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * Boolean expression to filter rows from the table "debt_recovery_template". All fields are combined with a logical 'AND'.
 */
export interface debt_recovery_template_bool_exp {
  _and?: debt_recovery_template_bool_exp[] | null;
  _not?: debt_recovery_template_bool_exp | null;
  _or?: debt_recovery_template_bool_exp[] | null;
  archived?: Boolean_comparison_exp | null;
  attachment_filename?: String_comparison_exp | null;
  client?: client_bool_exp | null;
  client_id?: Int_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: String_comparison_exp | null;
  email_content?: String_comparison_exp | null;
  email_subject?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  language?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  recovery_content?: String_comparison_exp | null;
  sms_content?: String_comparison_exp | null;
  type?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  updated_by?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "debt_recovery_template"
 */
export interface debt_recovery_template_insert_input {
  archived?: boolean | null;
  attachment_filename?: string | null;
  client?: client_obj_rel_insert_input | null;
  client_id?: number | null;
  created_at?: any | null;
  created_by?: string | null;
  email_content?: string | null;
  email_subject?: string | null;
  id?: number | null;
  language?: string | null;
  name?: string | null;
  recovery_content?: string | null;
  sms_content?: string | null;
  type?: string | null;
  updated_at?: any | null;
  updated_by?: string | null;
}

/**
 * Ordering options when selecting data from "debt_recovery_template".
 */
export interface debt_recovery_template_order_by {
  archived?: order_by | null;
  attachment_filename?: order_by | null;
  client?: client_order_by | null;
  client_id?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  email_content?: order_by | null;
  email_subject?: order_by | null;
  id?: order_by | null;
  language?: order_by | null;
  name?: order_by | null;
  recovery_content?: order_by | null;
  sms_content?: order_by | null;
  type?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
}

/**
 * input type for updating data in table "debt_recovery_template"
 */
export interface debt_recovery_template_set_input {
  archived?: boolean | null;
  attachment_filename?: string | null;
  client_id?: number | null;
  created_at?: any | null;
  created_by?: string | null;
  email_content?: string | null;
  email_subject?: string | null;
  id?: number | null;
  language?: string | null;
  name?: string | null;
  recovery_content?: string | null;
  sms_content?: string | null;
  type?: string | null;
  updated_at?: any | null;
  updated_by?: string | null;
}

export interface debtor_aggregate_bool_exp {
  count?: debtor_aggregate_bool_exp_count | null;
}

export interface debtor_aggregate_bool_exp_count {
  arguments?: debtor_select_column[] | null;
  distinct?: boolean | null;
  filter?: debtor_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "debtor"
 */
export interface debtor_aggregate_order_by {
  avg?: debtor_avg_order_by | null;
  count?: order_by | null;
  max?: debtor_max_order_by | null;
  min?: debtor_min_order_by | null;
  stddev?: debtor_stddev_order_by | null;
  stddev_pop?: debtor_stddev_pop_order_by | null;
  stddev_samp?: debtor_stddev_samp_order_by | null;
  sum?: debtor_sum_order_by | null;
  var_pop?: debtor_var_pop_order_by | null;
  var_samp?: debtor_var_samp_order_by | null;
  variance?: debtor_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "debtor"
 */
export interface debtor_arr_rel_insert_input {
  data: debtor_insert_input[];
  on_conflict?: debtor_on_conflict | null;
}

/**
 * order by avg() on columns of table "debtor"
 */
export interface debtor_avg_order_by {
  category_id?: order_by | null;
  client_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  recovery_category_id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "debtor". All fields are combined with a logical 'AND'.
 */
export interface debtor_bool_exp {
  _and?: debtor_bool_exp[] | null;
  _not?: debtor_bool_exp | null;
  _or?: debtor_bool_exp[] | null;
  are_reminders_suspended?: Boolean_comparison_exp | null;
  casecards?: casecard_bool_exp | null;
  casecards_aggregate?: casecard_aggregate_bool_exp | null;
  category?: category_bool_exp | null;
  category_id?: Int_comparison_exp | null;
  client?: client_bool_exp | null;
  client_id?: Int_comparison_exp | null;
  company_code?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: String_comparison_exp | null;
  customer_code_in_client_system?: String_comparison_exp | null;
  debtor_contacts?: debtor_contact_bool_exp | null;
  debtor_contacts_aggregate?: debtor_contact_aggregate_bool_exp | null;
  entity_type?: String_comparison_exp | null;
  extra_1?: String_comparison_exp | null;
  extra_2?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  import_file_id?: Int_comparison_exp | null;
  invoices?: invoice_bool_exp | null;
  invoices_aggregate?: invoice_aggregate_bool_exp | null;
  name?: String_comparison_exp | null;
  recovery_category_id?: Int_comparison_exp | null;
  reference_code?: String_comparison_exp | null;
  reminders?: reminder_bool_exp | null;
  reminders_aggregate?: reminder_aggregate_bool_exp | null;
  suspend_reminders_until?: date_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  updated_by?: String_comparison_exp | null;
  vat_code?: String_comparison_exp | null;
}

export interface debtor_contact_aggregate_bool_exp {
  bool_and?: debtor_contact_aggregate_bool_exp_bool_and | null;
  bool_or?: debtor_contact_aggregate_bool_exp_bool_or | null;
  count?: debtor_contact_aggregate_bool_exp_count | null;
}

export interface debtor_contact_aggregate_bool_exp_bool_and {
  arguments: debtor_contact_select_column_debtor_contact_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: debtor_contact_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface debtor_contact_aggregate_bool_exp_bool_or {
  arguments: debtor_contact_select_column_debtor_contact_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: debtor_contact_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface debtor_contact_aggregate_bool_exp_count {
  arguments?: debtor_contact_select_column[] | null;
  distinct?: boolean | null;
  filter?: debtor_contact_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "debtor_contact"
 */
export interface debtor_contact_aggregate_order_by {
  avg?: debtor_contact_avg_order_by | null;
  count?: order_by | null;
  max?: debtor_contact_max_order_by | null;
  min?: debtor_contact_min_order_by | null;
  stddev?: debtor_contact_stddev_order_by | null;
  stddev_pop?: debtor_contact_stddev_pop_order_by | null;
  stddev_samp?: debtor_contact_stddev_samp_order_by | null;
  sum?: debtor_contact_sum_order_by | null;
  var_pop?: debtor_contact_var_pop_order_by | null;
  var_samp?: debtor_contact_var_samp_order_by | null;
  variance?: debtor_contact_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "debtor_contact"
 */
export interface debtor_contact_arr_rel_insert_input {
  data: debtor_contact_insert_input[];
  on_conflict?: debtor_contact_on_conflict | null;
}

/**
 * order by avg() on columns of table "debtor_contact"
 */
export interface debtor_contact_avg_order_by {
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "debtor_contact". All fields are combined with a logical 'AND'.
 */
export interface debtor_contact_bool_exp {
  _and?: debtor_contact_bool_exp[] | null;
  _not?: debtor_contact_bool_exp | null;
  _or?: debtor_contact_bool_exp[] | null;
  city?: String_comparison_exp | null;
  country?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: String_comparison_exp | null;
  debtor?: debtor_bool_exp | null;
  debtor_id?: Int_comparison_exp | null;
  description?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  import_file_id?: Int_comparison_exp | null;
  is_enabled?: Boolean_comparison_exp | null;
  post_code?: String_comparison_exp | null;
  type?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  updated_by?: String_comparison_exp | null;
  value?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "debtor_contact"
 */
export interface debtor_contact_insert_input {
  city?: string | null;
  country?: string | null;
  created_at?: any | null;
  created_by?: string | null;
  debtor?: debtor_obj_rel_insert_input | null;
  debtor_id?: number | null;
  description?: string | null;
  id?: number | null;
  import_file_id?: number | null;
  is_enabled?: boolean | null;
  post_code?: string | null;
  type?: string | null;
  updated_at?: any | null;
  updated_by?: string | null;
  value?: string | null;
}

/**
 * order by max() on columns of table "debtor_contact"
 */
export interface debtor_contact_max_order_by {
  city?: order_by | null;
  country?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  debtor_id?: order_by | null;
  description?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  post_code?: order_by | null;
  type?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
  value?: order_by | null;
}

/**
 * order by min() on columns of table "debtor_contact"
 */
export interface debtor_contact_min_order_by {
  city?: order_by | null;
  country?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  debtor_id?: order_by | null;
  description?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  post_code?: order_by | null;
  type?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
  value?: order_by | null;
}

/**
 * on_conflict condition type for table "debtor_contact"
 */
export interface debtor_contact_on_conflict {
  constraint: debtor_contact_constraint;
  update_columns: debtor_contact_update_column[];
  where?: debtor_contact_bool_exp | null;
}

/**
 * order by stddev() on columns of table "debtor_contact"
 */
export interface debtor_contact_stddev_order_by {
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "debtor_contact"
 */
export interface debtor_contact_stddev_pop_order_by {
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "debtor_contact"
 */
export interface debtor_contact_stddev_samp_order_by {
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
}

/**
 * order by sum() on columns of table "debtor_contact"
 */
export interface debtor_contact_sum_order_by {
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "debtor_contact"
 */
export interface debtor_contact_var_pop_order_by {
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "debtor_contact"
 */
export interface debtor_contact_var_samp_order_by {
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
}

/**
 * order by variance() on columns of table "debtor_contact"
 */
export interface debtor_contact_variance_order_by {
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
}

/**
 * input type for inserting data into table "debtor"
 */
export interface debtor_insert_input {
  casecards?: casecard_arr_rel_insert_input | null;
  category?: category_obj_rel_insert_input | null;
  category_id?: number | null;
  client?: client_obj_rel_insert_input | null;
  client_id?: number | null;
  company_code?: string | null;
  created_at?: any | null;
  created_by?: string | null;
  customer_code_in_client_system?: string | null;
  debtor_contacts?: debtor_contact_arr_rel_insert_input | null;
  entity_type?: string | null;
  extra_1?: string | null;
  extra_2?: string | null;
  id?: number | null;
  import_file_id?: number | null;
  invoices?: invoice_arr_rel_insert_input | null;
  name?: string | null;
  recovery_category_id?: number | null;
  reference_code?: string | null;
  reminders?: reminder_arr_rel_insert_input | null;
  suspend_reminders_until?: any | null;
  updated_at?: any | null;
  updated_by?: string | null;
  vat_code?: string | null;
}

/**
 * order by max() on columns of table "debtor"
 */
export interface debtor_max_order_by {
  category_id?: order_by | null;
  client_id?: order_by | null;
  company_code?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  customer_code_in_client_system?: order_by | null;
  entity_type?: order_by | null;
  extra_1?: order_by | null;
  extra_2?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  name?: order_by | null;
  recovery_category_id?: order_by | null;
  reference_code?: order_by | null;
  suspend_reminders_until?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
  vat_code?: order_by | null;
}

/**
 * order by min() on columns of table "debtor"
 */
export interface debtor_min_order_by {
  category_id?: order_by | null;
  client_id?: order_by | null;
  company_code?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  customer_code_in_client_system?: order_by | null;
  entity_type?: order_by | null;
  extra_1?: order_by | null;
  extra_2?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  name?: order_by | null;
  recovery_category_id?: order_by | null;
  reference_code?: order_by | null;
  suspend_reminders_until?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
  vat_code?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "debtor"
 */
export interface debtor_obj_rel_insert_input {
  data: debtor_insert_input;
  on_conflict?: debtor_on_conflict | null;
}

/**
 * on_conflict condition type for table "debtor"
 */
export interface debtor_on_conflict {
  constraint: debtor_constraint;
  update_columns: debtor_update_column[];
  where?: debtor_bool_exp | null;
}

/**
 * Ordering options when selecting data from "debtor".
 */
export interface debtor_order_by {
  are_reminders_suspended?: order_by | null;
  casecards_aggregate?: casecard_aggregate_order_by | null;
  category?: category_order_by | null;
  category_id?: order_by | null;
  client?: client_order_by | null;
  client_id?: order_by | null;
  company_code?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  customer_code_in_client_system?: order_by | null;
  debtor_contacts_aggregate?: debtor_contact_aggregate_order_by | null;
  entity_type?: order_by | null;
  extra_1?: order_by | null;
  extra_2?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  invoices_aggregate?: invoice_aggregate_order_by | null;
  name?: order_by | null;
  recovery_category_id?: order_by | null;
  reference_code?: order_by | null;
  reminders_aggregate?: reminder_aggregate_order_by | null;
  suspend_reminders_until?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
  vat_code?: order_by | null;
}

/**
 * input type for updating data in table "debtor"
 */
export interface debtor_set_input {
  category_id?: number | null;
  client_id?: number | null;
  company_code?: string | null;
  created_at?: any | null;
  created_by?: string | null;
  customer_code_in_client_system?: string | null;
  entity_type?: string | null;
  extra_1?: string | null;
  extra_2?: string | null;
  id?: number | null;
  import_file_id?: number | null;
  name?: string | null;
  recovery_category_id?: number | null;
  reference_code?: string | null;
  suspend_reminders_until?: any | null;
  updated_at?: any | null;
  updated_by?: string | null;
  vat_code?: string | null;
}

/**
 * order by stddev() on columns of table "debtor"
 */
export interface debtor_stddev_order_by {
  category_id?: order_by | null;
  client_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  recovery_category_id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "debtor"
 */
export interface debtor_stddev_pop_order_by {
  category_id?: order_by | null;
  client_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  recovery_category_id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "debtor"
 */
export interface debtor_stddev_samp_order_by {
  category_id?: order_by | null;
  client_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  recovery_category_id?: order_by | null;
}

/**
 * order by sum() on columns of table "debtor"
 */
export interface debtor_sum_order_by {
  category_id?: order_by | null;
  client_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  recovery_category_id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "debtor"
 */
export interface debtor_var_pop_order_by {
  category_id?: order_by | null;
  client_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  recovery_category_id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "debtor"
 */
export interface debtor_var_samp_order_by {
  category_id?: order_by | null;
  client_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  recovery_category_id?: order_by | null;
}

/**
 * order by variance() on columns of table "debtor"
 */
export interface debtor_variance_order_by {
  category_id?: order_by | null;
  client_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  recovery_category_id?: order_by | null;
}

export interface event_aggregate_bool_exp {
  bool_and?: event_aggregate_bool_exp_bool_and | null;
  bool_or?: event_aggregate_bool_exp_bool_or | null;
  count?: event_aggregate_bool_exp_count | null;
}

export interface event_aggregate_bool_exp_bool_and {
  arguments: event_select_column_event_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: event_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface event_aggregate_bool_exp_bool_or {
  arguments: event_select_column_event_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: event_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface event_aggregate_bool_exp_count {
  arguments?: event_select_column[] | null;
  distinct?: boolean | null;
  filter?: event_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "event"
 */
export interface event_aggregate_order_by {
  avg?: event_avg_order_by | null;
  count?: order_by | null;
  max?: event_max_order_by | null;
  min?: event_min_order_by | null;
  stddev?: event_stddev_order_by | null;
  stddev_pop?: event_stddev_pop_order_by | null;
  stddev_samp?: event_stddev_samp_order_by | null;
  sum?: event_sum_order_by | null;
  var_pop?: event_var_pop_order_by | null;
  var_samp?: event_var_samp_order_by | null;
  variance?: event_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "event"
 */
export interface event_arr_rel_insert_input {
  data: event_insert_input[];
  on_conflict?: event_on_conflict | null;
}

/**
 * order by avg() on columns of table "event"
 */
export interface event_avg_order_by {
  client_id?: order_by | null;
  comment_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "event". All fields are combined with a logical 'AND'.
 */
export interface event_bool_exp {
  _and?: event_bool_exp[] | null;
  _not?: event_bool_exp | null;
  _or?: event_bool_exp[] | null;
  casecards?: casecard_events_bool_exp | null;
  casecards_aggregate?: casecard_events_aggregate_bool_exp | null;
  client?: client_bool_exp | null;
  client_id?: Int_comparison_exp | null;
  comment?: comment_bool_exp | null;
  comment_id?: bigint_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: uuid_comparison_exp | null;
  creator?: user_bool_exp | null;
  debtor?: debtor_bool_exp | null;
  debtor_id?: bigint_comparison_exp | null;
  event_type?: String_comparison_exp | null;
  extra_info?: jsonb_comparison_exp | null;
  id?: bigint_comparison_exp | null;
  invoices?: invoice_events_bool_exp | null;
  invoices_aggregate?: invoice_events_aggregate_bool_exp | null;
  is_deleted?: Boolean_comparison_exp | null;
  reminders?: reminder_events_bool_exp | null;
  reminders_aggregate?: reminder_events_aggregate_bool_exp | null;
  stage?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "event"
 */
export interface event_insert_input {
  casecards?: casecard_events_arr_rel_insert_input | null;
  client?: client_obj_rel_insert_input | null;
  client_id?: number | null;
  comment?: comment_obj_rel_insert_input | null;
  comment_id?: any | null;
  created_at?: any | null;
  created_by?: any | null;
  creator?: user_obj_rel_insert_input | null;
  debtor?: debtor_obj_rel_insert_input | null;
  debtor_id?: any | null;
  event_type?: string | null;
  extra_info?: any | null;
  id?: any | null;
  invoices?: invoice_events_arr_rel_insert_input | null;
  is_deleted?: boolean | null;
  reminders?: reminder_events_arr_rel_insert_input | null;
  stage?: string | null;
}

/**
 * order by max() on columns of table "event"
 */
export interface event_max_order_by {
  client_id?: order_by | null;
  comment_id?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  debtor_id?: order_by | null;
  event_type?: order_by | null;
  id?: order_by | null;
  stage?: order_by | null;
}

/**
 * order by min() on columns of table "event"
 */
export interface event_min_order_by {
  client_id?: order_by | null;
  comment_id?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  debtor_id?: order_by | null;
  event_type?: order_by | null;
  id?: order_by | null;
  stage?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "event"
 */
export interface event_obj_rel_insert_input {
  data: event_insert_input;
  on_conflict?: event_on_conflict | null;
}

/**
 * on_conflict condition type for table "event"
 */
export interface event_on_conflict {
  constraint: event_constraint;
  update_columns: event_update_column[];
  where?: event_bool_exp | null;
}

/**
 * Ordering options when selecting data from "event".
 */
export interface event_order_by {
  casecards_aggregate?: casecard_events_aggregate_order_by | null;
  client?: client_order_by | null;
  client_id?: order_by | null;
  comment?: comment_order_by | null;
  comment_id?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  creator?: user_order_by | null;
  debtor?: debtor_order_by | null;
  debtor_id?: order_by | null;
  event_type?: order_by | null;
  extra_info?: order_by | null;
  id?: order_by | null;
  invoices_aggregate?: invoice_events_aggregate_order_by | null;
  is_deleted?: order_by | null;
  reminders_aggregate?: reminder_events_aggregate_order_by | null;
  stage?: order_by | null;
}

/**
 * order by stddev() on columns of table "event"
 */
export interface event_stddev_order_by {
  client_id?: order_by | null;
  comment_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "event"
 */
export interface event_stddev_pop_order_by {
  client_id?: order_by | null;
  comment_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "event"
 */
export interface event_stddev_samp_order_by {
  client_id?: order_by | null;
  comment_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "event"
 */
export interface event_sum_order_by {
  client_id?: order_by | null;
  comment_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "event"
 */
export interface event_var_pop_order_by {
  client_id?: order_by | null;
  comment_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "event"
 */
export interface event_var_samp_order_by {
  client_id?: order_by | null;
  comment_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "event"
 */
export interface event_variance_order_by {
  client_id?: order_by | null;
  comment_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "import_file". All fields are combined with a logical 'AND'.
 */
export interface import_file_bool_exp {
  _and?: import_file_bool_exp[] | null;
  _not?: import_file_bool_exp | null;
  _or?: import_file_bool_exp[] | null;
  bucket_filename?: String_comparison_exp | null;
  client?: client_bool_exp | null;
  client_id?: Int_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: uuid_comparison_exp | null;
  creator?: user_bool_exp | null;
  id?: Int_comparison_exp | null;
  import_options?: jsonb_comparison_exp | null;
  import_type?: String_comparison_exp | null;
  orig_filename?: String_comparison_exp | null;
  raw_data?: jsonb_comparison_exp | null;
}

/**
 * input type for inserting data into table "import_file"
 */
export interface import_file_insert_input {
  bucket_filename?: string | null;
  client?: client_obj_rel_insert_input | null;
  client_id?: number | null;
  created_at?: any | null;
  created_by?: any | null;
  creator?: user_obj_rel_insert_input | null;
  id?: number | null;
  import_options?: any | null;
  import_type?: string | null;
  orig_filename?: string | null;
  raw_data?: any | null;
}

export interface import_file_invoice_aggregate_bool_exp {
  count?: import_file_invoice_aggregate_bool_exp_count | null;
}

export interface import_file_invoice_aggregate_bool_exp_count {
  arguments?: import_file_invoice_select_column[] | null;
  distinct?: boolean | null;
  filter?: import_file_invoice_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "import_file_invoice"
 */
export interface import_file_invoice_aggregate_order_by {
  avg?: import_file_invoice_avg_order_by | null;
  count?: order_by | null;
  max?: import_file_invoice_max_order_by | null;
  min?: import_file_invoice_min_order_by | null;
  stddev?: import_file_invoice_stddev_order_by | null;
  stddev_pop?: import_file_invoice_stddev_pop_order_by | null;
  stddev_samp?: import_file_invoice_stddev_samp_order_by | null;
  sum?: import_file_invoice_sum_order_by | null;
  var_pop?: import_file_invoice_var_pop_order_by | null;
  var_samp?: import_file_invoice_var_samp_order_by | null;
  variance?: import_file_invoice_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "import_file_invoice"
 */
export interface import_file_invoice_arr_rel_insert_input {
  data: import_file_invoice_insert_input[];
  on_conflict?: import_file_invoice_on_conflict | null;
}

/**
 * order by avg() on columns of table "import_file_invoice"
 */
export interface import_file_invoice_avg_order_by {
  id?: order_by | null;
  import_file_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "import_file_invoice". All fields are combined with a logical 'AND'.
 */
export interface import_file_invoice_bool_exp {
  _and?: import_file_invoice_bool_exp[] | null;
  _not?: import_file_invoice_bool_exp | null;
  _or?: import_file_invoice_bool_exp[] | null;
  id?: Int_comparison_exp | null;
  import_file?: import_file_bool_exp | null;
  import_file_id?: Int_comparison_exp | null;
  invoice?: invoice_bool_exp | null;
  invoice_id?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "import_file_invoice"
 */
export interface import_file_invoice_insert_input {
  id?: number | null;
  import_file?: import_file_obj_rel_insert_input | null;
  import_file_id?: number | null;
  invoice?: invoice_obj_rel_insert_input | null;
  invoice_id?: number | null;
}

/**
 * order by max() on columns of table "import_file_invoice"
 */
export interface import_file_invoice_max_order_by {
  id?: order_by | null;
  import_file_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by min() on columns of table "import_file_invoice"
 */
export interface import_file_invoice_min_order_by {
  id?: order_by | null;
  import_file_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * on_conflict condition type for table "import_file_invoice"
 */
export interface import_file_invoice_on_conflict {
  constraint: import_file_invoice_constraint;
  update_columns: import_file_invoice_update_column[];
  where?: import_file_invoice_bool_exp | null;
}

/**
 * order by stddev() on columns of table "import_file_invoice"
 */
export interface import_file_invoice_stddev_order_by {
  id?: order_by | null;
  import_file_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "import_file_invoice"
 */
export interface import_file_invoice_stddev_pop_order_by {
  id?: order_by | null;
  import_file_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "import_file_invoice"
 */
export interface import_file_invoice_stddev_samp_order_by {
  id?: order_by | null;
  import_file_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by sum() on columns of table "import_file_invoice"
 */
export interface import_file_invoice_sum_order_by {
  id?: order_by | null;
  import_file_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "import_file_invoice"
 */
export interface import_file_invoice_var_pop_order_by {
  id?: order_by | null;
  import_file_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "import_file_invoice"
 */
export interface import_file_invoice_var_samp_order_by {
  id?: order_by | null;
  import_file_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by variance() on columns of table "import_file_invoice"
 */
export interface import_file_invoice_variance_order_by {
  id?: order_by | null;
  import_file_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "import_file"
 */
export interface import_file_obj_rel_insert_input {
  data: import_file_insert_input;
  on_conflict?: import_file_on_conflict | null;
}

/**
 * on_conflict condition type for table "import_file"
 */
export interface import_file_on_conflict {
  constraint: import_file_constraint;
  update_columns: import_file_update_column[];
  where?: import_file_bool_exp | null;
}

/**
 * Ordering options when selecting data from "import_file".
 */
export interface import_file_order_by {
  bucket_filename?: order_by | null;
  client?: client_order_by | null;
  client_id?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  creator?: user_order_by | null;
  id?: order_by | null;
  import_options?: order_by | null;
  import_type?: order_by | null;
  orig_filename?: order_by | null;
  raw_data?: order_by | null;
}

export interface invoice_aggregate_bool_exp {
  bool_and?: invoice_aggregate_bool_exp_bool_and | null;
  bool_or?: invoice_aggregate_bool_exp_bool_or | null;
  count?: invoice_aggregate_bool_exp_count | null;
}

export interface invoice_aggregate_bool_exp_bool_and {
  arguments: invoice_select_column_invoice_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: invoice_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface invoice_aggregate_bool_exp_bool_or {
  arguments: invoice_select_column_invoice_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: invoice_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface invoice_aggregate_bool_exp_count {
  arguments?: invoice_select_column[] | null;
  distinct?: boolean | null;
  filter?: invoice_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "invoice"
 */
export interface invoice_aggregate_order_by {
  avg?: invoice_avg_order_by | null;
  count?: order_by | null;
  max?: invoice_max_order_by | null;
  min?: invoice_min_order_by | null;
  stddev?: invoice_stddev_order_by | null;
  stddev_pop?: invoice_stddev_pop_order_by | null;
  stddev_samp?: invoice_stddev_samp_order_by | null;
  sum?: invoice_sum_order_by | null;
  var_pop?: invoice_var_pop_order_by | null;
  var_samp?: invoice_var_samp_order_by | null;
  variance?: invoice_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "invoice"
 */
export interface invoice_arr_rel_insert_input {
  data: invoice_insert_input[];
  on_conflict?: invoice_on_conflict | null;
}

export interface invoice_attachments_aggregate_bool_exp {
  count?: invoice_attachments_aggregate_bool_exp_count | null;
}

export interface invoice_attachments_aggregate_bool_exp_count {
  arguments?: invoice_attachments_select_column[] | null;
  distinct?: boolean | null;
  filter?: invoice_attachments_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "invoice_attachments"
 */
export interface invoice_attachments_aggregate_order_by {
  avg?: invoice_attachments_avg_order_by | null;
  count?: order_by | null;
  max?: invoice_attachments_max_order_by | null;
  min?: invoice_attachments_min_order_by | null;
  stddev?: invoice_attachments_stddev_order_by | null;
  stddev_pop?: invoice_attachments_stddev_pop_order_by | null;
  stddev_samp?: invoice_attachments_stddev_samp_order_by | null;
  sum?: invoice_attachments_sum_order_by | null;
  var_pop?: invoice_attachments_var_pop_order_by | null;
  var_samp?: invoice_attachments_var_samp_order_by | null;
  variance?: invoice_attachments_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "invoice_attachments"
 */
export interface invoice_attachments_arr_rel_insert_input {
  data: invoice_attachments_insert_input[];
  on_conflict?: invoice_attachments_on_conflict | null;
}

/**
 * order by avg() on columns of table "invoice_attachments"
 */
export interface invoice_attachments_avg_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "invoice_attachments". All fields are combined with a logical 'AND'.
 */
export interface invoice_attachments_bool_exp {
  _and?: invoice_attachments_bool_exp[] | null;
  _not?: invoice_attachments_bool_exp | null;
  _or?: invoice_attachments_bool_exp[] | null;
  client_id?: Int_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  file_location?: String_comparison_exp | null;
  filename?: String_comparison_exp | null;
  folder?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  invoice?: invoice_bool_exp | null;
  invoice_id?: Int_comparison_exp | null;
  orig_filename?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "invoice_attachments"
 */
export interface invoice_attachments_insert_input {
  client_id?: number | null;
  created_at?: any | null;
  file_location?: string | null;
  filename?: string | null;
  folder?: string | null;
  id?: number | null;
  invoice?: invoice_obj_rel_insert_input | null;
  invoice_id?: number | null;
  orig_filename?: string | null;
}

/**
 * order by max() on columns of table "invoice_attachments"
 */
export interface invoice_attachments_max_order_by {
  client_id?: order_by | null;
  created_at?: order_by | null;
  file_location?: order_by | null;
  filename?: order_by | null;
  folder?: order_by | null;
  id?: order_by | null;
  invoice_id?: order_by | null;
  orig_filename?: order_by | null;
}

/**
 * order by min() on columns of table "invoice_attachments"
 */
export interface invoice_attachments_min_order_by {
  client_id?: order_by | null;
  created_at?: order_by | null;
  file_location?: order_by | null;
  filename?: order_by | null;
  folder?: order_by | null;
  id?: order_by | null;
  invoice_id?: order_by | null;
  orig_filename?: order_by | null;
}

/**
 * on_conflict condition type for table "invoice_attachments"
 */
export interface invoice_attachments_on_conflict {
  constraint: invoice_attachments_constraint;
  update_columns: invoice_attachments_update_column[];
  where?: invoice_attachments_bool_exp | null;
}

/**
 * order by stddev() on columns of table "invoice_attachments"
 */
export interface invoice_attachments_stddev_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "invoice_attachments"
 */
export interface invoice_attachments_stddev_pop_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "invoice_attachments"
 */
export interface invoice_attachments_stddev_samp_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by sum() on columns of table "invoice_attachments"
 */
export interface invoice_attachments_sum_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "invoice_attachments"
 */
export interface invoice_attachments_var_pop_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "invoice_attachments"
 */
export interface invoice_attachments_var_samp_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by variance() on columns of table "invoice_attachments"
 */
export interface invoice_attachments_variance_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by avg() on columns of table "invoice"
 */
export interface invoice_avg_order_by {
  amount_outstanding?: order_by | null;
  amount_paid?: order_by | null;
  amount_with_vat?: order_by | null;
  amount_wo_vat?: order_by | null;
  debtor_id?: order_by | null;
  full_amount?: order_by | null;
  id?: order_by | null;
  vat?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "invoice". All fields are combined with a logical 'AND'.
 */
export interface invoice_bool_exp {
  _and?: invoice_bool_exp[] | null;
  _not?: invoice_bool_exp | null;
  _or?: invoice_bool_exp[] | null;
  amount_outstanding?: numeric_comparison_exp | null;
  amount_paid?: numeric_comparison_exp | null;
  amount_with_vat?: numeric_comparison_exp | null;
  amount_wo_vat?: numeric_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: String_comparison_exp | null;
  currency?: String_comparison_exp | null;
  date_1?: date_comparison_exp | null;
  date_2?: date_comparison_exp | null;
  debtor?: debtor_bool_exp | null;
  debtor_id?: Int_comparison_exp | null;
  document_date?: date_comparison_exp | null;
  document_number?: String_comparison_exp | null;
  due_date?: date_comparison_exp | null;
  events?: invoice_events_bool_exp | null;
  events_aggregate?: invoice_events_aggregate_bool_exp | null;
  extra_1?: String_comparison_exp | null;
  extra_2?: String_comparison_exp | null;
  extra_3?: String_comparison_exp | null;
  extra_4?: String_comparison_exp | null;
  extra_5?: String_comparison_exp | null;
  extra_6?: String_comparison_exp | null;
  extra_document_number_1?: String_comparison_exp | null;
  full_amount?: numeric_comparison_exp | null;
  id?: Int_comparison_exp | null;
  import_file_invoices?: import_file_invoice_bool_exp | null;
  import_file_invoices_aggregate?: import_file_invoice_aggregate_bool_exp | null;
  invoice_attachments?: invoice_attachments_bool_exp | null;
  invoice_attachments_aggregate?: invoice_attachments_aggregate_bool_exp | null;
  invoice_line_items?: invoice_line_item_bool_exp | null;
  invoice_line_items_aggregate?: invoice_line_item_aggregate_bool_exp | null;
  invoice_payments?: invoice_payment_bool_exp | null;
  invoice_payments_aggregate?: invoice_payment_aggregate_bool_exp | null;
  invoice_type?: String_comparison_exp | null;
  is_late?: Boolean_comparison_exp | null;
  is_paid?: Boolean_comparison_exp | null;
  is_sending_suspended?: Boolean_comparison_exp | null;
  stage?: String_comparison_exp | null;
  suspend_reminders_until?: date_comparison_exp | null;
  time_1?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  updated_by?: String_comparison_exp | null;
  vat?: numeric_comparison_exp | null;
}

export interface invoice_events_aggregate_bool_exp {
  count?: invoice_events_aggregate_bool_exp_count | null;
}

export interface invoice_events_aggregate_bool_exp_count {
  arguments?: invoice_events_select_column[] | null;
  distinct?: boolean | null;
  filter?: invoice_events_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "invoice_events"
 */
export interface invoice_events_aggregate_order_by {
  avg?: invoice_events_avg_order_by | null;
  count?: order_by | null;
  max?: invoice_events_max_order_by | null;
  min?: invoice_events_min_order_by | null;
  stddev?: invoice_events_stddev_order_by | null;
  stddev_pop?: invoice_events_stddev_pop_order_by | null;
  stddev_samp?: invoice_events_stddev_samp_order_by | null;
  sum?: invoice_events_sum_order_by | null;
  var_pop?: invoice_events_var_pop_order_by | null;
  var_samp?: invoice_events_var_samp_order_by | null;
  variance?: invoice_events_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "invoice_events"
 */
export interface invoice_events_arr_rel_insert_input {
  data: invoice_events_insert_input[];
  on_conflict?: invoice_events_on_conflict | null;
}

/**
 * order by avg() on columns of table "invoice_events"
 */
export interface invoice_events_avg_order_by {
  event_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "invoice_events". All fields are combined with a logical 'AND'.
 */
export interface invoice_events_bool_exp {
  _and?: invoice_events_bool_exp[] | null;
  _not?: invoice_events_bool_exp | null;
  _or?: invoice_events_bool_exp[] | null;
  event?: event_bool_exp | null;
  event_id?: bigint_comparison_exp | null;
  invoice?: invoice_bool_exp | null;
  invoice_id?: bigint_comparison_exp | null;
}

/**
 * input type for inserting data into table "invoice_events"
 */
export interface invoice_events_insert_input {
  event?: event_obj_rel_insert_input | null;
  event_id?: any | null;
  invoice?: invoice_obj_rel_insert_input | null;
  invoice_id?: any | null;
}

/**
 * order by max() on columns of table "invoice_events"
 */
export interface invoice_events_max_order_by {
  event_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by min() on columns of table "invoice_events"
 */
export interface invoice_events_min_order_by {
  event_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * on_conflict condition type for table "invoice_events"
 */
export interface invoice_events_on_conflict {
  constraint: invoice_events_constraint;
  update_columns: invoice_events_update_column[];
  where?: invoice_events_bool_exp | null;
}

/**
 * order by stddev() on columns of table "invoice_events"
 */
export interface invoice_events_stddev_order_by {
  event_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "invoice_events"
 */
export interface invoice_events_stddev_pop_order_by {
  event_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "invoice_events"
 */
export interface invoice_events_stddev_samp_order_by {
  event_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by sum() on columns of table "invoice_events"
 */
export interface invoice_events_sum_order_by {
  event_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "invoice_events"
 */
export interface invoice_events_var_pop_order_by {
  event_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "invoice_events"
 */
export interface invoice_events_var_samp_order_by {
  event_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * order by variance() on columns of table "invoice_events"
 */
export interface invoice_events_variance_order_by {
  event_id?: order_by | null;
  invoice_id?: order_by | null;
}

/**
 * input type for inserting data into table "invoice"
 */
export interface invoice_insert_input {
  amount_outstanding?: any | null;
  amount_paid?: any | null;
  amount_with_vat?: any | null;
  amount_wo_vat?: any | null;
  created_at?: any | null;
  created_by?: string | null;
  currency?: string | null;
  date_1?: any | null;
  date_2?: any | null;
  debtor?: debtor_obj_rel_insert_input | null;
  debtor_id?: number | null;
  document_date?: any | null;
  document_number?: string | null;
  due_date?: any | null;
  events?: invoice_events_arr_rel_insert_input | null;
  extra_1?: string | null;
  extra_2?: string | null;
  extra_3?: string | null;
  extra_4?: string | null;
  extra_5?: string | null;
  extra_6?: string | null;
  extra_document_number_1?: string | null;
  full_amount?: any | null;
  id?: number | null;
  import_file_invoices?: import_file_invoice_arr_rel_insert_input | null;
  invoice_attachments?: invoice_attachments_arr_rel_insert_input | null;
  invoice_line_items?: invoice_line_item_arr_rel_insert_input | null;
  invoice_payments?: invoice_payment_arr_rel_insert_input | null;
  invoice_type?: string | null;
  is_paid?: boolean | null;
  stage?: string | null;
  suspend_reminders_until?: any | null;
  time_1?: string | null;
  updated_at?: any | null;
  updated_by?: string | null;
  vat?: any | null;
}

export interface invoice_line_item_aggregate_bool_exp {
  count?: invoice_line_item_aggregate_bool_exp_count | null;
}

export interface invoice_line_item_aggregate_bool_exp_count {
  arguments?: invoice_line_item_select_column[] | null;
  distinct?: boolean | null;
  filter?: invoice_line_item_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "invoice_line_item"
 */
export interface invoice_line_item_aggregate_order_by {
  avg?: invoice_line_item_avg_order_by | null;
  count?: order_by | null;
  max?: invoice_line_item_max_order_by | null;
  min?: invoice_line_item_min_order_by | null;
  stddev?: invoice_line_item_stddev_order_by | null;
  stddev_pop?: invoice_line_item_stddev_pop_order_by | null;
  stddev_samp?: invoice_line_item_stddev_samp_order_by | null;
  sum?: invoice_line_item_sum_order_by | null;
  var_pop?: invoice_line_item_var_pop_order_by | null;
  var_samp?: invoice_line_item_var_samp_order_by | null;
  variance?: invoice_line_item_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "invoice_line_item"
 */
export interface invoice_line_item_arr_rel_insert_input {
  data: invoice_line_item_insert_input[];
  on_conflict?: invoice_line_item_on_conflict | null;
}

/**
 * order by avg() on columns of table "invoice_line_item"
 */
export interface invoice_line_item_avg_order_by {
  id?: order_by | null;
  invoice_id?: order_by | null;
  price?: order_by | null;
  quantity?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "invoice_line_item". All fields are combined with a logical 'AND'.
 */
export interface invoice_line_item_bool_exp {
  _and?: invoice_line_item_bool_exp[] | null;
  _not?: invoice_line_item_bool_exp | null;
  _or?: invoice_line_item_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: uuid_comparison_exp | null;
  id?: bigint_comparison_exp | null;
  invoice?: invoice_bool_exp | null;
  invoice_id?: bigint_comparison_exp | null;
  name?: String_comparison_exp | null;
  price?: numeric_comparison_exp | null;
  product_id?: String_comparison_exp | null;
  quantity?: numeric_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  updated_by?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "invoice_line_item"
 */
export interface invoice_line_item_insert_input {
  created_at?: any | null;
  created_by?: any | null;
  id?: any | null;
  invoice?: invoice_obj_rel_insert_input | null;
  invoice_id?: any | null;
  name?: string | null;
  price?: any | null;
  product_id?: string | null;
  quantity?: any | null;
  updated_at?: any | null;
  updated_by?: any | null;
}

/**
 * order by max() on columns of table "invoice_line_item"
 */
export interface invoice_line_item_max_order_by {
  created_at?: order_by | null;
  created_by?: order_by | null;
  id?: order_by | null;
  invoice_id?: order_by | null;
  name?: order_by | null;
  price?: order_by | null;
  product_id?: order_by | null;
  quantity?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
}

/**
 * order by min() on columns of table "invoice_line_item"
 */
export interface invoice_line_item_min_order_by {
  created_at?: order_by | null;
  created_by?: order_by | null;
  id?: order_by | null;
  invoice_id?: order_by | null;
  name?: order_by | null;
  price?: order_by | null;
  product_id?: order_by | null;
  quantity?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
}

/**
 * on_conflict condition type for table "invoice_line_item"
 */
export interface invoice_line_item_on_conflict {
  constraint: invoice_line_item_constraint;
  update_columns: invoice_line_item_update_column[];
  where?: invoice_line_item_bool_exp | null;
}

/**
 * order by stddev() on columns of table "invoice_line_item"
 */
export interface invoice_line_item_stddev_order_by {
  id?: order_by | null;
  invoice_id?: order_by | null;
  price?: order_by | null;
  quantity?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "invoice_line_item"
 */
export interface invoice_line_item_stddev_pop_order_by {
  id?: order_by | null;
  invoice_id?: order_by | null;
  price?: order_by | null;
  quantity?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "invoice_line_item"
 */
export interface invoice_line_item_stddev_samp_order_by {
  id?: order_by | null;
  invoice_id?: order_by | null;
  price?: order_by | null;
  quantity?: order_by | null;
}

/**
 * order by sum() on columns of table "invoice_line_item"
 */
export interface invoice_line_item_sum_order_by {
  id?: order_by | null;
  invoice_id?: order_by | null;
  price?: order_by | null;
  quantity?: order_by | null;
}

/**
 * order by var_pop() on columns of table "invoice_line_item"
 */
export interface invoice_line_item_var_pop_order_by {
  id?: order_by | null;
  invoice_id?: order_by | null;
  price?: order_by | null;
  quantity?: order_by | null;
}

/**
 * order by var_samp() on columns of table "invoice_line_item"
 */
export interface invoice_line_item_var_samp_order_by {
  id?: order_by | null;
  invoice_id?: order_by | null;
  price?: order_by | null;
  quantity?: order_by | null;
}

/**
 * order by variance() on columns of table "invoice_line_item"
 */
export interface invoice_line_item_variance_order_by {
  id?: order_by | null;
  invoice_id?: order_by | null;
  price?: order_by | null;
  quantity?: order_by | null;
}

/**
 * order by max() on columns of table "invoice"
 */
export interface invoice_max_order_by {
  amount_outstanding?: order_by | null;
  amount_paid?: order_by | null;
  amount_with_vat?: order_by | null;
  amount_wo_vat?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  currency?: order_by | null;
  date_1?: order_by | null;
  date_2?: order_by | null;
  debtor_id?: order_by | null;
  document_date?: order_by | null;
  document_number?: order_by | null;
  due_date?: order_by | null;
  extra_1?: order_by | null;
  extra_2?: order_by | null;
  extra_3?: order_by | null;
  extra_4?: order_by | null;
  extra_5?: order_by | null;
  extra_6?: order_by | null;
  extra_document_number_1?: order_by | null;
  full_amount?: order_by | null;
  id?: order_by | null;
  invoice_type?: order_by | null;
  stage?: order_by | null;
  suspend_reminders_until?: order_by | null;
  time_1?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
  vat?: order_by | null;
}

/**
 * order by min() on columns of table "invoice"
 */
export interface invoice_min_order_by {
  amount_outstanding?: order_by | null;
  amount_paid?: order_by | null;
  amount_with_vat?: order_by | null;
  amount_wo_vat?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  currency?: order_by | null;
  date_1?: order_by | null;
  date_2?: order_by | null;
  debtor_id?: order_by | null;
  document_date?: order_by | null;
  document_number?: order_by | null;
  due_date?: order_by | null;
  extra_1?: order_by | null;
  extra_2?: order_by | null;
  extra_3?: order_by | null;
  extra_4?: order_by | null;
  extra_5?: order_by | null;
  extra_6?: order_by | null;
  extra_document_number_1?: order_by | null;
  full_amount?: order_by | null;
  id?: order_by | null;
  invoice_type?: order_by | null;
  stage?: order_by | null;
  suspend_reminders_until?: order_by | null;
  time_1?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
  vat?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "invoice"
 */
export interface invoice_obj_rel_insert_input {
  data: invoice_insert_input;
  on_conflict?: invoice_on_conflict | null;
}

/**
 * on_conflict condition type for table "invoice"
 */
export interface invoice_on_conflict {
  constraint: invoice_constraint;
  update_columns: invoice_update_column[];
  where?: invoice_bool_exp | null;
}

/**
 * Ordering options when selecting data from "invoice".
 */
export interface invoice_order_by {
  amount_outstanding?: order_by | null;
  amount_paid?: order_by | null;
  amount_with_vat?: order_by | null;
  amount_wo_vat?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  currency?: order_by | null;
  date_1?: order_by | null;
  date_2?: order_by | null;
  debtor?: debtor_order_by | null;
  debtor_id?: order_by | null;
  document_date?: order_by | null;
  document_number?: order_by | null;
  due_date?: order_by | null;
  events_aggregate?: invoice_events_aggregate_order_by | null;
  extra_1?: order_by | null;
  extra_2?: order_by | null;
  extra_3?: order_by | null;
  extra_4?: order_by | null;
  extra_5?: order_by | null;
  extra_6?: order_by | null;
  extra_document_number_1?: order_by | null;
  full_amount?: order_by | null;
  id?: order_by | null;
  import_file_invoices_aggregate?: import_file_invoice_aggregate_order_by | null;
  invoice_attachments_aggregate?: invoice_attachments_aggregate_order_by | null;
  invoice_line_items_aggregate?: invoice_line_item_aggregate_order_by | null;
  invoice_payments_aggregate?: invoice_payment_aggregate_order_by | null;
  invoice_type?: order_by | null;
  is_late?: order_by | null;
  is_paid?: order_by | null;
  is_sending_suspended?: order_by | null;
  stage?: order_by | null;
  suspend_reminders_until?: order_by | null;
  time_1?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
  vat?: order_by | null;
}

export interface invoice_payment_aggregate_bool_exp {
  count?: invoice_payment_aggregate_bool_exp_count | null;
}

export interface invoice_payment_aggregate_bool_exp_count {
  arguments?: invoice_payment_select_column[] | null;
  distinct?: boolean | null;
  filter?: invoice_payment_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "invoice_payment"
 */
export interface invoice_payment_aggregate_order_by {
  avg?: invoice_payment_avg_order_by | null;
  count?: order_by | null;
  max?: invoice_payment_max_order_by | null;
  min?: invoice_payment_min_order_by | null;
  stddev?: invoice_payment_stddev_order_by | null;
  stddev_pop?: invoice_payment_stddev_pop_order_by | null;
  stddev_samp?: invoice_payment_stddev_samp_order_by | null;
  sum?: invoice_payment_sum_order_by | null;
  var_pop?: invoice_payment_var_pop_order_by | null;
  var_samp?: invoice_payment_var_samp_order_by | null;
  variance?: invoice_payment_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "invoice_payment"
 */
export interface invoice_payment_arr_rel_insert_input {
  data: invoice_payment_insert_input[];
  on_conflict?: invoice_payment_on_conflict | null;
}

/**
 * order by avg() on columns of table "invoice_payment"
 */
export interface invoice_payment_avg_order_by {
  amount?: order_by | null;
  invoice_id?: order_by | null;
  payment_id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "invoice_payment". All fields are combined with a logical 'AND'.
 */
export interface invoice_payment_bool_exp {
  _and?: invoice_payment_bool_exp[] | null;
  _not?: invoice_payment_bool_exp | null;
  _or?: invoice_payment_bool_exp[] | null;
  amount?: numeric_comparison_exp | null;
  invoice?: invoice_bool_exp | null;
  invoice_id?: bigint_comparison_exp | null;
  payment?: payment_bool_exp | null;
  payment_id?: bigint_comparison_exp | null;
}

/**
 * input type for inserting data into table "invoice_payment"
 */
export interface invoice_payment_insert_input {
  amount?: any | null;
  invoice?: invoice_obj_rel_insert_input | null;
  invoice_id?: any | null;
  payment?: payment_obj_rel_insert_input | null;
  payment_id?: any | null;
}

/**
 * order by max() on columns of table "invoice_payment"
 */
export interface invoice_payment_max_order_by {
  amount?: order_by | null;
  invoice_id?: order_by | null;
  payment_id?: order_by | null;
}

/**
 * order by min() on columns of table "invoice_payment"
 */
export interface invoice_payment_min_order_by {
  amount?: order_by | null;
  invoice_id?: order_by | null;
  payment_id?: order_by | null;
}

/**
 * on_conflict condition type for table "invoice_payment"
 */
export interface invoice_payment_on_conflict {
  constraint: invoice_payment_constraint;
  update_columns: invoice_payment_update_column[];
  where?: invoice_payment_bool_exp | null;
}

/**
 * order by stddev() on columns of table "invoice_payment"
 */
export interface invoice_payment_stddev_order_by {
  amount?: order_by | null;
  invoice_id?: order_by | null;
  payment_id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "invoice_payment"
 */
export interface invoice_payment_stddev_pop_order_by {
  amount?: order_by | null;
  invoice_id?: order_by | null;
  payment_id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "invoice_payment"
 */
export interface invoice_payment_stddev_samp_order_by {
  amount?: order_by | null;
  invoice_id?: order_by | null;
  payment_id?: order_by | null;
}

/**
 * order by sum() on columns of table "invoice_payment"
 */
export interface invoice_payment_sum_order_by {
  amount?: order_by | null;
  invoice_id?: order_by | null;
  payment_id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "invoice_payment"
 */
export interface invoice_payment_var_pop_order_by {
  amount?: order_by | null;
  invoice_id?: order_by | null;
  payment_id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "invoice_payment"
 */
export interface invoice_payment_var_samp_order_by {
  amount?: order_by | null;
  invoice_id?: order_by | null;
  payment_id?: order_by | null;
}

/**
 * order by variance() on columns of table "invoice_payment"
 */
export interface invoice_payment_variance_order_by {
  amount?: order_by | null;
  invoice_id?: order_by | null;
  payment_id?: order_by | null;
}

/**
 * input type for updating data in table "invoice"
 */
export interface invoice_set_input {
  amount_outstanding?: any | null;
  amount_paid?: any | null;
  amount_with_vat?: any | null;
  amount_wo_vat?: any | null;
  created_at?: any | null;
  created_by?: string | null;
  currency?: string | null;
  date_1?: any | null;
  date_2?: any | null;
  debtor_id?: number | null;
  document_date?: any | null;
  document_number?: string | null;
  due_date?: any | null;
  extra_1?: string | null;
  extra_2?: string | null;
  extra_3?: string | null;
  extra_4?: string | null;
  extra_5?: string | null;
  extra_6?: string | null;
  extra_document_number_1?: string | null;
  full_amount?: any | null;
  id?: number | null;
  invoice_type?: string | null;
  is_paid?: boolean | null;
  stage?: string | null;
  suspend_reminders_until?: any | null;
  time_1?: string | null;
  updated_at?: any | null;
  updated_by?: string | null;
  vat?: any | null;
}

/**
 * order by stddev() on columns of table "invoice"
 */
export interface invoice_stddev_order_by {
  amount_outstanding?: order_by | null;
  amount_paid?: order_by | null;
  amount_with_vat?: order_by | null;
  amount_wo_vat?: order_by | null;
  debtor_id?: order_by | null;
  full_amount?: order_by | null;
  id?: order_by | null;
  vat?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "invoice"
 */
export interface invoice_stddev_pop_order_by {
  amount_outstanding?: order_by | null;
  amount_paid?: order_by | null;
  amount_with_vat?: order_by | null;
  amount_wo_vat?: order_by | null;
  debtor_id?: order_by | null;
  full_amount?: order_by | null;
  id?: order_by | null;
  vat?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "invoice"
 */
export interface invoice_stddev_samp_order_by {
  amount_outstanding?: order_by | null;
  amount_paid?: order_by | null;
  amount_with_vat?: order_by | null;
  amount_wo_vat?: order_by | null;
  debtor_id?: order_by | null;
  full_amount?: order_by | null;
  id?: order_by | null;
  vat?: order_by | null;
}

/**
 * order by sum() on columns of table "invoice"
 */
export interface invoice_sum_order_by {
  amount_outstanding?: order_by | null;
  amount_paid?: order_by | null;
  amount_with_vat?: order_by | null;
  amount_wo_vat?: order_by | null;
  debtor_id?: order_by | null;
  full_amount?: order_by | null;
  id?: order_by | null;
  vat?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "invoice_template". All fields are combined with a logical 'AND'.
 */
export interface invoice_template_bool_exp {
  _and?: invoice_template_bool_exp[] | null;
  _not?: invoice_template_bool_exp | null;
  _or?: invoice_template_bool_exp[] | null;
  archived?: Boolean_comparison_exp | null;
  client?: client_bool_exp | null;
  client_id?: bigint_comparison_exp | null;
  content?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: uuid_comparison_exp | null;
  id?: bigint_comparison_exp | null;
  language?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  updated_by?: uuid_comparison_exp | null;
  version?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "invoice_template"
 */
export interface invoice_template_insert_input {
  archived?: boolean | null;
  client?: client_obj_rel_insert_input | null;
  client_id?: any | null;
  content?: string | null;
  created_at?: any | null;
  created_by?: any | null;
  id?: any | null;
  language?: string | null;
  name?: string | null;
  updated_at?: any | null;
  updated_by?: any | null;
  version?: number | null;
}

/**
 * input type for inserting object relation for remote table "invoice_template"
 */
export interface invoice_template_obj_rel_insert_input {
  data: invoice_template_insert_input;
  on_conflict?: invoice_template_on_conflict | null;
}

/**
 * on_conflict condition type for table "invoice_template"
 */
export interface invoice_template_on_conflict {
  constraint: invoice_template_constraint;
  update_columns: invoice_template_update_column[];
  where?: invoice_template_bool_exp | null;
}

/**
 * Ordering options when selecting data from "invoice_template".
 */
export interface invoice_template_order_by {
  archived?: order_by | null;
  client?: client_order_by | null;
  client_id?: order_by | null;
  content?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  id?: order_by | null;
  language?: order_by | null;
  name?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
  version?: order_by | null;
}

/**
 * input type for updating data in table "invoice_template"
 */
export interface invoice_template_set_input {
  archived?: boolean | null;
  client_id?: any | null;
  content?: string | null;
  created_at?: any | null;
  created_by?: any | null;
  id?: any | null;
  language?: string | null;
  name?: string | null;
  updated_at?: any | null;
  updated_by?: any | null;
  version?: number | null;
}

/**
 * order by var_pop() on columns of table "invoice"
 */
export interface invoice_var_pop_order_by {
  amount_outstanding?: order_by | null;
  amount_paid?: order_by | null;
  amount_with_vat?: order_by | null;
  amount_wo_vat?: order_by | null;
  debtor_id?: order_by | null;
  full_amount?: order_by | null;
  id?: order_by | null;
  vat?: order_by | null;
}

/**
 * order by var_samp() on columns of table "invoice"
 */
export interface invoice_var_samp_order_by {
  amount_outstanding?: order_by | null;
  amount_paid?: order_by | null;
  amount_with_vat?: order_by | null;
  amount_wo_vat?: order_by | null;
  debtor_id?: order_by | null;
  full_amount?: order_by | null;
  id?: order_by | null;
  vat?: order_by | null;
}

/**
 * order by variance() on columns of table "invoice"
 */
export interface invoice_variance_order_by {
  amount_outstanding?: order_by | null;
  amount_paid?: order_by | null;
  amount_with_vat?: order_by | null;
  amount_wo_vat?: order_by | null;
  debtor_id?: order_by | null;
  full_amount?: order_by | null;
  id?: order_by | null;
  vat?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'.
 */
export interface json_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

export interface jsonb_cast_exp {
  String?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'.
 */
export interface jsonb_comparison_exp {
  _cast?: jsonb_cast_exp | null;
  _contained_in?: any | null;
  _contains?: any | null;
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _has_key?: string | null;
  _has_keys_all?: string[] | null;
  _has_keys_any?: string[] | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'.
 */
export interface numeric_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * Boolean expression to filter rows from the table "payment". All fields are combined with a logical 'AND'.
 */
export interface payment_bool_exp {
  _and?: payment_bool_exp[] | null;
  _not?: payment_bool_exp | null;
  _or?: payment_bool_exp[] | null;
  amount?: numeric_comparison_exp | null;
  casecard_payments?: casecard_payment_bool_exp | null;
  casecard_payments_aggregate?: casecard_payment_aggregate_bool_exp | null;
  client?: client_bool_exp | null;
  client_id?: bigint_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: uuid_comparison_exp | null;
  currency?: String_comparison_exp | null;
  debtor?: debtor_bool_exp | null;
  debtor_id?: bigint_comparison_exp | null;
  from?: String_comparison_exp | null;
  iban?: String_comparison_exp | null;
  id?: bigint_comparison_exp | null;
  import_file_id?: bigint_comparison_exp | null;
  invoice_payments?: invoice_payment_bool_exp | null;
  invoice_payments_aggregate?: invoice_payment_aggregate_bool_exp | null;
  payer_name?: String_comparison_exp | null;
  payment_date?: date_comparison_exp | null;
  reason?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  updated_by?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "payment"
 */
export interface payment_insert_input {
  amount?: any | null;
  casecard_payments?: casecard_payment_arr_rel_insert_input | null;
  client?: client_obj_rel_insert_input | null;
  client_id?: any | null;
  created_at?: any | null;
  created_by?: any | null;
  currency?: string | null;
  debtor?: debtor_obj_rel_insert_input | null;
  debtor_id?: any | null;
  from?: string | null;
  iban?: string | null;
  id?: any | null;
  import_file_id?: any | null;
  invoice_payments?: invoice_payment_arr_rel_insert_input | null;
  payer_name?: string | null;
  payment_date?: any | null;
  reason?: string | null;
  updated_at?: any | null;
  updated_by?: any | null;
}

/**
 * input type for inserting object relation for remote table "payment"
 */
export interface payment_obj_rel_insert_input {
  data: payment_insert_input;
  on_conflict?: payment_on_conflict | null;
}

/**
 * on_conflict condition type for table "payment"
 */
export interface payment_on_conflict {
  constraint: payment_constraint;
  update_columns: payment_update_column[];
  where?: payment_bool_exp | null;
}

export interface reminder_aggregate_bool_exp {
  count?: reminder_aggregate_bool_exp_count | null;
}

export interface reminder_aggregate_bool_exp_count {
  arguments?: reminder_select_column[] | null;
  distinct?: boolean | null;
  filter?: reminder_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "reminder"
 */
export interface reminder_aggregate_order_by {
  avg?: reminder_avg_order_by | null;
  count?: order_by | null;
  max?: reminder_max_order_by | null;
  min?: reminder_min_order_by | null;
  stddev?: reminder_stddev_order_by | null;
  stddev_pop?: reminder_stddev_pop_order_by | null;
  stddev_samp?: reminder_stddev_samp_order_by | null;
  sum?: reminder_sum_order_by | null;
  var_pop?: reminder_var_pop_order_by | null;
  var_samp?: reminder_var_samp_order_by | null;
  variance?: reminder_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "reminder"
 */
export interface reminder_arr_rel_insert_input {
  data: reminder_insert_input[];
  on_conflict?: reminder_on_conflict | null;
}

/**
 * order by avg() on columns of table "reminder"
 */
export interface reminder_avg_order_by {
  client_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  period_days_from?: order_by | null;
  period_days_to?: order_by | null;
  template_id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "reminder". All fields are combined with a logical 'AND'.
 */
export interface reminder_bool_exp {
  _and?: reminder_bool_exp[] | null;
  _not?: reminder_bool_exp | null;
  _or?: reminder_bool_exp[] | null;
  cancelled_at?: timestamptz_comparison_exp | null;
  cancelled_by?: uuid_comparison_exp | null;
  cancelled_by_user?: user_bool_exp | null;
  client?: client_bool_exp | null;
  client_id?: Int_comparison_exp | null;
  completed_at?: timestamptz_comparison_exp | null;
  completed_by?: uuid_comparison_exp | null;
  completed_by_user?: user_bool_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: uuid_comparison_exp | null;
  creator?: user_bool_exp | null;
  debtor?: debtor_bool_exp | null;
  debtor_id?: Int_comparison_exp | null;
  events?: reminder_events_bool_exp | null;
  events_aggregate?: reminder_events_aggregate_bool_exp | null;
  id?: Int_comparison_exp | null;
  import_file?: import_file_bool_exp | null;
  import_file_id?: Int_comparison_exp | null;
  period?: String_comparison_exp | null;
  period_days_from?: Int_comparison_exp | null;
  period_days_to?: Int_comparison_exp | null;
  reminder_send_requests?: reminder_send_request_bool_exp | null;
  reminder_send_requests_aggregate?: reminder_send_request_aggregate_bool_exp | null;
  stage?: String_comparison_exp | null;
  status?: String_comparison_exp | null;
  template?: template_bool_exp | null;
  template_id?: Int_comparison_exp | null;
  type?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  updated_by?: uuid_comparison_exp | null;
  updated_by_user?: user_bool_exp | null;
}

export interface reminder_events_aggregate_bool_exp {
  count?: reminder_events_aggregate_bool_exp_count | null;
}

export interface reminder_events_aggregate_bool_exp_count {
  arguments?: reminder_events_select_column[] | null;
  distinct?: boolean | null;
  filter?: reminder_events_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "reminder_events"
 */
export interface reminder_events_aggregate_order_by {
  avg?: reminder_events_avg_order_by | null;
  count?: order_by | null;
  max?: reminder_events_max_order_by | null;
  min?: reminder_events_min_order_by | null;
  stddev?: reminder_events_stddev_order_by | null;
  stddev_pop?: reminder_events_stddev_pop_order_by | null;
  stddev_samp?: reminder_events_stddev_samp_order_by | null;
  sum?: reminder_events_sum_order_by | null;
  var_pop?: reminder_events_var_pop_order_by | null;
  var_samp?: reminder_events_var_samp_order_by | null;
  variance?: reminder_events_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "reminder_events"
 */
export interface reminder_events_arr_rel_insert_input {
  data: reminder_events_insert_input[];
  on_conflict?: reminder_events_on_conflict | null;
}

/**
 * order by avg() on columns of table "reminder_events"
 */
export interface reminder_events_avg_order_by {
  event_id?: order_by | null;
  reminder_id?: order_by | null;
  reminder_send_request_id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "reminder_events". All fields are combined with a logical 'AND'.
 */
export interface reminder_events_bool_exp {
  _and?: reminder_events_bool_exp[] | null;
  _not?: reminder_events_bool_exp | null;
  _or?: reminder_events_bool_exp[] | null;
  event?: event_bool_exp | null;
  event_id?: bigint_comparison_exp | null;
  reminder?: reminder_bool_exp | null;
  reminder_id?: bigint_comparison_exp | null;
  reminder_send_request?: reminder_send_request_bool_exp | null;
  reminder_send_request_id?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "reminder_events"
 */
export interface reminder_events_insert_input {
  event?: event_obj_rel_insert_input | null;
  event_id?: any | null;
  reminder?: reminder_obj_rel_insert_input | null;
  reminder_id?: any | null;
  reminder_send_request?: reminder_send_request_obj_rel_insert_input | null;
  reminder_send_request_id?: number | null;
}

/**
 * order by max() on columns of table "reminder_events"
 */
export interface reminder_events_max_order_by {
  event_id?: order_by | null;
  reminder_id?: order_by | null;
  reminder_send_request_id?: order_by | null;
}

/**
 * order by min() on columns of table "reminder_events"
 */
export interface reminder_events_min_order_by {
  event_id?: order_by | null;
  reminder_id?: order_by | null;
  reminder_send_request_id?: order_by | null;
}

/**
 * on_conflict condition type for table "reminder_events"
 */
export interface reminder_events_on_conflict {
  constraint: reminder_events_constraint;
  update_columns: reminder_events_update_column[];
  where?: reminder_events_bool_exp | null;
}

/**
 * order by stddev() on columns of table "reminder_events"
 */
export interface reminder_events_stddev_order_by {
  event_id?: order_by | null;
  reminder_id?: order_by | null;
  reminder_send_request_id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "reminder_events"
 */
export interface reminder_events_stddev_pop_order_by {
  event_id?: order_by | null;
  reminder_id?: order_by | null;
  reminder_send_request_id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "reminder_events"
 */
export interface reminder_events_stddev_samp_order_by {
  event_id?: order_by | null;
  reminder_id?: order_by | null;
  reminder_send_request_id?: order_by | null;
}

/**
 * order by sum() on columns of table "reminder_events"
 */
export interface reminder_events_sum_order_by {
  event_id?: order_by | null;
  reminder_id?: order_by | null;
  reminder_send_request_id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "reminder_events"
 */
export interface reminder_events_var_pop_order_by {
  event_id?: order_by | null;
  reminder_id?: order_by | null;
  reminder_send_request_id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "reminder_events"
 */
export interface reminder_events_var_samp_order_by {
  event_id?: order_by | null;
  reminder_id?: order_by | null;
  reminder_send_request_id?: order_by | null;
}

/**
 * order by variance() on columns of table "reminder_events"
 */
export interface reminder_events_variance_order_by {
  event_id?: order_by | null;
  reminder_id?: order_by | null;
  reminder_send_request_id?: order_by | null;
}

/**
 * input type for inserting data into table "reminder"
 */
export interface reminder_insert_input {
  cancelled_at?: any | null;
  cancelled_by?: any | null;
  cancelled_by_user?: user_obj_rel_insert_input | null;
  client?: client_obj_rel_insert_input | null;
  client_id?: number | null;
  completed_at?: any | null;
  completed_by?: any | null;
  completed_by_user?: user_obj_rel_insert_input | null;
  created_at?: any | null;
  created_by?: any | null;
  creator?: user_obj_rel_insert_input | null;
  debtor?: debtor_obj_rel_insert_input | null;
  debtor_id?: number | null;
  events?: reminder_events_arr_rel_insert_input | null;
  id?: number | null;
  import_file?: import_file_obj_rel_insert_input | null;
  import_file_id?: number | null;
  period?: string | null;
  period_days_from?: number | null;
  period_days_to?: number | null;
  reminder_send_requests?: reminder_send_request_arr_rel_insert_input | null;
  stage?: string | null;
  status?: string | null;
  template?: template_obj_rel_insert_input | null;
  template_id?: number | null;
  type?: string | null;
  updated_at?: any | null;
  updated_by?: any | null;
  updated_by_user?: user_obj_rel_insert_input | null;
}

/**
 * order by max() on columns of table "reminder"
 */
export interface reminder_max_order_by {
  cancelled_at?: order_by | null;
  cancelled_by?: order_by | null;
  client_id?: order_by | null;
  completed_at?: order_by | null;
  completed_by?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  period?: order_by | null;
  period_days_from?: order_by | null;
  period_days_to?: order_by | null;
  stage?: order_by | null;
  status?: order_by | null;
  template_id?: order_by | null;
  type?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
}

/**
 * order by min() on columns of table "reminder"
 */
export interface reminder_min_order_by {
  cancelled_at?: order_by | null;
  cancelled_by?: order_by | null;
  client_id?: order_by | null;
  completed_at?: order_by | null;
  completed_by?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  period?: order_by | null;
  period_days_from?: order_by | null;
  period_days_to?: order_by | null;
  stage?: order_by | null;
  status?: order_by | null;
  template_id?: order_by | null;
  type?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "reminder"
 */
export interface reminder_obj_rel_insert_input {
  data: reminder_insert_input;
  on_conflict?: reminder_on_conflict | null;
}

/**
 * on_conflict condition type for table "reminder"
 */
export interface reminder_on_conflict {
  constraint: reminder_constraint;
  update_columns: reminder_update_column[];
  where?: reminder_bool_exp | null;
}

/**
 * Ordering options when selecting data from "reminder".
 */
export interface reminder_order_by {
  cancelled_at?: order_by | null;
  cancelled_by?: order_by | null;
  cancelled_by_user?: user_order_by | null;
  client?: client_order_by | null;
  client_id?: order_by | null;
  completed_at?: order_by | null;
  completed_by?: order_by | null;
  completed_by_user?: user_order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  creator?: user_order_by | null;
  debtor?: debtor_order_by | null;
  debtor_id?: order_by | null;
  events_aggregate?: reminder_events_aggregate_order_by | null;
  id?: order_by | null;
  import_file?: import_file_order_by | null;
  import_file_id?: order_by | null;
  period?: order_by | null;
  period_days_from?: order_by | null;
  period_days_to?: order_by | null;
  reminder_send_requests_aggregate?: reminder_send_request_aggregate_order_by | null;
  stage?: order_by | null;
  status?: order_by | null;
  template?: template_order_by | null;
  template_id?: order_by | null;
  type?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
  updated_by_user?: user_order_by | null;
}

export interface reminder_send_request_aggregate_bool_exp {
  count?: reminder_send_request_aggregate_bool_exp_count | null;
}

export interface reminder_send_request_aggregate_bool_exp_count {
  arguments?: reminder_send_request_select_column[] | null;
  distinct?: boolean | null;
  filter?: reminder_send_request_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "reminder_send_request"
 */
export interface reminder_send_request_aggregate_order_by {
  avg?: reminder_send_request_avg_order_by | null;
  count?: order_by | null;
  max?: reminder_send_request_max_order_by | null;
  min?: reminder_send_request_min_order_by | null;
  stddev?: reminder_send_request_stddev_order_by | null;
  stddev_pop?: reminder_send_request_stddev_pop_order_by | null;
  stddev_samp?: reminder_send_request_stddev_samp_order_by | null;
  sum?: reminder_send_request_sum_order_by | null;
  var_pop?: reminder_send_request_var_pop_order_by | null;
  var_samp?: reminder_send_request_var_samp_order_by | null;
  variance?: reminder_send_request_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "reminder_send_request"
 */
export interface reminder_send_request_arr_rel_insert_input {
  data: reminder_send_request_insert_input[];
  on_conflict?: reminder_send_request_on_conflict | null;
}

/**
 * order by avg() on columns of table "reminder_send_request"
 */
export interface reminder_send_request_avg_order_by {
  id?: order_by | null;
  reminder_id?: order_by | null;
  reminders_send_request_id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "reminder_send_request". All fields are combined with a logical 'AND'.
 */
export interface reminder_send_request_bool_exp {
  _and?: reminder_send_request_bool_exp[] | null;
  _not?: reminder_send_request_bool_exp | null;
  _or?: reminder_send_request_bool_exp[] | null;
  created_at?: timestamp_comparison_exp | null;
  created_by?: String_comparison_exp | null;
  email_attachments?: json_comparison_exp | null;
  email_body?: String_comparison_exp | null;
  email_recipients?: String_comparison_exp | null;
  email_subject?: String_comparison_exp | null;
  error_phone_numbers?: json_comparison_exp | null;
  error_reason?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  message_id?: String_comparison_exp | null;
  reminder?: reminder_bool_exp | null;
  reminder_id?: Int_comparison_exp | null;
  reminders_send_request?: reminders_send_request_bool_exp | null;
  reminders_send_request_id?: Int_comparison_exp | null;
  sms?: String_comparison_exp | null;
  status?: String_comparison_exp | null;
  success_phone_numbers?: json_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "reminder_send_request"
 */
export interface reminder_send_request_insert_input {
  created_at?: any | null;
  created_by?: string | null;
  email_attachments?: any | null;
  email_body?: string | null;
  email_recipients?: string | null;
  email_subject?: string | null;
  error_phone_numbers?: any | null;
  error_reason?: string | null;
  id?: number | null;
  message_id?: string | null;
  reminder?: reminder_obj_rel_insert_input | null;
  reminder_id?: number | null;
  reminders_send_request?: reminders_send_request_obj_rel_insert_input | null;
  reminders_send_request_id?: number | null;
  sms?: string | null;
  status?: string | null;
  success_phone_numbers?: any | null;
  updated_at?: any | null;
}

/**
 * order by max() on columns of table "reminder_send_request"
 */
export interface reminder_send_request_max_order_by {
  created_at?: order_by | null;
  created_by?: order_by | null;
  email_body?: order_by | null;
  email_recipients?: order_by | null;
  email_subject?: order_by | null;
  error_reason?: order_by | null;
  id?: order_by | null;
  message_id?: order_by | null;
  reminder_id?: order_by | null;
  reminders_send_request_id?: order_by | null;
  sms?: order_by | null;
  status?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "reminder_send_request"
 */
export interface reminder_send_request_min_order_by {
  created_at?: order_by | null;
  created_by?: order_by | null;
  email_body?: order_by | null;
  email_recipients?: order_by | null;
  email_subject?: order_by | null;
  error_reason?: order_by | null;
  id?: order_by | null;
  message_id?: order_by | null;
  reminder_id?: order_by | null;
  reminders_send_request_id?: order_by | null;
  sms?: order_by | null;
  status?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "reminder_send_request"
 */
export interface reminder_send_request_obj_rel_insert_input {
  data: reminder_send_request_insert_input;
  on_conflict?: reminder_send_request_on_conflict | null;
}

/**
 * on_conflict condition type for table "reminder_send_request"
 */
export interface reminder_send_request_on_conflict {
  constraint: reminder_send_request_constraint;
  update_columns: reminder_send_request_update_column[];
  where?: reminder_send_request_bool_exp | null;
}

/**
 * order by stddev() on columns of table "reminder_send_request"
 */
export interface reminder_send_request_stddev_order_by {
  id?: order_by | null;
  reminder_id?: order_by | null;
  reminders_send_request_id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "reminder_send_request"
 */
export interface reminder_send_request_stddev_pop_order_by {
  id?: order_by | null;
  reminder_id?: order_by | null;
  reminders_send_request_id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "reminder_send_request"
 */
export interface reminder_send_request_stddev_samp_order_by {
  id?: order_by | null;
  reminder_id?: order_by | null;
  reminders_send_request_id?: order_by | null;
}

/**
 * order by sum() on columns of table "reminder_send_request"
 */
export interface reminder_send_request_sum_order_by {
  id?: order_by | null;
  reminder_id?: order_by | null;
  reminders_send_request_id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "reminder_send_request"
 */
export interface reminder_send_request_var_pop_order_by {
  id?: order_by | null;
  reminder_id?: order_by | null;
  reminders_send_request_id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "reminder_send_request"
 */
export interface reminder_send_request_var_samp_order_by {
  id?: order_by | null;
  reminder_id?: order_by | null;
  reminders_send_request_id?: order_by | null;
}

/**
 * order by variance() on columns of table "reminder_send_request"
 */
export interface reminder_send_request_variance_order_by {
  id?: order_by | null;
  reminder_id?: order_by | null;
  reminders_send_request_id?: order_by | null;
}

/**
 * order by stddev() on columns of table "reminder"
 */
export interface reminder_stddev_order_by {
  client_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  period_days_from?: order_by | null;
  period_days_to?: order_by | null;
  template_id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "reminder"
 */
export interface reminder_stddev_pop_order_by {
  client_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  period_days_from?: order_by | null;
  period_days_to?: order_by | null;
  template_id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "reminder"
 */
export interface reminder_stddev_samp_order_by {
  client_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  period_days_from?: order_by | null;
  period_days_to?: order_by | null;
  template_id?: order_by | null;
}

/**
 * order by sum() on columns of table "reminder"
 */
export interface reminder_sum_order_by {
  client_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  period_days_from?: order_by | null;
  period_days_to?: order_by | null;
  template_id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "reminder"
 */
export interface reminder_var_pop_order_by {
  client_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  period_days_from?: order_by | null;
  period_days_to?: order_by | null;
  template_id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "reminder"
 */
export interface reminder_var_samp_order_by {
  client_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  period_days_from?: order_by | null;
  period_days_to?: order_by | null;
  template_id?: order_by | null;
}

/**
 * order by variance() on columns of table "reminder"
 */
export interface reminder_variance_order_by {
  client_id?: order_by | null;
  debtor_id?: order_by | null;
  id?: order_by | null;
  import_file_id?: order_by | null;
  period_days_from?: order_by | null;
  period_days_to?: order_by | null;
  template_id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "reminders_send_request". All fields are combined with a logical 'AND'.
 */
export interface reminders_send_request_bool_exp {
  _and?: reminders_send_request_bool_exp[] | null;
  _not?: reminders_send_request_bool_exp | null;
  _or?: reminders_send_request_bool_exp[] | null;
  client?: client_bool_exp | null;
  client_id?: Int_comparison_exp | null;
  completed_at?: timestamptz_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: uuid_comparison_exp | null;
  creator?: user_bool_exp | null;
  id?: Int_comparison_exp | null;
  reminder_send_requests?: reminder_send_request_bool_exp | null;
  reminder_send_requests_aggregate?: reminder_send_request_aggregate_bool_exp | null;
  started_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "reminders_send_request"
 */
export interface reminders_send_request_insert_input {
  client?: client_obj_rel_insert_input | null;
  client_id?: number | null;
  completed_at?: any | null;
  created_at?: any | null;
  created_by?: any | null;
  creator?: user_obj_rel_insert_input | null;
  id?: number | null;
  reminder_send_requests?: reminder_send_request_arr_rel_insert_input | null;
  started_at?: any | null;
}

/**
 * input type for inserting object relation for remote table "reminders_send_request"
 */
export interface reminders_send_request_obj_rel_insert_input {
  data: reminders_send_request_insert_input;
  on_conflict?: reminders_send_request_on_conflict | null;
}

/**
 * on_conflict condition type for table "reminders_send_request"
 */
export interface reminders_send_request_on_conflict {
  constraint: reminders_send_request_constraint;
  update_columns: reminders_send_request_update_column[];
  where?: reminders_send_request_bool_exp | null;
}

export interface template_aggregate_bool_exp {
  bool_and?: template_aggregate_bool_exp_bool_and | null;
  bool_or?: template_aggregate_bool_exp_bool_or | null;
  count?: template_aggregate_bool_exp_count | null;
}

export interface template_aggregate_bool_exp_bool_and {
  arguments: template_select_column_template_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: template_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface template_aggregate_bool_exp_bool_or {
  arguments: template_select_column_template_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: template_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface template_aggregate_bool_exp_count {
  arguments?: template_select_column[] | null;
  distinct?: boolean | null;
  filter?: template_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "template"
 */
export interface template_aggregate_order_by {
  avg?: template_avg_order_by | null;
  count?: order_by | null;
  max?: template_max_order_by | null;
  min?: template_min_order_by | null;
  stddev?: template_stddev_order_by | null;
  stddev_pop?: template_stddev_pop_order_by | null;
  stddev_samp?: template_stddev_samp_order_by | null;
  sum?: template_sum_order_by | null;
  var_pop?: template_var_pop_order_by | null;
  var_samp?: template_var_samp_order_by | null;
  variance?: template_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "template"
 */
export interface template_arr_rel_insert_input {
  data: template_insert_input[];
  on_conflict?: template_on_conflict | null;
}

/**
 * order by avg() on columns of table "template"
 */
export interface template_avg_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "template". All fields are combined with a logical 'AND'.
 */
export interface template_bool_exp {
  _and?: template_bool_exp[] | null;
  _not?: template_bool_exp | null;
  _or?: template_bool_exp[] | null;
  archived?: Boolean_comparison_exp | null;
  attachment_filename?: String_comparison_exp | null;
  client?: client_bool_exp | null;
  client_id?: Int_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: String_comparison_exp | null;
  email_content?: String_comparison_exp | null;
  email_subject?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  language?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  reminder_content?: String_comparison_exp | null;
  sms_content?: String_comparison_exp | null;
  type?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  updated_by?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "template"
 */
export interface template_insert_input {
  archived?: boolean | null;
  attachment_filename?: string | null;
  client?: client_obj_rel_insert_input | null;
  client_id?: number | null;
  created_at?: any | null;
  created_by?: string | null;
  email_content?: string | null;
  email_subject?: string | null;
  id?: number | null;
  language?: string | null;
  name?: string | null;
  reminder_content?: string | null;
  sms_content?: string | null;
  type?: string | null;
  updated_at?: any | null;
  updated_by?: string | null;
}

/**
 * order by max() on columns of table "template"
 */
export interface template_max_order_by {
  attachment_filename?: order_by | null;
  client_id?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  email_content?: order_by | null;
  email_subject?: order_by | null;
  id?: order_by | null;
  language?: order_by | null;
  name?: order_by | null;
  reminder_content?: order_by | null;
  sms_content?: order_by | null;
  type?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
}

/**
 * order by min() on columns of table "template"
 */
export interface template_min_order_by {
  attachment_filename?: order_by | null;
  client_id?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  email_content?: order_by | null;
  email_subject?: order_by | null;
  id?: order_by | null;
  language?: order_by | null;
  name?: order_by | null;
  reminder_content?: order_by | null;
  sms_content?: order_by | null;
  type?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "template"
 */
export interface template_obj_rel_insert_input {
  data: template_insert_input;
  on_conflict?: template_on_conflict | null;
}

/**
 * on_conflict condition type for table "template"
 */
export interface template_on_conflict {
  constraint: template_constraint;
  update_columns: template_update_column[];
  where?: template_bool_exp | null;
}

/**
 * Ordering options when selecting data from "template".
 */
export interface template_order_by {
  archived?: order_by | null;
  attachment_filename?: order_by | null;
  client?: client_order_by | null;
  client_id?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  email_content?: order_by | null;
  email_subject?: order_by | null;
  id?: order_by | null;
  language?: order_by | null;
  name?: order_by | null;
  reminder_content?: order_by | null;
  sms_content?: order_by | null;
  type?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
}

/**
 * input type for updating data in table "template"
 */
export interface template_set_input {
  archived?: boolean | null;
  attachment_filename?: string | null;
  client_id?: number | null;
  created_at?: any | null;
  created_by?: string | null;
  email_content?: string | null;
  email_subject?: string | null;
  id?: number | null;
  language?: string | null;
  name?: string | null;
  reminder_content?: string | null;
  sms_content?: string | null;
  type?: string | null;
  updated_at?: any | null;
  updated_by?: string | null;
}

/**
 * order by stddev() on columns of table "template"
 */
export interface template_stddev_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "template"
 */
export interface template_stddev_pop_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "template"
 */
export interface template_stddev_samp_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "template"
 */
export interface template_sum_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "template"
 */
export interface template_var_pop_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "template"
 */
export interface template_var_samp_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "template"
 */
export interface template_variance_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'.
 */
export interface time_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'.
 */
export interface timestamp_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'.
 */
export interface timestamptz_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'.
 */
export interface user_bool_exp {
  _and?: user_bool_exp[] | null;
  _not?: user_bool_exp | null;
  _or?: user_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: String_comparison_exp | null;
  email?: String_comparison_exp | null;
  first_name?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  is_admin?: Boolean_comparison_exp | null;
  last_name?: String_comparison_exp | null;
  password?: String_comparison_exp | null;
  password_reset_token?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  updated_by?: String_comparison_exp | null;
  user_roles?: user_role_bool_exp | null;
  user_roles_aggregate?: user_role_aggregate_bool_exp | null;
}

/**
 * input type for inserting data into table "user"
 */
export interface user_insert_input {
  created_at?: any | null;
  created_by?: string | null;
  email?: string | null;
  first_name?: string | null;
  id?: any | null;
  is_active?: boolean | null;
  is_admin?: boolean | null;
  last_name?: string | null;
  password?: string | null;
  password_reset_token?: string | null;
  updated_at?: any | null;
  updated_by?: string | null;
  user_roles?: user_role_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user"
 */
export interface user_obj_rel_insert_input {
  data: user_insert_input;
  on_conflict?: user_on_conflict | null;
}

/**
 * on_conflict condition type for table "user"
 */
export interface user_on_conflict {
  constraint: user_constraint;
  update_columns: user_update_column[];
  where?: user_bool_exp | null;
}

/**
 * Ordering options when selecting data from "user".
 */
export interface user_order_by {
  created_at?: order_by | null;
  created_by?: order_by | null;
  email?: order_by | null;
  first_name?: order_by | null;
  id?: order_by | null;
  is_active?: order_by | null;
  is_admin?: order_by | null;
  last_name?: order_by | null;
  password?: order_by | null;
  password_reset_token?: order_by | null;
  updated_at?: order_by | null;
  updated_by?: order_by | null;
  user_roles_aggregate?: user_role_aggregate_order_by | null;
}

export interface user_role_aggregate_bool_exp {
  bool_and?: user_role_aggregate_bool_exp_bool_and | null;
  bool_or?: user_role_aggregate_bool_exp_bool_or | null;
  count?: user_role_aggregate_bool_exp_count | null;
}

export interface user_role_aggregate_bool_exp_bool_and {
  arguments: user_role_select_column_user_role_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: user_role_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface user_role_aggregate_bool_exp_bool_or {
  arguments: user_role_select_column_user_role_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: user_role_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface user_role_aggregate_bool_exp_count {
  arguments?: user_role_select_column[] | null;
  distinct?: boolean | null;
  filter?: user_role_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "user_role"
 */
export interface user_role_aggregate_order_by {
  avg?: user_role_avg_order_by | null;
  count?: order_by | null;
  max?: user_role_max_order_by | null;
  min?: user_role_min_order_by | null;
  stddev?: user_role_stddev_order_by | null;
  stddev_pop?: user_role_stddev_pop_order_by | null;
  stddev_samp?: user_role_stddev_samp_order_by | null;
  sum?: user_role_sum_order_by | null;
  var_pop?: user_role_var_pop_order_by | null;
  var_samp?: user_role_var_samp_order_by | null;
  variance?: user_role_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "user_role"
 */
export interface user_role_arr_rel_insert_input {
  data: user_role_insert_input[];
  on_conflict?: user_role_on_conflict | null;
}

/**
 * order by avg() on columns of table "user_role"
 */
export interface user_role_avg_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_role". All fields are combined with a logical 'AND'.
 */
export interface user_role_bool_exp {
  _and?: user_role_bool_exp[] | null;
  _not?: user_role_bool_exp | null;
  _or?: user_role_bool_exp[] | null;
  client?: client_bool_exp | null;
  client_id?: Int_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by?: String_comparison_exp | null;
  dr_general?: Boolean_comparison_exp | null;
  dr_judicial?: Boolean_comparison_exp | null;
  dr_recovery?: Boolean_comparison_exp | null;
  dr_reminder?: Boolean_comparison_exp | null;
  id?: Int_comparison_exp | null;
  role?: String_comparison_exp | null;
  user?: user_bool_exp | null;
  user_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_role"
 */
export interface user_role_insert_input {
  client?: client_obj_rel_insert_input | null;
  client_id?: number | null;
  created_at?: any | null;
  created_by?: string | null;
  dr_general?: boolean | null;
  dr_judicial?: boolean | null;
  dr_recovery?: boolean | null;
  dr_reminder?: boolean | null;
  id?: number | null;
  role?: string | null;
  user?: user_obj_rel_insert_input | null;
  user_id?: any | null;
}

/**
 * order by max() on columns of table "user_role"
 */
export interface user_role_max_order_by {
  client_id?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  id?: order_by | null;
  role?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "user_role"
 */
export interface user_role_min_order_by {
  client_id?: order_by | null;
  created_at?: order_by | null;
  created_by?: order_by | null;
  id?: order_by | null;
  role?: order_by | null;
  user_id?: order_by | null;
}

/**
 * on_conflict condition type for table "user_role"
 */
export interface user_role_on_conflict {
  constraint: user_role_constraint;
  update_columns: user_role_update_column[];
  where?: user_role_bool_exp | null;
}

/**
 * order by stddev() on columns of table "user_role"
 */
export interface user_role_stddev_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_role"
 */
export interface user_role_stddev_pop_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_role"
 */
export interface user_role_stddev_samp_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "user_role"
 */
export interface user_role_sum_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_role"
 */
export interface user_role_var_pop_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_role"
 */
export interface user_role_var_samp_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "user_role"
 */
export interface user_role_variance_order_by {
  client_id?: order_by | null;
  id?: order_by | null;
}

/**
 * input type for updating data in table "user"
 */
export interface user_set_input {
  created_at?: any | null;
  created_by?: string | null;
  email?: string | null;
  first_name?: string | null;
  id?: any | null;
  is_active?: boolean | null;
  is_admin?: boolean | null;
  last_name?: string | null;
  password?: string | null;
  password_reset_token?: string | null;
  updated_at?: any | null;
  updated_by?: string | null;
}

/**
 * Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'.
 */
export interface uuid_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
