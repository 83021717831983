import React, { FC, useState, useCallback } from 'react'
import Button from 'antd/lib/button'
import { PlusOutlined } from '@ant-design/icons'
import { NewCommentForm } from './NewCommentForm'
import { GetEventsVariables } from '@/queries/_gen_/GetEvents'

interface NewCommentItemProps {
  reminderId?: number
  invoiceId?: number
  debtorId: number
  stage?: string
  variables?: GetEventsVariables
}

export const NewCommentItem: FC<NewCommentItemProps> = props => {
  const [editingActive, setEditingActive] = useState(false)

  const onSaved = useCallback(() => {
    setEditingActive(false)
  }, [])

  const onCancel = useCallback(() => {
    setEditingActive(false)
  }, [])

  if (editingActive) {
    return <NewCommentForm {...props} onSaved={onSaved} onCancel={onCancel} />
  } else {
    return (
      <Button onClick={() => setEditingActive(true)}>
        <PlusOutlined />
        Add comment
      </Button>
    )
  }
}
