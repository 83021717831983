export interface SheetColumn {
  label: string
  type: ColumnType
}

export type Cell = [string, string[]] // value, errors

export interface Sheet {
  name: string
  columns: SheetColumn[]
  rows: string[][] // row:column:value
  errors: string[][][]
}

export interface CurrentWorkbook {
  format: SheetFormat
  selectedSheetName: string
  sheets: { [key: string]: Sheet }
}

export interface RawSheet {
  name: string
  rows: unknown[][]
}

export interface RawWorkbook {
  sheets: RawSheet[]
}

export enum SheetFormat {
  // eslint-disable-next-line prettier/prettier
  simple = 'simple',
  silva = 'silva',
  custom = 'custom' // previous use config loaded from db
}
export interface SheetConfig {
  format: SheetFormat
  columnTypes?: ColumnType[]
}

export const sheetFormatsWithoutContactsImport = [SheetFormat.silva]

export const SheetFormatNames: { [key in SheetFormat]: string } = {
  [SheetFormat.simple]: 'Basic',
  [SheetFormat.silva]: 'SILVA',
  [SheetFormat.custom]: 'Custom'
}

export const sheetFormats = Object.values(SheetFormat)

export enum ColumnType {
  skip = 'skip',
  customerRefNo = 'customer_ref_no',
  companyCode = 'debtor_code',
  companyName = 'debtor_name',
  debtorAddress = 'debtor_address',
  debtorCity = 'debtor_city',
  debtorPhoneNumber = 'debtor_phone_number',
  caseNumber = 'case_number',
  caseDate = 'case_date',
  casePaymentDate = 'case_payment_date',
  amountOutstanding = 'amount_outstanding',
  currency = 'currency',
  debtorEmail = 'debtor_email',
  penaltyAmount = 'penalty_amount'
}

export const columnTypes = Object.values(ColumnType)

const defaultInvoiceImportRequiredColumns = [
  ColumnType.customerRefNo,
  ColumnType.companyCode,
  ColumnType.companyName,
  ColumnType.debtorAddress,
  ColumnType.debtorCity,
  ColumnType.debtorPhoneNumber,
  ColumnType.caseNumber,
  ColumnType.caseDate,
  ColumnType.casePaymentDate,
  ColumnType.amountOutstanding,
  ColumnType.debtorEmail,
  ColumnType.penaltyAmount
]

export const columnsRequiredForInvoiceImport: { [key in SheetFormat]: ColumnType[] } = {
  [SheetFormat.simple]: defaultInvoiceImportRequiredColumns,
  [SheetFormat.silva]: defaultInvoiceImportRequiredColumns,
  [SheetFormat.custom]: defaultInvoiceImportRequiredColumns
}

export const columnTypeNames: { [key in ColumnType]: string } = {
  [ColumnType.skip]: 'Skip',
  [ColumnType.customerRefNo]: 'Customer Ref. No',
  [ColumnType.companyCode]: 'Company Code',
  [ColumnType.companyName]: 'Company Name',
  [ColumnType.debtorAddress]: 'Address',
  [ColumnType.debtorCity]: 'City',
  [ColumnType.debtorPhoneNumber]: 'Debtor Phone Number',
  [ColumnType.caseNumber]: 'Case Number',
  [ColumnType.caseDate]: 'Case Date',
  [ColumnType.casePaymentDate]: 'Case Payment Date',
  [ColumnType.amountOutstanding]: 'Amount Outstanding',
  [ColumnType.currency]: 'Currency',
  [ColumnType.debtorEmail]: 'Debtor Email',
  [ColumnType.penaltyAmount]: 'Penalty Amount'
}

export const contactColumnTypes = [ColumnType.debtorAddress, ColumnType.debtorPhoneNumber, ColumnType.debtorEmail]
