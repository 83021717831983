import React, { FC, useState, useEffect } from 'react'
import InsetContainer from '@/components/layouts/InsetContainer'
import Container from '@/components/layouts/Container'
import { Routes, Route, Navigate, useNavigate } from 'react-router'
import { PayupDropzone } from '@/components/PayupDropzone'
import csvImg from '@/images/csv.svg'
import { useParsePaymentsCSV } from '@/hooks/import_payments/useParsePaymentsCSV'
import Spinner from '@/components/Spinner'
import { PaymentImportConfirm } from './components/PaymentImportConfirm'
import { PaymentImportBreadcrumbs } from './components/PaymentImportBreadcrumbs'

const ACCEPTED_MIME_TYPES = ['text/csv']

export const PaymentImportView: FC = () => {
  const [file, setFile] = useState<File | null>(null)
  const { result, loading, error } = useParsePaymentsCSV(file ?? undefined)
  const nav = useNavigate()

  useEffect(() => {
    if (result) {
      nav('/import_payments/confirm')
    }
  }, [result, nav])

  return (
    <InsetContainer>
      <Container $centeredColumn={true}>
        <PaymentImportBreadcrumbs hasFile={!!result} />
        {loading ? (
          <Spinner noDelay={true} tip='Reading CSV file...' />
        ) : (
          <Routes>
            <Route
              path='/'
              element={
                <PayupDropzone
                  file={file}
                  onUpload={files => files.length && setFile(files[0])}
                  typeName='csv'
                  parseError={error ?? undefined}
                  acceptedMimeTypes={ACCEPTED_MIME_TYPES}
                  iconSrc={csvImg}
                />
              }
            />
            {result && file && <Route path='/confirm' element={<PaymentImportConfirm file={file} rows={result} />} />}
            <Route element={<Navigate to='/import_payments' />} />
          </Routes>
        )}
      </Container>
    </InsetContainer>
  )
}
