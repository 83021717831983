import React, { FC, useMemo, useCallback } from 'react'
import { DebtRecoveryTemplate, TemplateType, DebtRecoveryTemplateWithId } from '@/models/templates'
import { Formik, Form } from 'formik'
import { ShortFormikField, FormikField } from '@/components/form/FormikField'
import { ClientWithId } from '@/models/client'
import * as yup from 'yup'
import * as R from 'ramda'
import { LangaugeSelect } from './LanguageSelect'
import { FormSubmitButton } from '@/components/form/FormSubmitButton'
import { TemplateTypeSelect } from './TemplateTypeSelect'
import { FormMutation } from '@/components/form/FormMutation'
import { InsertRecoveryTemplate, InsertRecoveryTemplateVariables } from '@/queries/_gen_/InsertRecoveryTemplate'
import { InsertTemplateMutation, UpdateTemplateMutation } from '@/queries/recoveriesTemplates.queries'
import { TinymceReminderDocumentEditor, TinymceSimple, RemindersTinymceAutocompleteOnly } from '@/components/tinymce/TinymceEditor'
import styled from 'styled-components'
import { UpdateRecoveryTemplateVariables } from '@/queries/_gen_/UpdateRecoveryTemplate'
import Switch from 'antd/lib/switch'
import ApolloClient from 'apollo-client'
import { useApolloClient } from 'react-apollo'
import { archivedReminderTemplateValidator } from '../templateValidators'
import { removeFontStyle } from 'common/utils/text'

const BorderFixFormikField = styled(FormikField)`
  .tox .tox-edit-area {
    border-top: none;
  }
`

// @TODO check that template with such name/language/type already exists
const validationSchema = (apollo: ApolloClient<any>, templateId?: number) =>
  yup.object().shape({
    name: yup.string().required('Required!'),
    language: yup.string().required('Required!'),
    type: yup.string().required('Required!'),
    sms_content: yup
      .string()
      .nullable()
      .when(['type'], {
        is: (type: TemplateType) => type === TemplateType.sms,
        then: yup.string().required('Required!')
      }),
    email_subject: yup
      .string()
      .nullable()
      .when(['type'], {
        is: (type: TemplateType) => type === TemplateType.email,
        then: yup.string().required('Required!')
      }),
    email_content: yup
      .string()
      .nullable()
      .when(['type'], {
        is: (type: TemplateType) => type === TemplateType.email,
        then: yup.string().required('Required!')
      }),
    recovery_content: yup
      .string()
      .nullable()
      .when(['type'], {
        is: (type: TemplateType) => type === TemplateType.email || type === TemplateType.mail,
        then: yup.string().required('Required!')
      }),
    ...(templateId
      ? {
          archived: yup
            .boolean()
            .test(
              'archived',
              'Template cannot be archived: it is assigned to a category.',
              archivedReminderTemplateValidator(apollo, templateId)
            )
        }
      : {})
  })
interface Values extends Omit<DebtRecoveryTemplate, 'client_id'> {
  global: boolean
}

interface TemplateFormProps {
  isNew: boolean
  values: Values
  loading: boolean
  client?: ClientWithId
  onSubmit: (values: Values) => void
}

const emptyTemplate: Values = {
  global: false,
  archived: false,
  language: '',
  name: '',
  type: '',
  sms_content: null,
  email_subject: null,
  email_content: null,
  recovery_content: null,
  attachment_filename: 'recovery.pdf'
}

const TemplateRecoveryForm: FC<TemplateFormProps> = ({ values: vals, isNew, loading, onSubmit }) => {
  const apolloClient = useApolloClient()
  const vSchema = useMemo(() => validationSchema(apolloClient, vals.id), [apolloClient, vals.id])

  return (
    <Formik<Values> initialValues={vals} onSubmit={onSubmit} validationSchema={vSchema}>
      {({ setFieldValue, dirty, values }) => (
        <Form className='ant-form ant-form-vertical'>
          <ShortFormikField name='global' label='Template is global'>
            {({ field }) => <Switch disabled={!isNew} checked={field.value} onChange={checked => setFieldValue('global', checked)} />}
          </ShortFormikField>
          {!isNew && (
            <ShortFormikField name='archived' label='Archived'>
              {({ field }) => <Switch disabled={isNew} checked={field.value} onChange={checked => setFieldValue('archived', checked)} />}
            </ShortFormikField>
          )}
          <ShortFormikField name='name' label='Name' />
          <ShortFormikField name='language' label='Language'>
            {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              ({ field: { onChange, ...fieldProps } }) => (
                <LangaugeSelect {...fieldProps} disabled={!isNew} onChange={value => setFieldValue('language', value)} />
              )
            }
          </ShortFormikField>
          <ShortFormikField name='type' label='Type'>
            {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              ({ field: { onChange, ...fieldProps } }) => (
                <TemplateTypeSelect {...fieldProps} disabled={!isNew} onChange={value => setFieldValue('type', value)} />
              )
            }
          </ShortFormikField>
          {values.type === TemplateType.email && <ShortFormikField name='attachment_filename' label='Attachment filename' />}
          {values.type === TemplateType.sms && (
            <BorderFixFormikField name='sms_content' label='SMS content'>
              {() => (
                <RemindersTinymceAutocompleteOnly
                  initialValue={vals.sms_content || ''}
                  onChange={content => setFieldValue('sms_content', content)}
                />
              )}
            </BorderFixFormikField>
          )}
          {values.type === TemplateType.email && (
            <>
              <BorderFixFormikField name='email_subject' label='Email subject'>
                {() => (
                  <RemindersTinymceAutocompleteOnly
                    initialValue={vals.email_subject || ''}
                    onChange={content => setFieldValue('email_subject', content)}
                  />
                )}
              </BorderFixFormikField>
              <FormikField name='email_content' label='Email content'>
                {() => (
                  <TinymceSimple
                    initialValue={vals.email_content || ''}
                    onChange={content => setFieldValue('email_content', removeFontStyle(content))}
                  />
                )}
              </FormikField>
            </>
          )}
          {(values.type === TemplateType.email || values.type === TemplateType.mail) && (
            <FormikField name='recovery_content' label='Recovery content'>
              {() => (
                <TinymceReminderDocumentEditor
                  initialValue={vals.recovery_content || ''}
                  onChange={content => setFieldValue('recovery_content', content)}
                />
              )}
            </FormikField>
          )}
          <FormSubmitButton htmlType='submit' type='primary' disabled={!dirty || loading} loading={loading}>
            Save
          </FormSubmitButton>
        </Form>
      )}
    </Formik>
  )
}

interface CreateTemplateFormProps {
  onSaved: (template: DebtRecoveryTemplateWithId) => void
  client: ClientWithId
}

export const CreateTemplateForm: FC<CreateTemplateFormProps> = ({ client, onSaved }) => {
  const buildVariables = useCallback(
    (values: Values): InsertRecoveryTemplateVariables => {
      const { global, ...payload } = values
      return {
        payload: {
          ...payload,
          client_id: !global ? client.id : null
        }
      }
    },
    [client]
  )

  return (
    <FormMutation<Values, InsertRecoveryTemplate, InsertRecoveryTemplateVariables>
      buildVariables={buildVariables}
      successMessage='Template created!'
      onSaved={r => onSaved && r.result && r.result.returning.length && onSaved(r.result.returning[0])}
      mutation={InsertTemplateMutation}
      refetchQueries={['GetTemplateOptions', 'SearchTemplates']}
    >
      {({ onSubmit, result: { loading } }) => (
        <TemplateRecoveryForm isNew={true} client={client} onSubmit={onSubmit} loading={loading} values={emptyTemplate} />
      )}
    </FormMutation>
  )
}

interface UpdateTemplateFormProps {
  client: ClientWithId
  template: DebtRecoveryTemplateWithId
}

export const UpdateRecoveryTemplateForm: FC<UpdateTemplateFormProps> = ({ client, template }) => {
  const buildVariables = useCallback(
    (vals: Values): UpdateRecoveryTemplateVariables => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { global, id, ...payload } = vals
      return {
        payload: {
          ...R.omit(['type', 'language', 'client_id'], payload)
        },
        id: template.id
      }
    },
    [template.id]
  )

  const values = useMemo(
    () => ({
      ...template,
      global: !template.client_id
    }),
    [template]
  )

  return (
    <FormMutation<Values, InsertRecoveryTemplate, InsertRecoveryTemplateVariables>
      buildVariables={buildVariables}
      successMessage='Template updated!'
      mutation={UpdateTemplateMutation}
    >
      {({ onSubmit, result: { loading } }) => (
        <TemplateRecoveryForm isNew={false} client={client} onSubmit={onSubmit} loading={loading} values={values} />
      )}
    </FormMutation>
  )
}
